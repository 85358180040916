<template>
  <!--MENU SECTION -->

  <section id="dashbord" class="dashboard-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/supervisor/student-list"
            title="Student thesis"
            iconClass="fas fa-tasks"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/supervisor/student-requests"
            title="Student request"
            iconClass="fas fa-tasks"
            iconBg="bg-info"
          ></nav-item>
        </div>
      </div>
    </div>
  </section>
  <!-- MENU SECTION END -->
</template>

<script>
import NavItem from "../UI/NavItem.vue";

export default {
  components: {
    NavItem,
  },
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTime:null
    };
  },
  mounted() {
    this.events.forEach((event) => { 
      window.addEventListener(event, this.resetTimer);
    });

    this.setTimer();
  },
  methods: {
    setTimer: function () {
      this.warningTime = setTimeout(this.warningMessage,30 * 60000);
    },
    resetTimer: function () {
      clearTimeout(this.warningTime);
      this.setTimer();
    },
    warningMessage:function(){
      this.$store.dispatch("user/userLoggedOut");
      this.$router.replace("/signin");
    }
  } 
};
</script>
