<template>
  <div class="my-progress-boxes">
    <div class="writing-content">
      <h5 class="writing-content--title">Shared thesis sections</h5>
    </div>

    <!-- TASKS -->

    <div class="card mb-2" v-for="section in secionList" :key="section.id">
      <shared-thesis-section-block
        :section="section"
      ></shared-thesis-section-block>
    </div>
    <!-- TASKS END -->
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";

import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";
import SharedThesisSectionBlock from "./SharedThesisSectionBlock.vue";
export default {
  props: ["thesisId"],
  components: {
    BaseSpinner,
    SharedThesisSectionBlock,
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const secionList = ref([]);

    const loadSections = async (thesisId) => {
      if (thesisId) {
        //isLoading.value = true;
        const token = store.getters["user/token"];
        const response = await fetch(
          backendURL + "/api/getThesisShardSectionList/" + thesisId,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            isLoading.value = false;
            secionList.value = resposeData.data;
          }
        }
        isLoading.value = false;
      }
    };

    onMounted(() => {
      loadSections(props.thesisId);
    });

    watch(
      () => props.thesisId,
      (value) => {
        loadSections(value);
      }
    );

    return {
      secionList,
      isLoading,
    };
  },
};
</script>
