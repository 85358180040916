<template>
  <rndView></rndView>
</template>

<script>
import RNDview from "../../ResearchComponent/RnDTreeView";
export default {
  components: {
    rndView: RNDview,
  },
};
</script>
