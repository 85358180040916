<template>
  <div class="section">
    <div class="container">
      <div class="help-guide">
        <h4 class="main-heading text-start">TIPS & GUIDES</h4>
        <div class="float-end">
          <Canvace :page="page" :iconId="1"></Canvace>
        </div>
      </div>
    </div>
  </div>
  <section id="dashbord">
    <div class="container">
      <div class="row mt-3">
        <div
          class="col-xl-2 col-md-6 mb-2"
          v-for="(main, index) in mainTips"
          :key="main.id"
        >
          <div
            @click="activate(main.id)"
            :class="[
              active_el === 0 && index === 0
                ? 'card activeCard'
                : active_el === main.id
                ? 'card activeCard'
                : 'card',
            ]"
          >
            <div class="card-body" @click="getSubCat(main.id)">
              <div class="row align-items-center">
                <div class="col">
                  <!-- Title -->
                  <h3 class="tips-menu mb-2">
                    {{ main.mainTips }}
                  </h3>
                </div>
              </div>
              <!-- / .row -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
        <div class="user-credentials-boxes">
          <p>
            <a
              
              data-bs-toggle="collapse"
              data-bs-target=".multi-collapse"
              aria-expanded="false"
              aria-controls="multiCollapseExample1 multiCollapseExample2 multiCollapseExample3"
              @click="allExpandIcon()"
            >
              <i :class="allIconChange"></i>
            </a>
          </p>

          <div class="row mt-2">
            <div class="col-xl-4 col-md-6 mb-2">
              <div class="card">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="tips">
                        <div class="tips--text">
                          <h3 class="text-uppercase text-muted mb-4">
                            CATEGORY
                          </h3>
                        </div>
                        <div class="tips--logos">
                          <!-- <button
                            class="btn"
                            data-bs-toggle="collapse"
                            href="#multiCollapseExample1"
                            role="button"
                            aria-expanded="false"
                            aria-controls="multiCollapseExample1"
                            @click="catExpandIcon()"
                          >
                            <i :class="catIconChange"></i>
                          </button> -->
                        </div>
                      </div>
                      <!-- Title -->

                      <div class="collapse multi-collapse show" 
                        id="multiCollapseExample1"
                      >
                        <div
                          class="form-check form-switch"
                          v-for="sub in subTips"
                          :key="sub.id"
                        >
                          <input
                            class="form-check-input" 
                            type="radio"
                            id="sub.id"
                            name="sub.id"
                            @change="getNarrowCat(sub.id)"
                            :value="sub.id"
                            :checked="sub.id === sId"
                          /> 
                          {{ sub.subTips }}  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- NARROW FIELDS -->
            <div class="col-xl-4 col-md-6 mb-2">
              <div class="card">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col">
                      <div class="tips">
                        <div class="tips--text">
                          <h3 class="text-uppercase text-muted mb-4">
                            resource
                          </h3>
                        </div>
                        <!-- Title -->
                        <div class="tips--logos">
                          <!-- <button
                            class="btn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#multiCollapseExample2"
                            aria-expanded="false"
                            aria-controls="multiCollapseExample2"
                            @click="resExpandIcon()"
                          >
                            <i :class="resourceIconChange"></i>
                          </button> -->
                        </div>
                      </div>

                      <div
                         class="collapse multi-collapse show" 
                        id="multiCollapseExample2"
                      >
                        <div
                          class="form-check form-switch"
                          v-for="narrow in narrowTips"
                          :key="narrow.id"
                        >
                          <input
                            class="form-check-input" 
                            type="radio"
                            id="narrow.id"
                            name="narrow.id"
                            @change="getDetailCat(narrow.id)"
                            :value="narrow.id"
                            :checked="narrow.id === nId"
                          /> 
                          {{ narrow.narrowTips }} 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- DETAILED FIELDS -->
            <div class="col-xl-4 col-md-6 mb-2">
              <div class="card">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <div class="tips">
                        <div class="tips--text">
                          <h3 class="text-uppercase text-muted mb-4">
                            alternative
                          </h3>
                        </div>
                        <div class="tips--logos">
                          <!-- <button
                            class="btn"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#multiCollapseExample3"
                            aria-expanded="false"
                            aria-controls="multiCollapseExample3"
                            @click="alterExpandIcon()"
                          >
                            <i :class="alterIconChanges"></i>
                          </button> -->
                        </div>
                      </div>

                      <div
                        class="collapse multi-collapse show" 
                        id="multiCollapseExample3"
                      >
                        <div
                          class="form-check form-switch"
                          v-for="detail in detailTips"
                          :key="detail.id"
                        >
                          <input
                            class="form-check-input"  
                            type="radio"
                            id="detail.id"
                            name="detail.id"
                            @click="
                              setDetailDescription(
                                detail.detailDesc,
                                detail.detailTips
                              )
                            "
                            :value="detail.id"
                            :checked="detail.id === dId"
                          /> 
                          {{ detail.detailTips }} 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <h4 class="main-heading--sub text-start">
        {{ linkName }}
      </h4>
      <div class="row row-cols-sm-12">
        <div class="col-xxl-3 col-xl-3 order-xl-2 mt-2">
          <div class="resource-filter">
            <h5 class="resource-filter--title">IMPORTANT LINKS</h5>
            <ol class="list-group list-group-numbered">
              <li
                class="
                  list-group-item
                  d-flex
                  justify-content-between
                  align-items-start
                "
                v-for="l in linkTipObj"
                :key="l.id"
              >
                <div class="ms-2 me-auto">
                  <div class="fw-bold">{{ l.description }}</div>
                  <a :href="l.link" target="blank"
                    ><p class="media-body--author mb-2">
                      {{ l.name }}
                    </p></a
                  >
                </div>
              </li>
            </ol>
          </div>
        </div>

        <div class="col-xxl-9 col-xl-9 order-xl-1 mb-2">
          <section class="resource-boxes">
            <div class="row resources no-gutters">
              <div class="col-xl-12 col-md-12 px-2 px-md-3">
                <div class="row">
                  <div class="col-md-12 text-right text-600">
                    <div class="ml-3 media-body">
                      <span class="tips-text" v-html="this.detailObj"></span> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { backendURL } from "../../../app-constants";
import Canvace from "../../canvasComponent/OffCanvace.vue";
export default {
  components: {
    Canvace,
  },
  data() {
    return {
      mainTips: [],
      subTips: [],
      narrowTips: [],
      detailTips: [],
      detailObj: "",
      linkTipObj: [],
      linkName: "",
      page: "tips",
      active_el: 0,
      mId: 0,
      sId: 0,
      nId: 0,
      dId: 0,
      catIconChange: "fas fa-arrow-alt-circle-down",
      resourceIconChange: "fas fa-arrow-alt-circle-down",
      alterIconChanges: "fas fa-arrow-alt-circle-down",
      allIconChange: "fas fa-arrow-alt-circle-down",
      allExpand: false,
      catExand: false,
      resExpand: false,
      alterExpand: false,
    };
  },
  watch: {
    subTips: {
      handler(subTips) {
        if (subTips != "") {
          this.sId = subTips[0].id;
          this.getNarrowCat(this.sId);
        }
      },
    },
    narrowTips: {
      handler(nTips) {
        if (nTips != "") {
          this.nId = nTips[0].id;
          this.getDetailCat(this.nId);
        }
      },
    },
    detailTips: {
      handler(dTips) {
        if (dTips != "") {
          this.dId = dTips[0].id;
          this.setDetailDescription(dTips[0].detailDesc, dTips[0].detailTips);
        }
      },
    },
    allExpand: {
      handler(ex) {
        this.catIconChange = ex
          ? "fas fa-arrow-alt-circle-up"
          : "fas fa-arrow-alt-circle-down";
        this.resourceIconChange = ex
          ? "fas fa-arrow-alt-circle-up"
          : "fas fa-arrow-alt-circle-down";
        this.alterIconChanges = ex
          ? "fas fa-arrow-alt-circle-up"
          : "fas fa-arrow-alt-circle-down";
        this.allIconChange = ex
          ? "fas fa-arrow-alt-circle-up"
          : "fas fa-arrow-alt-circle-down";
      },
    },
    catExand: {
      handler(ex) {
        this.catIconChange = ex
          ? "fas fa-arrow-alt-circle-up"
          : "fas fa-arrow-alt-circle-down";
      },
    },
    resExpand: {
      handler(ex) {
        this.resourceIconChange = ex
          ? "fas fa-arrow-alt-circle-up"
          : "fas fa-arrow-alt-circle-down";
      },
    },
    alterExpand: {
      handler(ex) {
        this.alterIconChanges = ex
          ? "fas fa-arrow-alt-circle-up"
          : "fas fa-arrow-alt-circle-down";
      },
    },
  },
  methods: {
    getSubCat: function (id) {
      /* clear data */
      this.subTips.splice(0, this.subTips.length);
      this.narrowTips.splice(0, this.narrowTips.length);
      this.detailTips.splice(0, this.detailTips.length);
      this.linkTipObj.splice(0, this.linkTipObj.length);
      this.detailObj = "";
      this.linkName = "";
      this.text = "";
      this.mId = 0;
      this.sId = 0;
      this.nId = 0;
      this.dId = 0;

      this.token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/loadSubTipsByMain/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.subTips = res.data;
        });
    },
    getNarrowCat: function (id) {
      this.nId = 0;
      this.dId = 0;

      this.token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/loadNarrowTipsBySub/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.narrowTips = res.data;
        });
    },
    getDetailCat: function (id) {
      this.token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/loadDetailTipsByNarrow/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.detailTips = res.data;
          if (this.detailTips != "") {
            this.setLinks(this.detailTips[0].narrowTips.subTips.id);
          }
        });
    },
    setDetailDescription: function (text, tips) {
      this.detailObj = text;
      this.linkName = tips;
    },
    setLinks: function (id) {
      this.token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/getSubCategoryById/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.linkTipObj = res.data;
        });
    },
    activate: function (el) {
      this.active_el = el;
    },
    allExpandIcon: function () {
      this.allExpand = !this.allExpand;
     
      if (this.allExpand === true) {
        this.catExand = true;
        this.resExpand = true;
        this.alterExpand = true;
      } else {
        this.catExand = false;
        this.resExpand = false;
        this.alterExpand = false;
      }
    },
    catExpandIcon: function () {
      this.catExand = !this.catExand;
    },
    resExpandIcon: function () {
      this.resExpand = !this.resExpand;
    },
    alterExpandIcon: function () {
      this.alterExpand = !this.alterExpand;
    },
  },
  mounted() {
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/tip/loadMainTipsToStudent", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.mainTips = res.data;
        if (this.mainTips.length > 0) {
          this.mId = this.mainTips[0].id;
          this.getSubCat(this.mId);
        }
      });
  },
};
</script>
<style lang="scss" scoped>
.activeCard {
  border-color: #035397;
  background-color: #e4fbff;
}
</style>
