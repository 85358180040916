<template>
  <div class="container">
    <div class="help-guide">
      <h4 class="main-heading text-start">ONLINE RESULTS</h4>
      <div class="float-end"><Canvace :page="page" :iconId="1"></Canvace></div>
    </div>
    <p style="color: white">
      Search result based on thesis keywords : {{ thesisKeyword }}
      <router-link to="/student/thesis-complete"
        >{{ tTitle }}
        <i class="fas fa-edit mx-2"></i>
      </router-link>
    </p>
    <div class="col-12">
      <div class="row row-cols-sm-12">
        <!-- Filters -->
        <div class="col-xxl-3 col-xl-3 order-xl-1 mb-4">
          <div class="my-progress-boxes">
            <web-resource-filter
              @filter-resources="filterResources"
            ></web-resource-filter>
          </div>
          <div class="my-progress-boxes mt-3">
            <h5 class="resource-filter--title border-bottom mb-2">
              Powered by
            </h5>
            <label class="social-login--google-scholar">
              <span class="social-login-span"
                ><img
                  src="@/assets/images/Google_Scholar_logo.svg"
                  width="36"
                  height="36"
                  loading="lazy"
                  alt="Thesis Pro"
              /></span>
              GOOGLE SCHOLAR
            </label>
          </div>
        </div>
        <!-- Resource list -->

        <div class="col-xxl-9 col-xl-9 order-xl-1 mb-4">
          <div class="my-progress-boxes">
            <web-resource-list-block
              v-for="resource in resourceList"
              :key="resource.id"
              :resource="resource"
            ></web-resource-list-block>
          </div>
          <nav aria-label="...">
            <ul class="pagination pagination-sm">
              <li class="page-item" :class="activePage == 0 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(0)">1</a>
              </li>

              <li class="page-item" :class="activePage == 10 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(10)">2</a>
              </li>

              <li class="page-item" :class="activePage == 20 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(20)">3</a>
              </li>

              <li class="page-item" :class="activePage == 30 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(30)">4</a>
              </li>

              <li class="page-item" :class="activePage == 40 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(40)">5</a>
              </li>

              <li class="page-item" :class="activePage == 50 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(50)">6</a>
              </li>

              <li class="page-item" :class="activePage == 60 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(60)">7</a>
              </li>

              <li class="page-item" :class="activePage == 70 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(70)">8</a>
              </li>

              <li class="page-item" :class="activePage == 80 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(80)">9</a>
              </li>

              <li class="page-item" :class="activePage == 90 ? 'active' : ''">
                <a class="page-link" @click="searchInWebNew(90)">10</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useStore } from "vuex";
import axios from "axios";

import WebResourceListBlock from "./WebResourceListBlock.vue";
import WebResourceFilter from "./WebResourceFilter.vue";
import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";
import Canvace from "../../canvasComponent/OffCanvace.vue";

export default {
  components: {
    WebResourceListBlock,
    WebResourceFilter,
    BaseSpinner,
    Canvace,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);

    const resourceList = ref([]);
    const startYear = ref(null);
    const endYear = ref(null);
    const activePage = ref(0);
    const page = "websource";
    const thesisKeyword = ref([]);
    const tTitle = ref("");

    const resourceFiltersList = reactive([...resourceList.value]);

    const filterResources = (filter) => {
      startYear.value = filter.startYear;
      endYear.value = filter.endYear;
      searchInWebNew(0);
    };

    onMounted(() => {
      //searchInWeb(0);
      searchInWebNew(0);
      getThesis();
    });

    const searchInWebNew = async (startCount) => {
      activePage.value = startCount;
      //Get Url
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(backendURL + "/api/getSearchURL/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          countStart: startCount,
          startYear: startYear.value,
          endYear: endYear.value,
          userAgent: navigator.userAgent,
        }),
      });
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          //resourceList.value = resposeData.data;
         // console.log(">>>> " + resposeData.data);
          await axios
            .get(resposeData.data)
            .then((responseDom) => {
             // console.log("Everything is awesome.");
             // console.log(">>>> " + responseDom.data);
              getWebData(responseDom.data);
            })
            .catch((error) => {
              console.warn("Error " + error);
            });
        }
      }
      //isLoading.value = false;
      //const resposeData11 = await axios.get(" https://scholar.google.com");
      //console.log(resposeData11.data);
    };

    const getWebData = async (domdata) => {
      // Get web data
      const responseWEB = await fetch(
        backendURL + "/api/auth/getWebResourceNew/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stringData: domdata,
          }),
        }
      );

      const resposeWEDData = await responseWEB.json();
      if (responseWEB.ok) {
        if (resposeWEDData.valid) {
          resourceList.value = resposeWEDData.data;
        }
      } else {
        console.error("Error > " + responseWEB.errors);
      }
      isLoading.value = false;
    };
    const searchInWeb = async (startCount) => {
      activePage.value = startCount;
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(backendURL + "/api/getWebResource/", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          countStart: startCount,
          startYear: startYear.value,
          endYear: endYear.value,
          userAgent: navigator.userAgent,
        }),
      });
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          resourceList.value = resposeData.data;
        }
      }
      isLoading.value = false;
    };

    const getThesis = async () => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];

      const response = await fetch(
        backendURL + "/api/getUserThesisDetails/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          thesisKeyword.value = resposeData.data.keyWords;
          tTitle.value = resposeData.data.title;
        }
      }
    };

    return {
      filterResources,
      resourceFiltersList,
      isLoading,
      resourceList,
      startYear,
      endYear,
      searchInWeb,
      searchInWebNew,
      activePage,
      page,
      getThesis,
      thesisKeyword,
    };
  },
};
</script>
