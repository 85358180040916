<template>
  <div class="container">
    <div class="alert alert-success" role="alert" v-show="jsonSuc">
      Succesfully saved!
    </div>
    <div class="alert alert-danger" role="alert" v-show="jsonErr">
      Something went wrong!
    </div>
    <!-- ACCORDION -->
    <div class="row mt-5 mb-5">
      <div class="col-md-12">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item shadow mb-3">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Add category
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <div class="row">
                  <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
                    <form
                      autocomplete="off"
                      class="row"
                      @submit.prevent="saveSubTips"
                    >
                      <div class="col-12" :class="{ errors: catValidation }">
                        <label for="quest" class="form-label"
                          >Category <span class="text-muted"></span
                        ></label>
                        <input
                          type="hidden"
                          id="cid"
                          name="cid"
                          v-model="sTipsId"
                        />
                        <input
                          type="text"
                          id="quest"
                          name="quest"
                          class="form-control"
                          v-model="sTips"
                          maxlength="255"
                        />
                        <div class="error-message">Please enter category.</div>
                      </div>
                      <div class="col-12" :class="{ errors: mainValidation }">
                        <label class="form-label">Main</label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          v-model="mainTips"
                        >
                          <option
                            v-for="m in allMaintips"
                            :key="m.id"
                            :value="m.id"
                          >
                            {{ m.mainTips }}
                          </option>
                        </select>
                        <div class="error-message">
                          Please select main tips.
                        </div>
                      </div>
                      <div
                        class="
                          d-grid
                          gap-2
                          d-md-flex
                          justify-content-md-left
                          mt-2
                        "
                      >
                        <button
                          class="btn-task--success me-md-2 mb-3"
                          type="submit"
                        >
                          SAVE <i class="fas fa-save"></i>
                        </button>
                        <button
                          class="btn-task--danger me-md-2 mb-3"
                          type="button"
                        >
                          CANCEL <i class="fas fa-window-close"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="row">
                  <table class="table table-striped table-bordered mt-2">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="subTips in allSubtips" :key="subTips.id">
                        <td>{{ subTips.subTips }}</td>
                        <td>
                          <button @click="viewResource(subTips.id)">
                            View
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item shadow mb-3">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
                @click="loadAllLink"
              >
                Add link
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <AddLink
                  :loadSubTips="allSubtips"
                  :loadAllLinkNew="allLink"
                ></AddLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ACCORDION END -->
  </div>
</template>
<script>
import { backendURL } from "../../../app-constants";
import AddLink from "./AddLinks.vue";
export default {
  props: ["loadMaintips", "reloadSubTips"],
  components: {
    AddLink,
  },
  data() {
    return {
      sTipsId: "",
      mainTipsObj: {},
      mainTips: "",
      sTips: "",
      allSubtips: [],
      jsonErr: false,
      jsonSuc: false,
      allMaintips: [],
      formIsValid: false,
      catValidation: false,
      mainValidation: false,
      allLink: [],
    };
  },
  watch: {
    loadMaintips: {
      handler(mainTips) {
        this.allMaintips = mainTips;
      },
    },
    reloadSubTips: {
      handler(subTips) {
        this.allSubtips = subTips;
      },
    },
  },
  methods: {
    validateFrom: function () {
      this.formIsValid = true;
      if (this.sTips == "") {
        this.catValidation = true;
        this.formIsValid = false;
      }
      if (this.mainTips == "") {
        this.mainValidation = true;
        this.formIsValid = false;
      }
    },
    saveSubTips: function () {
      this.validateFrom();
      if (this.formIsValid) {
        this.catValidation = false;
        this.mainValidation = false;

        this.token = this.$store.getters["user/token"];
        this.mainTipsObj = { id: this.mainTips };
        var object = {
          mainTips: this.mainTipsObj,
          subTips: this.sTips,
          id: this.sTipsId,
        };
        fetch(backendURL + "/api/auth/tip/saveSubTips", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
          body: JSON.stringify(object),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              this.allSubtips = res.data;
              this.sTips = "";
              this.sTipsId = "";
              this.mainTips = "";
              this.jsonSuc = true;
            } else {
              this.jsonErr = true;
            }
          });
      }
    },
    viewResource: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/loadSubCategory/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.sTipsId = res.data.id;
          this.sTips = res.data.subTips;
          this.mainTips = res.data.mainTips.id;
        });
    },
    loadAllLink: function () {
      fetch(backendURL + "/api/auth/tip/loadLinks", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.allLink = res.data;
        });
    },
  },
  mounted() {
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/tip/loadMainTips", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allMaintips = res.data;
      });

    fetch(backendURL + "/api/auth/tip/loadSubTips", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allSubtips = res.data;
      });
  },
};
</script>
