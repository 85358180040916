export default {
  loggedUserId(state) {
    return state.userId;
  },

  loggedUserRole(state) {
    return state.role;
  },

  isThesisActivated(state) {
    return state.isThesisActivated;
  },

  usersImage(state) {
    return state.base64Image;
  },

  token(state) {
    return state.token;
  },

  isAuthenticated(state) {
    return !!state.token;
  },

  notificationCount(state) {
    return state.nitificationCount;
  },
};
