<template>
  <!-- USERS-->
  <div class="container">
    <div class="alert alert-success" role="alert" v-show="jsonSuc">
      Succesfully saved!
    </div>
    <div class="alert alert-danger" role="alert" v-show="jsonErr">
      Something went wrong!
    </div>
  </div>
  <div>
    <div class="container">
      <div class="my-progress-boxes">
      <div class="row text-start">
        <div class="sysadmin mb-1">
          <h4>USERS PAYMENT IN THE SYSTEM</h4>
        </div>
      </div>

      <div class="row text-start">
        <form autocomplete="off" class="row" @submit.prevent="updatePayment">
          <div class="col-12">
            <label for="quest" class="form-label"
              >User <span class="text-muted"></span
            ></label>
            <input
              type="hidden"
              id="pId"
              name="pId"
              v-model="paymentData.id.val"
            />
            <input
              type="text"
              id="quest"
              name="quest"
              class="form-control"
              readonly="readonly"
              v-model="paymentData.user.val"
            />
          </div>
          <div class="col-12">
            <label class="form-label">Payment Status</label>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="paymentData.paymentStatus.val"
            >
              <option
                v-for="(m, index) in paymentStatus"
                :key="index"
                :value="index"
                :selected="index == paymentData.paymentStatus.val"
              >
                {{ m }}
              </option>
            </select>
          </div>
          <div class="col-12">
            <label class="form-label">Payment package</label>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="paymentData.paymentPackage.val"
            >
              <option
                v-for="(p, index) in paymentPakage"
                :key="index"
                :value="index"
                :selected="index == paymentData.paymentPackage.val"
              >
                {{ p }}
              </option>
            </select>
          </div>
          <!-- <div class="col-12">
            <div class="col-sm-12 mt-2">
              <datepicker
                class="form-control"
                id="updateDate"
                name="updateDate"
                v-model="paymentData.updatedDate.val"
                @blur="clearValidity('updateDate')"
              />
            </div>
          </div> -->
          <div class="col-12">
            <div class="col-sm-12 mt-2">
              <label for="expireDate" class="form-label">Expire date</label>
              <datepicker
                class="form-control"
                id="expireDate"
                name="expireDate"
                v-model="paymentData.expiredDate.val"
                @blur="clearValidity('expireDate')"
              />
            </div>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-left mt-2">
            <button class="btn-task--success me-md-2 mb-3" type="submit">
              SAVE <i class="fas fa-save"></i>
            </button>
            <button class="btn-task--danger me-md-2 mb-3" type="button">
              CANCEL <i class="fas fa-window-close"></i>
            </button>
          </div>
        </form>
      </div>

      <div class="row text-start">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Update date</th>
              <th>Payment status</th>
              <th>Payment package</th>
              <th>Expire date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="p in allPaymentData" :key="p.id">
              <td>{{ p.user.fullName }}</td>
              <td>{{ p.user.email }}</td>
              <td>{{ p.updatedDate }}</td>
              <td>{{ p.paymentStatus }}</td>
              <td>{{ p.paymentPackage }}</td>
              <td>{{ p.expiredDate }}</td>
              <td>
                <button @click="loadUsersPaymentbyId(p.id)">Edit</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  </div>

  <!-- END -->
</template>
<script>
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { backendURL } from "../../../app-constants";
import Datepicker from "vue3-datepicker";
export default {
  components: {
    Datepicker,
  },
  setup() {
    const store = useStore();
    const allPaymentData = ref([]);
    const paymentStatus = ref([]);
    const paymentPakage = ref([]);
    const jsonErr = ref(false);
    const jsonSuc = ref(false);

    const paymentData = reactive({
      id: { val: null, isValid: true },
      user: { val: null, isValid: true },
      updatedDate: { val: null, isValid: true },
      paymentStatus: { val: "", isValid: true },
      paymentPackage: { val: "", isValid: true },
      expiredDate: { val: null, isValid: true },
    });

    onMounted(() => {
      loadUsersPayment();
    });

    const loadUsersPayment = async () => {
      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/getAllUserPayment", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          allPaymentData.value = res.data;
        });

      fetch(backendURL + "/api/auth/listAllPaymentStatus", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          paymentStatus.value = res.data;
        });

      fetch(backendURL + "/api/auth/listAllPaymentPakage", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          paymentPakage.value = res.data;
        });
    };

    const loadUsersPaymentbyId = async (id) => {
      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/getUserPayment/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          paymentData.id.val = res.data.id;
          paymentData.user.val = res.data.user.fullName;
          paymentData.updatedDate.val = new Date(res.data.updatedDate);
          paymentData.expiredDate.val = new Date(res.data.expiredDate);
          paymentData.paymentStatus.val = res.data.paymentStatus;
          paymentData.paymentPackage.val = res.data.paymentPackage;
        });
    };

    async function updatePayment() {
      const token = store.getters["user/token"];
      let obj = {
        id: paymentData.id.val,
        paymentStatus: paymentData.paymentStatus.val,
        paymentPackage: paymentData.paymentPackage.val,
        expiredDate: paymentData.expiredDate.val,
      };
      fetch(backendURL + "/api/auth/updatePayment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            jsonSuc.value = true;
            paymentData.id.val = null;
            paymentData.user.val = null;
            paymentData.expiredDate.val = null;
            paymentData.paymentStatus.val = "";
            paymentData.paymentPackage.val = "";
            loadUsersPayment();
          } else {
            jsonErr.value = true;
          }
        });
    }

    return {
      paymentData,
      paymentStatus,
      paymentPakage,
      allPaymentData,
      loadUsersPaymentbyId,
      updatePayment,
      jsonErr,
      jsonSuc,
    };
  },
};
</script>
