<template>
  <h5 class="writing-content--title mt-4">
    NEW EVENT
  </h5>
  <form @submit.prevent="addEvent">
    <div class="row">
      <div class="col-12" :class="{ errors: !eventObj.title.isValid }">
        <label for="title" class="form-label"
          >Title <span class="text-muted mt-3"></span
        ></label>
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="title"
            name="title"
            v-model="eventObj.title.val"
            @blur="clearValidity('title')"
          />
          <div class="error-message">
            Please add title.
          </div>
        </div>
      </div>
      <div class="col-12" :class="{ errors: !eventObj.startDate.isValid }">
        <label for="startDate" class="form-label mt-4">Starting date</label>
        <datepicker
          class="form-control"
          id="startDate"
          name="startDate"
          v-model="eventObj.startDate.val"
          @blur="clearValidity('startDate')"
        />
        <div class="error-message">
          Please select start date.
        </div>
      </div>

      <div class="col-12" :class="{ errors: !eventObj.endDate.isValid }">
        <label for="endDate" class="form-label mt-4"
          >End date <span class="text-muted"></span
        ></label>

        <datepicker
          class="form-control"
          id="endDate"
          name="endDate"
          v-model="eventObj.endDate.val"
          @blur="clearValidity('endDate')"
        />
        <div class="error-message">
          Please select end date.
        </div>
      </div>

      <div class="d-grid gap-2 d-md-flex text-start">
        <button class="btn-task--success mt-3 me-md-2">
          Add <i class="fas fa-calendar-plus"></i>
        </button>
      </div>
      <p v-if="!formIsValid" class="comman-error">
        {{ invalidmessage }}
      </p>
    </div>
  </form>
</template>

<script>
import { reactive, ref } from "vue";

import Datepicker from "vue3-datepicker";

export default {
  emits: ["add-new-event"],
  components: {
    Datepicker,
  },
  setup(props, { emit }) {
    const eventObj = reactive({
      title: { val: null, isValid: true },
      startDate: { val: null, isValid: true },
      endDate: { val: null, isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);

    function clearValidity(input) {
      eventObj[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;
      if (eventObj.title.val == "") {
        eventObj.title.isValid = false;
        formIsValid.value = false;
      }

      if (!eventObj.startDate.val || eventObj.startDate.val == "") {
        eventObj.startDate.isValid = false;
        formIsValid.value = false;
      }
      if (!eventObj.endDate.val || eventObj.endDate.val == "") {
        eventObj.endDate.isValid = false;
        formIsValid.value = false;
      }
    }
    function addEvent() {
      validateFrom();
      if (!formIsValid.value) {
        invalidmessage.value = "Required  fields missing";
        return;
      }
      emit("add-new-event", { ...eventObj });
      clearForm();
    }
    function clearForm() {
      eventObj.title.val = "";
      eventObj.startDate.val = null;
      eventObj.endDate.val = null;
    }

    return {
      eventObj,
      formIsValid,
      invalidmessage,
      clearValidity,
      addEvent,
    };
  },
};
</script>
