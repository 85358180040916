<template>
  <section id="thesis-about" class="thesis-about mb-2">
    <div class="container">
      <section class="thesis-about-box">
        <div class="row no-gutters mb-1">
          <div class="col-xl-8 col-md-6 mb-1 px-2 px-md-3">
            <div class="row">
              <div class="col-md-1 text-center">
                <!-- profile picture of student-->

                <div class="user-picture mt-1">
                  <img
                    :src="headerDetails.profImage"
                    alt=""
                    width="50"
                    height="50"
                    class="rounded-circle shadow-lg"
                  />
                </div>
              </div>
              <div class="col-md-11 text-right text-600">
                <div class="ml-2 media-body">
                  <div class="thesis-about">
                    <p class="thesis-about--name mb-2">
                      {{ headerDetails.userName }}
                    </p>

                    <p class="thesis-about--title mb-2">
                      <router-link to="/student/thesis-complete"
                        >{{ headerDetails.thesisTitle }}
                        <i class="fas fa-edit mx-2"></i>
                      </router-link>
                    </p>
                    <p
                      class="thesis-about--supervisor mb-0"
                      v-if="
                        showSupervisorSelect && headerDetails.supervisorName
                      "
                    >
                      <strong>Supervisor: </strong>
                      {{ headerDetails.supervisorName }}
                    </p>
                    <p
                      class="thesis-about--supervisor mb-0"
                      v-if="
                        showSupervisorSelect &&
                        !headerDetails.supervisorName &&
                        !headerDetails.hasPendingRequest
                      "
                    >
                      Select or invite supervisor.
                      <router-link
                        to="/student/thesis-complete"
                        class="btn-task--notifications btn-sm me-md-2"
                      >
                        <i class="fas fa-plus"></i>
                      </router-link>
                    </p>

                    <p
                      class="thesis-about--supervisor mb-0"
                      v-if="
                        showSupervisorSelect && headerDetails.hasPendingRequest
                      "
                    >
                      Waiting for supervisor response
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 px-2 px-md-3">
            <div class="row">
              <div class="col-lg-12">
                <div class="row g-2">
                  <div class="col-md-6">
                    <p class="thesis-about--date-descrip">Started on</p>
                    <p class="card-body--deadline mx-2">
                      {{ headerDetails.startDate }}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <p class="thesis-about--date-descrip">FINISHING DATE</p>
                    <p class="card-body--deadline mx-2">
                      {{ headerDetails.finishingDate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>

  <!-- <div class="loading-page" v-if="isLoading">
    <div class="counter">
      <p>loading</p>
      <h1><span v-html="animateCount"></span>%</h1>
      <hr :style="{ width: animateCount + '%' }" />
    </div>
  </div> -->
</template>

<script>
import { useStore } from "vuex";
import { onMounted, reactive, ref } from "vue";
import { backendURL } from "../../../app-constants.js";

export default {
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const animateCount = ref(0);

    const headerDetails = reactive({
      userName: null,
      thesisTitle: null,
      finishingDate: null,
      latestDeadLine: null,
      profImage: null,
      startDate: null,
      supervisorName: null,
      hasPendingRequest: false,
    });

    const showSupervisorSelect = ref(false);

    onMounted(async () => {
      //loadingGif();
      isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getThesisHeaderDetails/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          headerDetails.userName = resposeData.data.userName;
          headerDetails.thesisTitle = resposeData.data.thesisTitle;
          headerDetails.finishingDate = resposeData.data.finishingDate;
          headerDetails.latestDeadLine = resposeData.data.latestDeadLine;
          headerDetails.profImage = resposeData.data.profImage;
          headerDetails.startDate = resposeData.data.startDate;
          headerDetails.supervisorName = resposeData.data.supervisorName;
          headerDetails.hasPendingRequest = resposeData.data.hasPendingRequest;
          showSupervisorSelect.value = true;
        }
      }
      isLoading.value = false;
    });

    // const loadingGif = () => {
    //   var counter = 0;
    //   var i = setInterval(function () { 
    //     counter++;
    //     animateCount.value++; 
    //     if (counter == 100) {
    //       clearInterval(i);
    //     }
    //   }, 50);
    // };

    return {
      headerDetails,
      showSupervisorSelect,
      isLoading,
      animateCount,
    };
  },
};
</script>
 
