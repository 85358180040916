<template>
  <div class="form w-100 pb-2">
    <div class="form w-100 pb-2">
      <h4 class="display-3--title mb-4 fw-blod">LOG IN</h4>
    </div>

    <div class="social-login text-center">
      <google-log-in :role="role" :token="token"></google-log-in>
      <div class="mt-4">
        <span class="text-center mb-3">or use your user account for login</span>
      </div>
    </div>
    <form @submit.prevent="signInUser" class="row" autocomplete="off">
      <div class="col-12" :class="{ errors: !loginData.userName.isValid }">
        <label for="userName" class="form-label">
          Email <span class="text-muted"></span>
        </label>
        <input
          type="text"
          class="form-control"
          id="userName"
          name="userName"
          v-model.trim="loginData.userName.val"
          @blur="clearValidity('userName')"
        />
        <div class="error-message">
          Please enter a valid email address.
        </div>
      </div>

      <div class="col-12" :class="{ errors: !loginData.password.isValid }">
        <label for="password" class="form-label">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          name="password"
          v-model="loginData.password.val"
          @blur="clearValidity('password')"
        />
        <div class="error-message">
          Password is required
        </div>
        <router-link to="/fogot-password"
          ><p class="fs-6--forget">Forgot password?</p></router-link
        >
      </div>

      <div class="text-center d-grid mt-1">
        <button class="btn btn-primary rounded-pill pt-3 pb-3" type="submit">
          SUBMIT
          <i class="fas fa-paper-plane"></i>
        </button>
      </div>

      <p v-if="!formIsValid" class="comman-error">{{ invalidmessage }}</p>
    </form>

    <base-spinner v-if="isLoading"></base-spinner>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import BaseSpinner from "../UI/BaseSpinner.vue";
import GoogleLogIn from "./ext-log-ins/GoogleLogIn.vue";
import {
  backendURL,
  isUserPaymentActivated,
  UserRoles,
} from "../../app-constants";

export default {
  props: ["role", "token"],
  components: { BaseSpinner, GoogleLogIn },
  emits: ["update-logged-user"],

  setup() {
    const router = useRouter();
    const store = useStore();

    const loginData = reactive({
      userName: { val: "", isValid: true },
      password: { val: "", isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);

    function clearValidity(input) {
      loginData[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;
      if (loginData.userName.val == "") {
        loginData.userName.isValid = false;
        formIsValid.value = false;
      }
      if (loginData.password.val == "") {
        loginData.password.isValid = false;
        formIsValid.value = false;
      }
    }

    async function signInUser() {
      validateFrom();
      if (!formIsValid.value) {
        invalidmessage.value = "Required  fields missing";
        return;
      }
      //isLoading.value = true;
      try {
        await store.dispatch("user/login", {
          userName: loginData.userName.val,
          password: loginData.password.val,
        });
        const loggedUserRole = store.getters["user/loggedUserRole"];

        if (isUserPaymentActivated && loggedUserRole == UserRoles.STUDENT) {
          checkUserPayments(loggedUserRole);
        } else {
          isLoading.value = false;
          goToSystem(loggedUserRole);
        }
      } catch (error) {
        isLoading.value = false;
        alert("message " + error.message);
        formIsValid.value = false;
        invalidmessage.value = "Failed to authenticate...";
      }
    }

    const checkUserPayments = async (loggedUserRole) => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/validateUserPayment/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          if (resposeData.data) {
            isLoading.value = false;
            goToSystem(loggedUserRole);
          } else {
            isLoading.value = false;
            alert(resposeData.message);
            router.replace("/payments/" + userId);
          }
        } else {
          alert(resposeData.message);
        }
      }
      isLoading.value = false;
    };

    const goToSystem = async (loggedUserRole) => {
      await store.dispatch("user/updateNotification");
      if (loggedUserRole == UserRoles.SYS_ADMIN) {
        router.replace("/admin");
      } else if (loggedUserRole == UserRoles.STUDENT) {
        router.replace("/student");
      } else if (loggedUserRole == UserRoles.SUPERVISOR) {
        router.replace("/supervisor");
      }
    };

    return {
      loginData,
      formIsValid,
      invalidmessage,
      clearValidity,
      signInUser,
      isLoading,
    };
  },
};
</script>
