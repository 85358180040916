<template>
  <base-dialog
    :show="showModal"
    title="PROFILE PICTURE"
    @close-dialog="closeDialog"
    size="modal-md"
  >
    <template #default>
      <VueCropper
        v-show="selectedFile"
        ref="cropper"
        :src="selectedFile"
        alt="Source Image"
        preview=".preview"
        :guides="true"
        :view-mode="2"
        drag-mode="crop"
        :min-container-width="460"
        :min-container-height="500"
        :background="true"
        :rotatable="true"
        :img-style="{ width: '100%', height: '100%' }"
        :aspectRatio="1 / 1"
        :initialAspectRatio="1 / 1"
      ></VueCropper>
    </template>
    <template #actions>
      <button
        class="btn-task--success me-md-2"
        type="button"
        @click="saveImage"
      >
        Crop <i class="fas fa-crop-alt"></i>
      </button>
      <button
        class="btn-task--danger me-md-2"
        type="button"
        data-bs-dismiss="modal"
        @click="showModal = false"
      >
        CLOSE <i class="fas fa-window-close"></i>
      </button>
    </template>
  </base-dialog>

  <div class="avatar-lg text-center">
    <img
      :src="profileImage"
      alt=""
      width="100"
      height="100"
      class="rounded-circle"
    />
  </div>
  <div class="d-grid gap-2 d-md-flex justify-content-md-center">
    <h5
      class="profile--success me-md-2"
      @click="$refs.FileInput.click()"
      style="cursor: pointer"
    >
      CHANGE PROFILE
    </h5>
  </div>
  <input
    ref="FileInput"
    type="file"
    style="display: none"
    @change="onFileSelect"
  />
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { backendURL } from "../../app-constants";

export default {
  props: ["user", "image_name"],
  components: {
    VueCropper,
  },
  data() {
    return {
      token: "",
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      files: "",
      showModal: false,
      profileImage: this.$store.getters["user/usersImage"],
    };
  },

  methods: {
    saveImage() {
      try {
        let userId = this.$store.getters["user/loggedUserId"];
        this.cropedImage = this.$refs.cropper
          .getCroppedCanvas()
          .toBlob((blob) => {
            const formData = new FormData();
            formData.append("image", blob);
            formData.append("userId", userId);
            formData.append("profileImageType", this.mime_type.split("/")[1]);
            this.uploadProfileData(formData);
          });
      } catch (error) {
        this.showModal = false;
        alert(error);
      }
    },

    async uploadProfileData(formData) {
      try {
        const token = this.$store.getters["user/token"];
        const response = await fetch(backendURL + "/api/updateUserProfile/", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            con: "multipart/form-data",
          },
          body: formData,
        });
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            this.profileImage = resposeData.data;
            this.showModal = false;
            this.$store.dispatch("user/updateProfileImage", {
              base64Image: resposeData.data,
            });
          }
        } else {
          this.showModal = false;
        }
      } catch (error) {
        this.showModal = false;
        alert(error);
      }
    },
    onFileSelect(e) {
      this.showModal = true;
      const file = e.target.files[0];
      this.mime_type = file.type;

      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = "";
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    closeDialog() {
      this.showModal = false;
    },
  },
};
</script>
<style>
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
  transform: auto;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
</style>
