<template>
  <ul class="list-group mb-3">
    <li class="list-group-item d-flex justify-content-between lh-sm">
      <div class="thesis-writing-sections">
        <h6 class="thesis-writing-sections--title my-0">
           <a :href="resource.url" target="_brank" class="thesis"
            >{{ resource.title }}</a>
        </h6>
        <hr class="my-2" />
        <small class="thesis-writing-sections--author">{{
          resource.author
        }}</small>
        <small class="thesis-writing-sections---publisher">{{
          resource.source
        }}</small>
      </div>
      <span class="text-muted"
        ><div :class="lockIconColourCss" class="text-center">
          <a :href="resource.url" target="_brank" class="thesis"
            ><i :class="lockIconCss"></i
          ></a>
        </div>
      </span>
    </li>
  </ul>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  props: ["resource"],
  setup(props) {
    const lockIconCss = computed(() => {
      return props.resource.publicAccess ? "fas fa-unlock-alt" : "fas fa-lock";
    });
    const lockIconColourCss = computed(() => {
      return props.resource.publicAccess
        ? "thesis-icons--success"
        : "thesis-icons--warning";
    });

    return {
      lockIconColourCss,
      lockIconCss,
    };
  },
};
</script>
