<template>
  <notifications position="top center"></notifications>
  <div class="card">
    <div class="card-header">Select Or invite Supervisor</div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="searchKey"
              name="searchKey"
              v-model.trim="searchKey"
            />
            <label for="floatingTextarea">Name or email</label>
            <div class="error-message">
              Please enter keywords.
            </div>
          </div>
        </div>

        <div class="col-12">
          <button
            class="btn-task--success me-md-2  mt-2"
            @click="searchSupervior"
            type="button"
          >
            SEARCH <i class="fas fa-search"></i>
          </button>
        </div>

        <div class="col-12 mb-4" v-show="showResult">
          <span v-if="supervisorList.length == 0">No result</span>
          <!-- Iterrate sunervisors -->
          <ul>
            <li v-for="supervisor in supervisorList" :key="supervisor.id">
              <span>{{ supervisor.fullName }}</span
              ><span style="margin-left:1em"
                ><button
                  class="btn-task--success me-md-2"
                  @click="sendRequestToSupervisor(supervisor.id)"
                  type="button"
                >
                  Send request <i class="fas fa-paper-plane"></i></button
              ></span>
            </li>
          </ul>
          <!-- invite -->
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="supervisorEmail"
              name="supervisorEmail"
              v-model.trim="supervisorEmail"
            />
            <label for="floatingTextarea">Supervisor email address </label>
            <button
              class="btn-task--success me-md-2 mt-2"
              @click="inviteToSupervisor"
              type="button"
            >
              Invite <i class="fas fa-envelope-open-text"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

import { backendURL } from "../../app-constants";
import BaseSpinner from "../UI/BaseSpinner.vue";
import { notify } from "@kyvg/vue3-notification";
export default {
  components: {
    BaseSpinner,
  },
  props: ["thesisId"],
  emits: ["refresh-data"],
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref(false);
    const searchKey = ref("");
    const supervisorList = ref([]);
    const showResult = ref(false);
    const supervisorEmail = ref("");

    const searchSupervior = async () => {
      supervisorList.value = [];
      showResult.value = false;
      //isLoading.value = true;
      const token = store.getters["user/token"];

      try {
        const response = await fetch(
          backendURL +
            "/api/searchSupervisors/" +
            (searchKey.value ? searchKey.value : "*"),
          {
            method: "GET",
            headers: {
              //"Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(resposeData.message || "Error Search User");
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          showResult.value = true;
          supervisorList.value = resposeData.data;
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    const inviteToSupervisor = async () => {
      //isLoading.value = true;
      supervisorList.value = [];
      showResult.value = false;
      const token = store.getters["user/token"];

      try {
        const response = await fetch(backendURL + "/api/inviteToSupervisor", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            thesisId: props.thesisId,
            emailAddress: supervisorEmail.value,
          }),
        });

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(resposeData.message || "Error Search User");
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          emit("refresh-data");
          notify({
            type: "success",
            title: "SUCCESS",
            text: "Invitation sent to supervisor",
          });
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    const sendRequestToSupervisor = async (supervisorId) => {
      supervisorList.value = [];
      showResult.value = false;
      //isLoading.value = true;
      const token = store.getters["user/token"];

      try {
        const response = await fetch(
          backendURL + "/api/addRequestToSupervisor",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
              thesisId: props.thesisId,
              supervisorId: supervisorId,
            }),
          }
        );

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(resposeData.message || "Error Search User");
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          notify({
            type: "success",
            title: "SUCCESS",
            text: "Request sent to supervisor!",
          });
          //alert("Request sent to supervisor");
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    return {
      searchKey,
      searchSupervior,
      supervisorList,
      showResult,
      inviteToSupervisor,
      supervisorEmail,
      isLoading,
      sendRequestToSupervisor,
    };
  },
};
</script>
