<template>
  <section class="resource-boxes">
    <notifications position="top center"></notifications>
    <div class="row resources no-gutters">
      <div class="col-xl-12 col-md-12 mb-1 px-2 px-md-3">
        <div class="row">
          <div class="col-md-12 text-right text-600">
            <div class="ml-3 media-body">
              <div>
                <h6 class="resource font-weight-semi-bold border-bottom mb-3">
                  <a :href="resource.url" target="_blank">{{
                    resource.title
                  }}</a>
                </h6>
              </div>

              <p class="media-body--author">
                {{ resource.author }}
              </p>

              <!-- <div class="publiser-sdg"> -->
              <div class="row mb-1">
                <div
                  :class="
                    resource.sdgList.length > 0 ? 'col-md-10' : 'col-md-12'
                  "
                >
                  <p class="media-body--publiser">
                    {{ resource.source }} ({{ resource.dateTime }})
                  </p>
                </div>
                <div class="col-md-2">
                  <span>
                    <student-resource-sdg
                      v-for="sdg in resource.sdgList"
                      :key="sdg.id"
                      :sdg="sdg"
                    ></student-resource-sdg>
                  </span>
                </div>
              </div>
              <!-- </div> -->

              <div class="resourse-rights">
                <div :class="lockIconColourCss" class="text-start">
                  <a href="#" class="resources"><i :class="lockIconCss"></i></a>
                </div>
                <p :class="lockTextColourCss">{{ lockText }}</p>

                <div class="resource-icons--success text-start">
                  <a @click="updateMyLibray" class="resources"
                    ><i :class="addIconCss"></i
                  ></a>
                </div>
                <p class="resource--add-text">{{ addText }}</p>

                <!-- start citation -->
                <div class="resource-icons--success text-start" v-if="resource.doi != null">
                  <a
                    class="resources"
                    data-bs-toggle="collapse"
                    :href="'#collapseExample' + resource.resourceId"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    @click="citesLoad"
                  >
                    <i class="fas fa-quote-left"></i>
                  </a>
                </div>
                 <p class="resource--add-text text-uppercase" v-if="resource.doi != null">{{ citationVal.replace(/[0-9]/g, '') }} cite</p>
                <!-- end citation -->
              </div>
              <!-- start citation -->
              <div class="resourse-rights">
                <p
                  class="collapse resource--add-tex"
                  :id="'collapseExample' + resource.resourceId"
                  v-html="citationTxt"
                ></p>
              </div>
              <!-- end citation -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";

import StudentResourceSdg from "./StudentResourceSdg.vue";
import { useStore } from "vuex";
import { backendURL } from "../../../app-constants";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: {
    StudentResourceSdg,
  },
  props: ["resource", "libraryList"],
  emits: ["update-my-library"],
  setup(props, { emit }) {
    const store = useStore();
    const test = ref(0);
    const isInbibray = ref(
      props.libraryList.indexOf(props.resource.resourceId) !== -1
    );

    const lockIconCss = computed(() => {
      return props.resource.publicAccess ? "fas fa-unlock-alt" : "fas fa-lock";
    });
    const lockIconColourCss = computed(() => {
      return props.resource.publicAccess
        ? "resource-icons--success"
        : "resource-icons--danger";
    });

    const lockTextColourCss = computed(() => {
      return props.resource.publicAccess
        ? "resource--add-text"
        : "resource--add-text-paid";
    });

    const lockText = computed(() => {
      return props.resource.publicAccess ? "Open access" : "Paid access";
    });

    const addIconCss = ref(isInbibray.value ? "fas fa-minus" : "fas fa-plus");
    const addText = ref(
      isInbibray.value ? "Remove from my library" : "Add to my library"
    );

    const citationTxt = ref("");
    const citationVal = ref("");

    /*  const addIconCss = computed(() => {
      return isInbibray.value ? "fas fa-minus" : "fas fa-plus";
    });

    const addText = computed(() => {
      return isInbibray.value ? "Remove from my library" : "Add to my library";
    });*/

    function updateMyLibray() {
      const myLibObj = {
        id: props.resource.id,
        isInbibray: isInbibray.value,
      };

      test.value = props.resource.id;

      emit("update-my-library", { myLibObj });
    }

    watch(test, (value) => {
      if (value) {
        addIconCss.value = isInbibray.value ? "fas fa-plus" : "fas fa-minus";
        addText.value = isInbibray.value
          ? "Add to my library"
          : "Remove from my library";
      }
    });

    const citesLoad = async () => {
      if(citationVal.value !== null){
      try {
        const Cite = require("citation-js");
        let url = props.resource.doi != null ? props.resource.doi : "";
        let example = await new Cite.async(url);

        let output = example.format("bibliography", {
          format: "html",
          template: citationVal.value,
          lang: "en-US",
        });
        citationTxt.value = output;
      } catch (error) {
        notify({
          type: "error",
          title: "ERROR",
          text: "Invalid DOI url",
        });
      }
      }else{
        notify({
          type: "warn",
          title: "Citation",
          text: "Select citation type in detail thesis page",
        });
      }
    };

    onMounted(() => {
      loadDetails();
    });

    const loadDetails = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getUserThesisDetails/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          citationVal.value = resposeData.data.citation;
        }
      }
    };

    return {
      lockIconColourCss,
      lockIconCss,
      addIconCss,
      lockTextColourCss,
      lockText,
      addText,
      updateMyLibray,
      citesLoad,
      citationTxt,
      citationVal
    };
  },
};
</script>
