<template>
  <button
    class="btn-tips"
    type="button"
    data-bs-toggle="offcanvas"
    :data-bs-target="'#' + page + iconId"
    aria-controls="offcanvasRight"
    @click="getInfoData()"
  >
    <i class="fas fa-question"></i>
  </button>
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    :id="page + iconId"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header">
      <h3 id="offcanvasRightLabel" class="display-5--title mt-1">HELP GUIDE</h3>
      <button
        type="button"
        class="btn-close--model text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        X
      </button>
    </div>
    <div class="offcanvas-body" v-html="desc"></div>
  </div>
</template>
<script>
import { backendURL } from "../../app-constants";
export default {
  props: ["page", "iconId"],
  data() {
    return {
      iconNum: this.iconId,
      description: "",
      desc: "",
    };
  },
  methods: {
    getInfoData: function () {
      this.token = this.$store.getters["user/token"];
      fetch(
        backendURL +
          "/api/auth/information/getInformationGuid/" +
          this.page +
          "/" +
          this.iconId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          this.description = res.data;
        });
    },
  },
  watch: {
    //  description: function () {
    //  this.desc = "";
    //     this.desc = this.description;
    //   },
    description: {
      handler(after) {
        this.desc = after;
      },
    },
  },
};
</script>
