<template>
  <notifications position="top center"></notifications>
  <div class="container">
    <div class="help-guide">
      <h4 class="main-heading text-start">THESIS WRITING</h4>
      <div class="float-end"><Canvace :page="page" :iconId="1"></Canvace></div>
    </div>
  </div>

  <thesis-head-details></thesis-head-details>

  <section id="my-progress" class="my-progress">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 my-progress">
          <div class="my-progress-boxes">
            <div class="writing-content">
              <h5 class="writing-content--title">
                THESIS WRITING PROGRESS
                <br />
                <button
                  v-if="showUpdateSectionOrder"
                  type="button"
                  class="btn-sm btn-task--success"
                  @click="updateSectionOrder"
                >
                  Update section order
                </button>
              </h5>
              <div class="thesis-icons--guides text-center">
                <p class="thesis-about--date-descrip">REARRANGE SECTIONS</p>
                <a
                  ><span> <Canvace :page="page" :iconId="2"></Canvace></span
                ></a>
                <!-- <router-link to="/student/thesis-complete" class="thesis"
                  ><i class="fas fa-info"></i
                ></router-link> -->
              </div>

              <div class="thesis-icons--edit text-center">
                <p class="thesis-about--date-descrip">ADD NEW SECTION</p>
                <a
                  to="/student/thesis-complete"
                  class="thesis"
                  @click="loadAddNew"
                  ><i class="fas fa-plus"></i
                ></a>
              </div>
            </div>
            <div>
              <div
                class="
                  d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block
                "
              >
                <draggable :list="sectiosData" @change="log">
                  <thesis-section
                    v-for="section in sectiosData"
                    :id="section.id"
                    :key="section.id"
                    :title="section.title"
                    :startDate="section.startDate"
                    :endDate="section.endDate"
                    :sectionStatus="section.status"
                    @reload-list="loadSections"
                  ></thesis-section>
                </draggable>
              </div>
              <div
                class="d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none"
              >
                <thesis-section
                  v-for="section in sectiosData"
                  :id="section.id"
                  :key="section.id"
                  :title="section.title"
                  :startDate="section.startDate"
                  :endDate="section.endDate"
                  :sectionStatus="section.status"
                  @reload-list="loadSections"
                ></thesis-section>
              </div>
            </div>
            <edit-section
              v-if="showAddNew"
              @hide-componant="hideAddNew"
              :listIndex="sectiosData.length"
            ></edit-section>
          </div>

          <div
            id="exportContent"
            style="display: none"
            v-html="printContent"
          ></div>
          <button
            class="btn-task--success me-md-2"
            @click="export2Word('exportContent')"
          >
            Export whole thesis to ms word <i class="fas fa-file-word"></i>
          </button>
        </div>
      </div>
    </div>
  </section>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { VueDraggableNext } from "vue-draggable-next";
import { backendURL } from "../../../app-constants";

import ThesisSection from "./ThesisSection.vue";
import ThesisHeadDetails from "./ThesisHeadDetails.vue";
import EditSection from "./EditSection.vue";
import BaseSpinner from "../../UI/BaseSpinner.vue";
import Canvace from "../../canvasComponent/OffCanvace.vue";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: {
    ThesisSection,
    draggable: VueDraggableNext,
    ThesisHeadDetails,
    EditSection,
    BaseSpinner,
    Canvace,
  },
  setup() {
    const store = useStore();
    const sectiosData = ref([]);
    const showAddNew = ref(false);
    const showUpdateSectionOrder = ref(false);
    const isLoading = ref(false);
    const page = "thesis";
    const printContent = ref("");

    const loadSections = async () => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getThesisSections/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          sectiosData.value = resposeData.data;
          let writeContent = "";
          if (sectiosData.value.length > 0) {
            for (var i = 0; i < sectiosData.value.length; i++) {
              writeContent =
                writeContent + "<br>" + sectiosData.value[i].writeDetails;
            }
            printContent.value = writeContent;
          }
        }
      }
    };

    onMounted(() => {
      loadSections();
    });

    const loadAddNew = () => {
      showAddNew.value = true;
    };

    const hideAddNew = () => {
      showAddNew.value = false;
      loadSections();
    };

    function log() {
      showUpdateSectionOrder.value = true;
    }
    const updateSectionOrder = async () => {
      try {
        showUpdateSectionOrder.value = false;
        //isLoading.value = true;

        const dataArray = new Array();
        sectiosData.value.forEach((element, index) => {
          let Obj = {
            listIndex: index,
            id: element.id,
          };
          dataArray.push(Obj);
        });

        const token = store.getters["user/token"];

        const response = await fetch(
          backendURL + "/api/updateSectionListIndex/",
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify([...dataArray]),
          }
        );
        const resposeData = await response.json();
        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error update section order"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          loadSections();

          notify({
            type: "success",
            title: "SUCCESS",
            text: "Successfully saved!",
          });
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    const export2Word = (element, filename = "") => {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;
      var blob = new Blob(["\ufeff", html], {
        type: "application/msword",
      });
      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);
      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";
      // Create download link element
      var downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;
        // Setting the file name
        downloadLink.download = filename;
        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    };

    return {
      sectiosData,
      log,
      showAddNew,
      loadAddNew,
      hideAddNew,
      loadSections,
      updateSectionOrder,
      showUpdateSectionOrder,
      isLoading,
      page,
      export2Word,
      printContent,
    };
  },
};
</script>
