<template>
  <!-- THE NAVBAR SECTION -->
  <!-- <container class="intro-section"> -->
  <nav class="navbar navbar-expand-lg navbar-light menu">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo.svg" alt="Thesis Pro" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link active">HOME</router-link>
          </li>
        </ul>
        <router-link to="/signin" class="rounded-pill btn-rounded">
          SIGN IN
          <span><i class="fas fa-user"></i></span>
        </router-link>
      </div>
    </div>
  </nav>
  <!-- </container> -->
  <!-- THE NAVBAR SECTION END -->
  <!-- SIGNIN SECTION -->

  <section id="contact" class="login">
    <div class="container">
      <div class="row text-center">
        <h2 class="login-text text-start mb-0">SIGN UP TO THESIS PRO</h2>

        <p class="lead mt-0 text-start text-light">
          Already have a account?
          <router-link to="/signin"> SIGN IN </router-link>
        </p>
      </div>

      <!-- SIGNIN FORM -->
      <div class="row">
        <div class="col-12 col-lg-6 gradient shadow p-3">
          <div class="login-info w-100">
            <h4 class="display-3--title fw-blod">
              WELCOME TO THESIS PRO
            </h4>
            <p class="lh-lg">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Enim
              ratione odio harum, veritatis excepturi perferendis aliquid aut
              quia doloribus similique sed consectetur voluptatum vel numquam,
              sunt fugiat unde ea laudantium!
            </p>
            <h4 class="display-3--brief">With Thesis Pro you can do</h4>
            <ul class="login-info__list">
              <li>Online resoures on your fingertip</li>
              <li>Thesis assesment on real-time</li>
              <li>Connect with your supervisor/ examiner</li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-6 bg-white shadow p-3">
          <div class="form w-100 pb-2">
            <div class="form w-100 pb-2">
              <h4 class="display-3--title mb-4 fw-blod">SIGN UP</h4>
            </div>

            <div class="social-login text-center">
              <google-log-in :role="role" :token="token"></google-log-in>
              <span class="text-center mb-3">or use your own email</span>
            </div>

            <form @submit.prevent="saveUser" autocomplete="off" class="row">
              <div class="row g-3">
                <div
                  class="alert alert-info alert-dismissible fade show col-12"
                  role="alert"
                  v-if="!formIsValid"
                >
                  <span class="comman-error">{{ invalidmessage }}</span>
                </div>
                <div
                  class="col-12"
                  :class="{ errors: !regData.firstName.isValid }"
                >
                  <label for="firstName" class="form-label">First name</label>
                  <input
                    type="text"
                    id="firstName"
                    class="form-control"
                    name="firstName"
                    v-model.trim="regData.firstName.val"
                    @blur="clearValidity('firstName')"
                  />
                  <div class="error-message">
                    Valid first name is required.
                  </div>
                </div>

                <div
                  class="col-12"
                  :class="{ errors: !regData.lastName.isValid }"
                >
                  <label for="lastName" class="form-label">Last name</label>
                  <input
                    type="text"
                    id="lastName"
                    class="form-control"
                    name="lastName"
                    v-model.trim="regData.lastName.val"
                    @blur="clearValidity('lastName')"
                  />
                  <div class="error-message">
                    Valid last name is required.
                  </div>
                </div>

                <div
                  class="col-12"
                  :class="{ errors: !regData.university.isValid }"
                >
                  <label for="university" class="form-label">University</label>
                  <input
                    type="text"
                    id="university"
                    class="form-control"
                    name="university"
                    v-model="regData.university.val"
                    @blur="clearValidity('country')"
                  />
                </div>

                <div
                  class="col-12"
                  :class="{ errors: !regData.country.isValid }"
                >
                  <label for="country" class="form-label">Country</label>
                  <input
                    type="text"
                    id="country"
                    class="form-control"
                    name="country"
                    v-model="regData.country.val"
                    @blur="clearValidity('country')"
                  />
                </div>

                <div
                  class="col-12"
                  :class="{ errors: !regData.emailAddress.isValid }"
                >
                  <label for="emailAddress" class="form-label"
                    >Email <span class="text-muted"></span
                  ></label>
                  <input
                    type="email"
                    id="emailAddress"
                    name="emailAddress"
                    v-model="regData.emailAddress.val"
                    class="form-control"
                    @blur="clearValidity('emailAddress')"
                  />
                  <div class="error-message">
                    Please enter a valid email address.
                  </div>
                </div>
                <div class="text-center d-grid mt-1">
                  <button
                    type="submit"
                    class="btn btn-primary rounded-pill pt-3 pb-3"
                  >
                    SUBMIT
                    <i class="fas fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- END SIGNIN FORM -->
    </div>
  </section>
  <!--  END CONTACT US/LOGIN SECTION -->

  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { backendURL, UserRoles } from "../../app-constants.js";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";
import GoogleLogIn from "../../components/user/ext-log-ins/GoogleLogIn.vue";

export default {
  components: { BaseSpinner, GoogleLogIn },
  props: ["token"],
  setup(props) {
    const router = useRouter();

    const regData = reactive({
      firstName: { val: "", isValid: true },
      lastName: { val: "", isValid: true },
      emailAddress: { val: "", isValid: true },
      country: { val: "", isValid: true },
      university: { val: "", isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);

    function clearValidity(input) {
      regData[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;

      if (regData.firstName.val == "") {
        regData.firstName.isValid = false;
        formIsValid.value = false;
      }
      if (regData.lastName.val == "") {
        regData.lastName.isValid = false;
        formIsValid.value = false;
      }
      if (regData.emailAddress.val == "") {
        regData.emailAddress.isValid = false;
        formIsValid.value = false;
      }
    }

    async function saveUser() {
      //isLoading.value = true;
      validateFrom();

      if (!formIsValid.value) {
        isLoading.value = false;
        invalidmessage.value = "Required  fields missing";
        return;
      }
      try {
        const response = await fetch(backendURL + "/api/auth/signUp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: regData.firstName.val,
            lastName: regData.lastName.val,
            country: regData.country.val,
            university: regData.university.val,
            email: regData.emailAddress.val,
            role: UserRoles.SUPERVISOR,
            token: props.token,
          }),
        });

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error register student"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;

          router.push("/supervisor-registration-success");
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = error.message;
      }
    }

    const role = computed(() => UserRoles.SUPERVISOR);

    return {
      regData,
      formIsValid,
      invalidmessage,
      clearValidity,
      saveUser,
      isLoading,
      role,
    };
  },
};
</script>
