<template>
  <div class="alert alert-success" role="alert" v-show="linkjsonSuc">
    Succesfully saved!
  </div>
  <div class="alert alert-danger" role="alert" v-show="linkjsonErr">
    Something went wrong!
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
      <form autocomplete="off" class="row" @submit.prevent="saveSubTips">
        <div class="col-12" :class="{ errors: linkValidation }">
          <label for="quest" class="form-label"
            >Link <span class="text-muted"></span
          ></label>
          <input type="hidden" id="lId" name="lId" v-model="linkId" />
          <input
            type="text"
            id="quest"
            name="quest"
            class="form-control"
            v-model="link"
            maxlength="250"
          />
          <div class="error-message">Please enter link.</div>
        </div>
        <div class="col-12" :class="{ errors: linkNameValidation }">
          <label for="quest" class="form-label"
            >Link name <span class="text-muted"></span
          ></label>
          <input
            type="text"
            id="quest"
            name="quest"
            class="form-control"
            v-model="linkName"
             maxlength="250"
          />
          <div class="error-message">Please enter link name.</div>
        </div>
        <div class="col-12" :class="{ errors: linkDescValidation }">
          <label for="quest" class="form-label"
            >Link description <span class="text-muted"></span
          ></label>
          <input
            type="text"
            id="quest"
            name="quest"
            class="form-control"
            v-model="linkDesc"
             maxlength="250"
          />
          <div class="error-message">Please enter link description.</div>
        </div>
        <div class="col-12" :class="{ errors: subTipValidation }">
          <label class="form-label">Category</label>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="subTips"
          >
            <option v-for="m in allSubTips" :key="m.id" :value="m.id">
              {{ m.subTips }}
            </option>
          </select>
          <div class="error-message">Please select category.</div>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-left mt-2">
          <button class="btn-task--success me-md-2 mb-3" type="submit">
            SAVE <i class="fas fa-save"></i>
          </button>
          <button class="btn-task--danger me-md-2 mb-3" type="button">
            CANCEL <i class="fas fa-window-close"></i>
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <table class="table table-striped table-bordered mt-2">
      <thead>
        <tr>
          <th>Link</th>
          <th>Link name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="link in allLink" :key="link.id">
          <td>{{ link.link }}</td>
          <td>{{ link.name }}</td>
          <td><button @click="viewResource(link.id)">View</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { backendURL } from "../../../app-constants";
export default {
  props: ["loadSubTips", "loadAllLinkNew"],
  data() {
    return {
      allSubTips: [],
      linkjsonSuc: false,
      linkjsonErr: false,
      subTip: {},
      subTips: "",
      link: "",
      linkName: "",
      allLink: [],
      linkId: "",
      linkDesc: "",
      linkValidation: false,
      formIsValid: false,
      linkNameValidation: false,
      linkDescValidation: false,
      subTipValidation: false,
    };
  },
  watch: {
    loadSubTips: {
      handler(subTips) {
        this.allSubTips = subTips;
      },
    },
    loadAllLinkNew: {
      handler(link) {
        this.allLink = link;
      },
    },
  },
  methods: {
    validateFrom: function () {
      this.formIsValid = true;
      if (this.link == "") {
        this.linkValidation = true;
        this.formIsValid = false;
      }
      if (this.linkName == "") {
        this.linkNameValidation = true;
        this.formIsValid = false;
      }
      if (this.linkDesc == "") {
        this.linkDescValidation = true;
        this.formIsValid = false;
      }
      if (this.subTips == "") {
        this.subTipValidation = true;
        this.formIsValid = false;
      }
    },
    saveSubTips: function () {
      this.validateFrom();
      if (this.formIsValid) {
        this.linkValidation = false;
        this.linkNameValidation = false;
        this.linkDescValidation = false;
        this.subTipValidation = false;

        this.token = this.$store.getters["user/token"];
        this.subTip = { id: this.subTips };
        var object = {
          id: this.linkId,
          subTips: this.subTip,
          link: this.link,
          name: this.linkName,
          description: this.linkDesc,
        };
        fetch(backendURL + "/api/auth/tip/saveTipsLink", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
          body: JSON.stringify(object),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              this.allLink = res.data;
              this.linkjsonSuc = true;
              this.linkId = "";
              this.subTip = "";
              this.link = "";
              this.linkName = "";
              this.linkDesc = "";
            } else {
              this.linkjsonErr = true;
            }
          });
      }
    },
    viewResource: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/loadLinksById/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.linkId = res.data.id;
          this.link = res.data.link;
          this.linkName = res.data.name;
          this.linkDesc = res.data.description;
          this.subTips = res.data.subTips.id;
        });
    },
  },
  mounted() {
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/tip/loadSubTips", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allSubTips = res.data;
      });

    fetch(backendURL + "/api/auth/tip/loadLinks", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allLink = res.data;
      });
  },
};
</script>
