<template>
  <div class="my-progress-boxes">
    <div class="writing-content">
      <h5 class="writing-content--title">
        MY LIBRARY - LATEST
      </h5>
    </div>
    <div
      class="overflow-auto p-1 mb-3 mb-md-0 me-md-3 bg-none"
      style="max-width:  auto; max-height: 400px;"
    >
      <resource-list-item
        v-for="resource in resourceList"
        :key="resource.id"
        :resource="resource"
      ></resource-list-item>
    </div>
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";
import ResourceListItem from "../thesis-writing/ResourceListItem.vue";

export default {
  components: {
    ResourceListItem,
    BaseSpinner,
  },
  props: ["sectionType"],
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    //const resourceList = reactive([]);
    const resourceList = ref([]);

    onMounted(async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getLatestLibraryList/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          resourceList.value = resposeData.data;
        }
      }
      isLoading.value = false;
    });

    return {
      resourceList,
      isLoading,
    };
  },
};
</script>
