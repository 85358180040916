<template>
  <section id="thesis-about" class="thesis-about mb-3">
    <div class="container">
      <section class="thesis-about-box">
        <div class="row no-gutters mb-1">
          <div class="col-xl-8 col-md-6 mb-1 px-2 px-md-3">
            <div class="row">
              <div class="col-md-1 text-center">
                <!-- profile picture of student-->

                <div class="user-picture mt-1">
                  <img
                    :src="headerDetails.profImage"
                    alt="mdo"
                    width="50"
                    height="50"
                    class="rounded-circle shadow-lg"
                  />
                </div>
              </div>
              <div class="col-md-11 text-right text-600">
                <div class="ml-2 media-body">
                  <div class="thesis-about">
                    <p class="thesis-about--name mb-2">
                      Student's Name: {{ headerDetails.userName }}
                    </p>

                    <p class="thesis-about--title mb-2">
                      <a href="#">{{ headerDetails.thesisTitle }} </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 px-2 px-md-3">
            <div class="row">
              <div class="col-lg-12">
                <div class="row g-2">
                  <div class="col-md-4">
                    <p class="thesis-about--date-descrip">FINISHING DATE</p>
                    <p class="thesis-about--date">
                      {{ headerDetails.finishingDate }}
                    </p>
                  </div>
                  <div class="col-md-8 mb-2 my-2">
                    <div
                      class="
                        student-request
                        d-sm-flex
                        align-items-center
                        justify-content-end
                      "
                    >
                      <button
                        type="button"
                        class="btn-task--notifications btn-sm me-md-2"
                      >
                        NOTIFICATIONS <span class="badge"> 4</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { watch } from "vue";
import { useStore } from "vuex";
import { onMounted, reactive } from "vue";
import { backendURL } from "../../../app-constants.js";

export default {
  props: ["thesisId"],
  setup(props) {
    const store = useStore();

    const headerDetails = reactive({
      userName: null,
      thesisTitle: null,
      finishingDate: null,
      latestDeadLine: null,
      profImage: null,
    });

    const loadDetails = async (thesisId) => {
      if (thesisId) {
        const token = store.getters["user/token"];
        const response = await fetch(
          backendURL + "/api/getThesisHeaderDetailsById/" + thesisId,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              //     //"Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            headerDetails.userName = resposeData.data.userName;
            headerDetails.thesisTitle = resposeData.data.thesisTitle;
            headerDetails.finishingDate = resposeData.data.finishingDate;
            headerDetails.latestDeadLine = resposeData.data.latestDeadLine;
            headerDetails.profImage = resposeData.data.profImage;
          }
        }
      }
    };

    onMounted(() => {
      loadDetails(props.thesisId);
    });

    watch(
      () => props.thesisId,
      (value) => {
        loadDetails(value);
      }
    );

    return {
      headerDetails,
    };
  },
};
</script>
