<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
        <form autocomplete="off" class="row" @submit.prevent="saveDetailTips">
          <div class="col-12" :class="{ errors: altrValidation }">
            <label for="quest" class="form-label"
              >Alternative <span class="text-muted"></span
            ></label>
            <input type="hidden" id="dId" name="dId" v-model="dId" />
            <input
              type="text"
              id="dTips"
              name="dTips"
              class="form-control"
              v-model="dTips"
              maxlength="250"
            />
            <div class="error-message">Please enter alternative.</div>
          </div>
          <div class="col-12" :class="{ errors: resValidation }">
            <label class="form-label">Resource</label>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="narrowTip"
            >
              <option v-for="m in narrowTips" :key="m.id" :value="m.id">
                {{ m.narrowTips }}
              </option>
            </select>
            <div class="error-message">Please select resource.</div>
          </div>
          <div class="my-progress-boxes">
            <div class="col-12 mt-2" :class="{ errors: resDescValidation }">
              <quill-editor v-model:value="desc" />
              <div class="error-message">Please enter description.</div>
            </div>
          </div>
          <div>
            <button class="btn-task--success me-md-2 mb-3" type="submit">
              SAVE <i class="fas fa-save"></i>
            </button>
            <button class="btn-task--danger me-md-2 mb-3" type="button">
              CANCEL <i class="fas fa-window-close"></i>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <table class="table table-striped table-bordered mt-2">
        <thead>
          <tr>
            <th>Alternative</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="alternative in allAlternative" :key="alternative.id">
            <td>{{ alternative.detailTips }}</td>
            <td><button @click="viewResource(alternative.id)">View</button></td>
            <td>
              <button @click="deleteResource(alternative.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { backendURL } from "../../../app-constants";
import { quillEditor } from "vue3-quill";
export default {
  props: ["loadNarrowTips", "loadAllDetailTips"],
  components: {
    quillEditor,
  },
  data() {
    return {
      dTips: "",
      narrowTip: "",
      narrowTipObj: {},
      narrowTips: [],
      desc: "",
      jsonErr: false,
      jsonSuc: false,
      allAlternative: [],
      dId: "",
      formIsValid: false,
      altrValidation: false,
    };
  },
  watch: {
    loadNarrowTips: {
      handler(narrow) {
        this.narrowTips = narrow;
      },
    },
    loadAllDetailTips: {
      handler(detailTip) {
        this.allAlternative = detailTip;
      },
    },
  },
  methods: {
    validateFrom: function () {
      this.formIsValid = true;
      if (this.dTips == "") {
        this.altrValidation = true;
        this.formIsValid = false;
      }
      if (this.narrowTip == "") {
        this.resValidation = true;
        this.formIsValid = false;
      }
      if (this.desc == "") {
        this.resDescValidation = true;
        this.formIsValid = false;
      }
    },
    saveDetailTips: function () {
      this.validateFrom();
      if (this.formIsValid) {
        this.altrValidation = false;
        this.resValidation = false;
        this.resDescValidation = false;

        this.token = this.$store.getters["user/token"];
        this.narrowTipObj = { id: this.narrowTip };
        var object = {
          id: this.dId,
          narrowTips: this.narrowTipObj,
          detailTips: this.dTips,
          detailDesc: this.desc,
        };
        fetch(backendURL + "/api/auth/tip/saveDetailTips", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
          body: JSON.stringify(object),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              this.allAlternative = res.data;
              this.dId = "";
              this.narrowTip = "";
              this.desc = "";
              this.dTips = "";
              this.jsonSuc = true;
            } else {
              this.jsonErr = true;
            }
          });
      }
    },
    viewResource: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/loadDetailById/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.dId = res.data.id;
          this.dTips = res.data.detailTips;
          this.desc = res.data.detailDesc;
          this.narrowTip = res.data.narrowTips.id;
        });
    },

    deleteResource: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/deleteDetailById/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({id:id}),
      })
        .then((res) => res.json())
        .then((res) => {
          this.allAlternative = res.data;
        });
    },
  },
  mounted() {
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/tip/loadNarrowTips", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.narrowTips = res.data;
      });

    fetch(backendURL + "/api/auth/tip/loadDetail", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allAlternative = res.data;
      });
  },
};
</script>
