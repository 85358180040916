<template>
  <div class="my-progress-boxes" v-if="hasResult">
    <div class="writing-content">
      <h5 class="writing-content--title">
        R&D RESOURCES
      </h5>
    </div>
    <div
      class="overflow-auto p-1 mb-3 mb-md-0 me-md-3 bg-none"
      style="max-width:  auto; max-height: 600px;"
    >
      <resource-list-item
        v-for="resource in resourceList"
        :key="resource.id"
        :resource="resource"
      ></resource-list-item>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import ResourceListItem from "./ResourceListItem.vue";
import { backendURL } from "../../../app-constants";

export default {
  components: {
    ResourceListItem,
  },
  props: ["sectionId"],
  setup(props) {
    const store = useStore();

    const resourceList = ref([]);
    const hasResult = ref(false);

    onMounted(async () => {
      const token = store.getters["user/token"];
      const response = await fetch(
        backendURL + "/api/getResourceForSection/" + props.sectionId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          resourceList.value = resposeData.data;
          hasResult.value = resposeData.data.length > 0;
        }
      }
    });

    return {
      resourceList,
      hasResult,
    };
  },
};
</script>
