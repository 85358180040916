<template>
  <div class="container">
    <div class="container">
      <div class="tabPanel">
        <label for="tab-1" tabindex="0"></label>
        <input
          id="tab-1"
          type="radio"
          name="tabs"
          checked="true"
          aria-hidden="true"
        />
        <h2>Main</h2>
        <div>
          <MainTips></MainTips>
        </div>
        <label for="tab-2" tabindex="0"></label>
        <input
          id="tab-2"
          type="radio"
          name="tabs"
          aria-hidden="true"
          @click="loadMainTips"
        />
        <h2>Category</h2>
        <div>
          <SubTips
            :loadMaintips="allMaintips"
            :reloadSubTips="allSubTips"
          ></SubTips>
        </div>

        <label for="tab-3" tabindex="0"></label>
        <input
          id="tab-3"
          type="radio"
          name="tabs"
          aria-hidden="true"
          @click="loadSubTips"
        />
        <h2>Resource</h2>
        <div>
          <NarrowTips
            :loadSubTips="allSubTips"
            :loadAllNarrowTips="allNarrowTips"
          ></NarrowTips>
        </div>

        <label for="tab-4" tabindex="0"></label>
        <input
          id="tab-4"
          type="radio"
          name="tabs"
          aria-hidden="true"
          @click="loadNarrowTips"
        />
        <h2>Alternative</h2>
        <div>
          <DetailTips
            :loadNarrowTips="allNarrowTips"
            :loadAllDetailTips="allDetaiTips"
          ></DetailTips>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { backendURL } from "../../../app-constants";
import MainTips from "./AddMainTips.vue";
import SubTips from "./AddSubTips.vue";
import NarrowTips from "./NarrowTips.vue";
import DetailTips from "./AddDetailTips.vue";
export default {
  components: {
    MainTips,
    SubTips,
    NarrowTips,
    DetailTips,
  },
  data() {
    return {
      allMaintips: [],
      allSubTips: [],
      allNarrowTips: [],
      allDetaiTips:[]
    };
  },
  methods: {
    loadMainTips: function () {
      this.loadSubTips();
      this.token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/loadMainTips", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.allMaintips = res.data;
        });
    },
    loadSubTips: function () {
      this.loadNarrowTips();
      fetch(backendURL + "/api/auth/tip/loadSubTips", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.allSubTips = res.data;
        });
    },
    loadNarrowTips: function () {
      this.loadDetailTips();
      fetch(backendURL + "/api/auth/tip/loadNarrowTips", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.allNarrowTips = res.data;
        });
    },
    loadDetailTips: function () {
      fetch(backendURL + "/api/auth/tip/loadDetail", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.allDetaiTips = res.data;
        });
    },
  },
  mounted() {
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/tip/loadMainTips", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allMaintips = res.data;
      });

    fetch(backendURL + "/api/auth/tip/loadSubTips", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allSubtips = res.data;
      });
  },
};
</script>
