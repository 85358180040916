<template>
  <div class="container">
    <div class="help-guide">
    <h4 class="main-heading text-start">
      MY PROGRESS  
    </h4> 
     <div class="float-end"><Canvace :page="page" :iconId="1"></Canvace></div>
    </div>
  </div>

  <thesis-head-details></thesis-head-details>

  <!-- MY PROGRESS SECTION -->
  <section id="my-progress" class="my-progress">
    <div class="container">
      <div class="row text-start">
        <div class="my-progress-content">
          <h4></h4>
          <!-- <hr /> -->
        </div>
      </div>
    </div>
  </section>

  <!-- MY PROGRESS SECTION -->
  <div class="container">
    <div class="row row-cols-sm-12">
      <div class="col-xxl-8 col-xl-8 order-xl-1 mb-4">
        <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4">
          <div class="my-progress-boxes">
            <progress-chart :thesisId="thesisId"></progress-chart>
          </div>
        </div>

        <!-- COMMUNICATION WITH SUPERVISOR -->

        <div class="my-progress-boxes">
          <common-forum :thesisId="thesisId" :isStudent="true"></common-forum>
        </div>
      </div>

      <div class="col-xxl-4 col-xl-4 order-xl-1 mb-4">
        <div class="column">
          <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4">
            <div class="my-progress-boxes">
              <dead-line-indicator :thesisId="thesisId"></dead-line-indicator>
            </div>
          </div>
          <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4">
            <my-library-latest-list></my-library-latest-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END MY PROGRESS SECTION -->
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import { backendURL } from "../../app-constants";

import MyLibraryLatestList from "./progress/MyLibraryLatestList.vue";
import CommonForum from "../forum/CommonForum.vue";
import DeadLineIndicator from "./progress/DeadLineIndicator.vue";
import ProgressChart from "./progress/ProgressChart.vue";
import ThesisHeadDetails from "./thesis-writing/ThesisHeadDetails.vue";
import BaseSpinner from "../UI/BaseSpinner.vue";
import Canvace from "../canvasComponent/OffCanvace.vue";

export default {
  components: {
    MyLibraryLatestList,
    CommonForum,
    DeadLineIndicator,
    ProgressChart,
    ThesisHeadDetails,
    BaseSpinner,
    Canvace,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const page = "progress";

    const thesisId = ref(null);
    onMounted(async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getThesisHeaderDetails/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          thesisId.value = resposeData.data.thesisId;
        }
      }
      isLoading.value = false;
    });
    return {
      thesisId,
      isLoading,
      page,
    };
  },
};
</script>
