<template>
  <!-- <footer class="footer">
    <div class="container">
      <div class="row text-muted">
        <div class="col-8 text-start">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a class="text-muted mx-3" href="#">SUPPORT</a>
            </li>
            <li class="list-inline-item">
              <a class="text-muted mx-3" href="#">HELP CENTER</a>
            </li>
            <li class="list-inline-item">
              <a class="text-muted mx-3" href="#">PRIVACY</a>
            </li>
            <li class="list-inline-item">
              <router-link to="/terms-condition">
              <a class="text-muted mx-3">TERMS & CONDITIONS</a>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-4 text-end">
          <p class="mb-0">
            © 2021 - <a href="index.html" class="text-muted">SciMind AB</a>
          </p>
        </div>
      </div>
    </div>
  </footer> -->

  <footer class="footer mt-auto py-5 bg-none">
    <div class="container">
      <div class="row g-5">
        <div class="col-xl-6 col-md-12 col-12 mb-3">
          <div class="feature col">
            <div class="feature-icon bg-none">
              <div class="footer-element">
                <span class="footer-element--icon text-center"
                  ><i class="fas fa-university"></i
                ></span>
                <p class="footer-element--text text-start mt-2">ABOUT US</p>
                <p class="footer-element--intro">
                  Thesis Pro is one of the systems brought to you by <a  target="_blank" v-bind:href="footerLink.scimindLink">SciMind AB</a>,
                  a company based in Stockholm, Sweden. SciMind AB was founded
                  in 2016 as a spin-off company via the <a  target="_blank" v-bind:href="footerLink.stockholmLink">Stockholm university</a>
                  innovation office and <a  target="_blank" v-bind:href="footerLink.suholdingLink">SU Holding</a>. In 2017 SciMind was selected
                  as a <a  target="_blank" v-bind:href="footerLink.sTINGLink">STING company</a>.
                </p> 
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <div class="feature col">
            <div class="feature-icon bg-none">
              <div class="footer-element">
                <span class="footer-element--icon text-center"
                  ><i class="fas fa-envelope-open-text"></i
                ></span>
                <p class="footer-element--text text-start mt-2">CONTACT US</p>
                <span
                  ><a target="_blank" v-bind:href="footerLink.supportLink"
                    ><p class="footer-element--items text-start">support@thesispro.se</p></a
                  ></span
                >
                <span
                  ><a target="_blank" v-bind:href="footerLink.helpLink"
                    ><p class="footer-element--items text-start">
                      Help Center
                    </p></a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <div class="feature col">
            <div class="feature-icon bg-none">
              <div class="footer-element">
                <span class="footer-element--icon text-center"
                  ><i class="fas fa-file-contract"></i
                ></span>
                <p class="footer-element--text text-start mt-2">TERMS</p>
                <span
                  ><router-link to="/privacy-policy"
                    ><p class="footer-element--items text-start">
                     Privacy
                    </p></router-link
                  ></span
                >
                <span
                  ><router-link to="/terms-condition"
                    ><p class="footer-element--items text-start">
                      Terms & Conditions
                    </p></router-link
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rounded-social-buttons">
        <a
          class="social-button twitter"
          v-bind:href="footerLink.twiterLink"
          target="_blank"
          ><i class="fab fa-twitter"></i
        ></a>
        <a
          class="social-button linkedin"
          v-bind:href="footerLink.linkedInLink"
          target="_blank"
          ><i class="fab fa-linkedin-in"></i
        ></a>
        <a
          class="social-button youtube"
          v-bind:href="footerLink.youTubeLink"
          target="_blank"
          ><i class="fab fa-youtube"></i
        ></a>
        <a
          class="social-button instagram"
          v-bind:href="footerLink.instergramLink"
          target="_blank"
          ><i class="fab fa-instagram"></i
        ></a>
      </div>
    </div>
  </footer>
</template>
<script>
import { backendURL } from "../../app-constants";
export default {
  data() {
    return { 
      footerLink: {},
    };
  },
  mounted() {   
    fetch(backendURL + "/api/auth/footer/getAllFooterLink", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.footerLink = res.data;
      });
  },
};
</script>
