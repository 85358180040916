<template>
  <div class="resource-filter">
    <h5 class="resource-filter--title border-bottom mb-2">YEAR</h5>
    <div>
      <label class="form-label" for="startYear">
        From
      </label>
      <input
        class="form-control"
        type="text"
        name="startYear"
        id="startYear"
        v-model="filter.startYear"
      />
    </div>
    <div>
      <label class="form-label" for="endYear">
        Last year
      </label>
      <input
        class="form-control"
        type="text"
        name="endYear"
        id="endYear"
        v-model="filter.endYear"
      />
    </div>
    <div class="mt-2">
      <button class="btn-task--success me-md-2" @click="filterResources">
        Search <i class="fas fa-search"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
export default {
  emits: ["filter-resources", "searchByYear"],
  setup(props, { emit }) {
    const filter = reactive({
      startYear: null,
      endYear: null,
    });

    const filterResources = () => {
      emit("filter-resources", filter);
    };

    return {
      filter,
      filterResources,
    };
  },
};
</script>
