<template>
  <div>
    <div class="container">
      <div class="alert alert-success" role="alert" v-show="jsonSuc">
        Succesfully saved!
      </div>
      <div class="alert alert-danger" role="alert" v-show="jsonErr">
        Something went wrong!
      </div>
      <div class="row">
        <div class="my-progress-boxes">
          <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
            <form autocomplete="off" class="row" @submit.prevent="saveTerms">
              <div class="my-progress-boxes">
              <div class="col-12 mt-2" :class="{ errors: termValidation }">
                <input type="hidden" id="tId" name="tId" v-model="tId" />
                <label class="form-label">Terms & condition</label>

                <quill-editor v-model:value="term" />
                <!-- <textarea
                type="text" 
                id="desc"
                name="desc"
                placeholder="Terms & condition"
                class="form-control"
                rows="12"
                v-model="term"
                maxlength="5000"
              /> -->
                <div class="error-message">Please enter Terms & condition.</div>
              </div>
              </div>
              <div class="col-12 mt-2">
               
                  <button class="btn-task--success me-md-2 mb-3" type="submit">
                    SAVE <i class="fas fa-save"></i>
                  </button>
                  <button class="btn-task--danger me-md-2 mb-3" type="button">
                    CANCEL <i class="fas fa-window-close"></i>
                  </button>
                </div>
               
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { backendURL } from "../../../app-constants";
import { quillEditor } from "vue3-quill";
export default {
  components: { quillEditor },
  data() {
    return {
      term: "",
      terms: [],
      token: "",
      jsonErr: false,
      jsonSuc: false,
      formIsValid: false,
      termValidation: false,
      tId: "",
    };
  },
  methods: {
    validateFrom: function () {
      this.formIsValid = true;
      if (this.term == "") {
        this.termValidation = true;
        this.formIsValid = false;
      }
    },
    saveTerms: function () {
      this.validateFrom();
      if (this.formIsValid) {
        this.termValidation = false;

        this.token = this.$store.getters["user/token"];
        var object = { id: this.tId, termsText: this.term,type:"terms" };
        fetch(backendURL + "/api/auth/terms/saveTerms", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
          body: JSON.stringify(object),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              this.terms = res.data.termsText;
              this.jsonSuc = true;
            } else {
              this.jsonErr = true;
            }
          });
      }
    },
  },
  mounted() {
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/terms/listAllTerms/terms", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.term = res.data.termsText;
        this.tId = res.data.id;
      });
  },
};
</script>
