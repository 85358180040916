<template>
  <div>
    <div class="container">
      <notifications position="top center"></notifications>
      <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10 mt-5">
        <div class="user-credentials-boxes">
          <h5 class="user-credentials-boxes--title mb-3">
            Select your SDG area (Optional)
          </h5>
          <section class="sdg-section">
            <div class="sdg-container mt-3" v-if="sdgList !== 0">
              <div class="sdg-item mb-3" v-for="sdg in sdgList" :key="sdg.id">
                <img
                  :src="getImgUrl(sdg)"
                  alt="sdg"
                  width="80"
                  height="80"
                  class="mt-10 shadow-sm"
                />
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :value="sdg.id"
                    v-model="selectedSDG"
                    @change="getSelectedSDG($event)"
                    :checked="passSdg.find((s) => s === sdg.id)"
                  />
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckChecked"
                  ></label>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { backendURL } from "../../app-constants.js";
import { notify } from "@kyvg/vue3-notification";
export default {
  props: ["sdgSelected"],
  emits: ["selected-sdg"],
  data() {
    return {
      sdgList: [],
      selectedSDG: [],
      passSdg: [],
    };
  },
  watch: {
    sdgSelected: { 
      handler(sdg) {      
        for (let i = 0; i < sdg.length; i++) {
          if (sdg[i].id === undefined) {
            this.passSdg.push(sdg[i]);
           this.selectedSDG.push(sdg[i]);
          } else {
            this.passSdg.push(sdg[i].id); 
            this.selectedSDG.push(sdg[i].id); 
          }
        }
      },
    },
  },
  methods: {
    getSelectedSDG: function ($event) {
      if (this.selectedSDG.length <= 4) {
        this.$emit("selected-sdg", this.selectedSDG);
      } else {
        this.selectedSDG.pop();
        notify({
          type: "warn",
          title: "Note",
          text: "Maximum four SDG's can choose!",
        });

        $event.target.checked = false;
      }
    },
    getImgUrl(pic) {
      return require("@/assets/images/sdg/" + pic.sdgIcon);
    },
  },
  mounted() {
    this.token = this.$store.getters["user/token"];

    fetch(backendURL + "/api/auth/listAllSdg", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.sdgList = res.data;
      });
  },
};
</script>
