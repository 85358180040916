<template>
  <the-header></the-header>
  <section id="notifications" class="user-credentials">
    <div class="container">
      <div class="help-guide">
        <h4 class="main-heading text-start">NOTIFICATIONS</h4>
      </div>
      <div class="section container-fluid text-end">
        <a href="" @click="markAllAsRead"
          ><button class="all-read">MARK ALL AS READ</button></a
        >
      </div>
      <div class="row">
        <div class="col-lg-12 my-progress mb-1">
          <div class="notification-boxes">
            <notification-block
              v-for="not in nitificationList"
              :key="not.id"
              :notifiObj="not"
              @load-notifications="loadNotification"
            ></notification-block>
          </div>
        </div>
      </div>
    </div>
  </section>
  <base-spinner v-if="isLoading"></base-spinner>
  <the-footer></the-footer>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import TheHeader from "../../components/layouts/TheHeader.vue";
import NotificationBlock from "../../components/user/NotificationBlock.vue";
import { backendURL } from "../../app-constants";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: {
    TheHeader,
    NotificationBlock,
    BaseSpinner,
    TheFooter,
  },
  setup() {
    const store = useStore();
    const nitificationList = ref([]);
    const isLoading = ref(false);

    onMounted(() => {
      loadNotification();
    });

    const loadNotification = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getUserNotifications/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          isLoading.value = false;
          nitificationList.value = resposeData.data;
        }
      }
      isLoading.value = false;
    };

    const markAllAsRead = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/notificationMarkAllAsRead/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          isLoading.value = false;
          notify({
            type: "success",
            title: "SUCCESS",
            text: "All notifications marked as read",
          });
          loadNotification();
          store.dispatch("user/updateNotification");
        }
      }
      isLoading.value = false;
    };

    return {
      loadNotification,
      nitificationList,
      isLoading,
      markAllAsRead,
    };
  },
};
</script>

<style scoped>
.notifi-unread {
  background-color: #b8e2ea5c;
}
</style>
