<template>
  <div class="writing-content">
    <h5 class="writing-content--title">
      MY PROGRESS
    </h5>
  </div>

  <div>
    <vue3-chart-js
      :id="lineChart.id"
      ref="chartRef"
      :type="lineChart.type"
      :data="lineChart.data"
      :options="lineChart.options"
    ></vue3-chart-js>
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";

import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";

import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import zoomPlugin from "chartjs-plugin-zoom";
//import dataLabels from "chartjs-plugin-datalabels";

// globally registered and
Vue3ChartJs.registerGlobalPlugins([zoomPlugin]);

export default {
  props: ["thesisId"],
  components: {
    Vue3ChartJs,
    BaseSpinner,
  },

  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const chartRef = ref(null);

    const lineChart = reactive({
      id: 1,
      type: "line",
      // locally registered and available for this chart
      //plugins: [dataLabels],
      data: {
        labels: [],
        datasets: [
          {
            label: "Planned",
            data: [],
            fill: false,
            borderColor: "green",
            backgroundColor: "white",
          },
          {
            label: "Progress",
            data: [],
            fill: false,
            borderColor: "#00D8FF",
            tension: 0.5,
            backgroundColor: "white",
          },
        ],
      },
      options: {
        scales: {
          y: {
            title: {
              display: true,
              text: "Days",
            },
          },
        },
        spanGaps: true,
        plugins: {
          /*zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "y",
            },
          },*/
        },
      },
    });

    const loadChartData = async (selectedThesisId) => {
      if (selectedThesisId) {
        //isLoading.value = true;
        const token = store.getters["user/token"];
        const response = await fetch(
          backendURL + "/api/getThesisSectionsForChart/" + selectedThesisId,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            isLoading.value = false;
            lineChart.data.labels = resposeData.data.labelList;
            lineChart.data.datasets[0].data = resposeData.data.endDayList;
            lineChart.data.datasets[1].data = resposeData.data.completeDayList;

            chartRef.value.update(250);
          }
        }
        isLoading.value = false;
      }
    };

    onMounted(() => {
      loadChartData(props.thesisId);
    });

    watch(
      () => props.thesisId,
      (value) => {
        loadChartData(value);
      }
    );

    /*const lineChart1 = {
      type: "line",
      // locally registered and available for this chart
      //plugins: [dataLabels],
      data: {
        labels: [
          "Title",
          "Prel. Abstract",
          "Introduction",
          "Aim and research question(s)",
          "Summary of state of art",
          "Method(s) and sample",
          "Results",
          "Discussion",
          "Conclusions",
          "Future work",
          "Abstract",
          "References",
          "Appendices",
        ],
        datasets: [
          {
            label: "Planned",
            data: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
            fill: false,
            borderColor: "green",
            backgroundColor: "white",
          },
          {
            label: "Progress",
            data: [10, 15, 22, 27, 32, 37, 45, 52],
            fill: false,
            borderColor: "#00D8FF",
            tension: 0.5,
            backgroundColor: "white",
          },
        ],
      },
      options: {
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "y",
            },
          },
          /*datalabels: {
            backgroundColor: function(context) {
              return context.dataset.backgroundColor;
            },
            borderRadius: 4,
            color: "white",
            font: {
              weight: "bold",
            },
            formatter: Math.round,
            padding: 6,
          },*/
    /*},
      },
    };*/

    return {
      lineChart,
      chartRef,
    };
  },
};
</script>
