<template>
  <div :class="repplyCssClass1" class="">
    <div class="forum--image p-2">
      <img
        :src="comment.image"
        alt="mdo"
        width="40"
        height="40"
        class="rounded-circle mt-10 shadow-sm"
      />
    </div>
    <div :class="repplyCssClass">
      <div class="response-info">
        <span
          ><p class="response-by">{{ comment.userName }}</p></span
        >
        <span
          ><p class="response-time">{{ comment.date }}</p></span
        >
      </div>
      <p>
        {{ comment.message }}
      </p>
    </div>
    <div v-if="shwoDelete" class="response-delete">
      <i
        class="fas fa-trash-alt"
        @click="deleteForumComment"
        style="cursor: pointer;"
      ></i>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { backendURL } from "../../app-constants";

export default {
  props: {
    isStudent: {
      type: Boolean,
      default: true,
    },
    comment: {
      type: Object,
    },
  },
  emits: ["refresh-froum"],
  setup(props, { emit }) {
    const store = useStore();

    const repplyCssClass = computed(() => {
      let res = "";
      res = !props.comment.reply ? "forum--message" : "forum--reply";
      return res;
    });

    const repplyCssClass1 = computed(() => {
      let res = "";
      res = !props.comment.reply
        ? "forum d-flex flex-row bd-highlight mb-3"
        : "d-flex flex-row-reverse bd-highlight";
      return res;
    });

    const shwoDelete = computed(() => {
      const userId = store.getters["user/loggedUserId"];
      return userId == props.comment.userId;
    });

    const deleteForumComment = async () => {
      if (confirm("Do you sure?")) {
        const token = store.getters["user/token"];
        const response = await fetch(
          backendURL + "/api/deleteForum/" + props.comment.id,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            emit("refresh-froum");
          }
        }
      }
    };

    return {
      repplyCssClass,
      repplyCssClass1,
      shwoDelete,
      deleteForumComment,
    };
  },
};
</script>
