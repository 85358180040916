<template>
  <div>
    <div class="container">
      <div class="my-progress-boxes">
      <div class="row text-start">
        <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
          <form autocomplete="off" class="row" @submit.prevent="saveQuestions">
            <div class="col-12">
              <label for="quest" class="form-label"
                >Question <span class="text-muted"></span
              ></label>
              <input
                type="text"
                id="quest"
                name="quest"
                placeholder="Question"
                class="form-control"
                v-model="question"
              />
            </div>
            <div class="col-sm-12 mb-2">
              <label for="ans" class="form-label">Answers</label>
              <textarea
                type="text"
                id="ans"
                name="ans"
                placeholder="Answers"
                class="form-control"
                rows="12"
                v-model="answer"
              />
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-left mb-2">
              <button class="btn btn-outline-success me-md-2" type="submit">
                SAVE <i class="fas fa-save"></i>
              </button>
              <button class="btn btn-outline-danger me-md-2" type="button">
                CANCEL <i class="fas fa-window-close"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
 
<script>
import { backendURL } from "../../../app-constants";
export default {
  data() {
    return {
      question: "",
      answer: "",
      allAnswer: [],
    };
  },
  methods: {
    saveQuestions: function () {
      this.token = this.$store.getters["user/token"];

      var object = { question: this.question, answer: this.answer };

      fetch(backendURL + "/api/auth/faq/saveQuestion", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token           
        },
        body: JSON.stringify(object),
      })
        .then((res) => res.json())
        .then((res) => {
          this.allAnswer = res.data;
        });
    },
  },
};
</script>
