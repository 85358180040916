<template>
  <form @submit.prevent="updateSection">
    <div class="row g-3 mt-3 mx-3">
      <div
        class="col-12"
        :class="{ errors: !sectionData.sectionTitle.isValid }"
      >
        <label for="sectionTitle" class="form-label"
          >Title of the section <span class="text-muted"></span
        ></label>
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            id="sectionTitle"
            name="sectionTitle"
            v-model.trim="sectionData.sectionTitle.val"
            @blur="clearValidity('sectionTitle')"
            ref="title"
          />
          <label for="floatingTextarea">Add your new section title here</label>
          <div class="error-message">
            Please enter Title of the section.
          </div>
        </div>
      </div>
    </div>

    <div class="row g-3 mx-3 mt-3">
      <div class="col-sm-6" :class="{ errors: !sectionData.startDate.isValid }">
        <label for="startDate" class="form-label">Starting date</label>
        <datepicker
          class="form-control"
          id="startDate"
          name="startDate"
          v-model="sectionData.startDate.val"
          @blur="clearValidity('startDate')"
        />
        <div class="error-message">
          Please select start date.
        </div>
      </div>

      <div class="col-sm-6" :class="{ errors: !sectionData.endDate.isValid }">
        <label for="endDate" class="form-label"
          >When do you plan to finish? <span class="text-muted"></span
        ></label>

        <datepicker
          class="form-control"
          id="endDate"
          name="endDate"
          v-model="sectionData.endDate.val"
          @blur="clearValidity('endDate')"
        />
        <div class="error-message">
          Please select end date.
        </div>
      </div>
    </div>

    <div class="d-grid gap-2 d-md-flex justify-content-md-start mt-3 mx-4">
      <button class="btn-task--success me-md-2">
        SAVE <i class="fas fa-save"></i>
      </button>
      <button
        class="btn-task--danger me-md-2"
        type="button"
        @click="cancelButton"
      >
        CANCEL <i class="fas fa-window-close"></i>
      </button>

      <button
        class="btn-task--danger me-md-2"
        type="button"
        @click="deleteSectionButton"
      >
        DELETE <i class="fas fa-trash-alt"></i>
      </button>
    </div>

    <p v-if="!formIsValid" class="comman-error">
      {{ invalidmessage }}
    </p>
  </form>
  <base-spinner v-if="isLoading"></base-spinner>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import Datepicker from "vue3-datepicker";
import { useStore } from "vuex";
import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";

export default {
  components: {
    Datepicker,
    BaseSpinner,
  },
  emits: ["hide-componant"],
  props: ["sectionId", "listIndex", "title1", "startDate1", "endDate1"],
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref(false);

    const sectionData = reactive({
      sectionTitle: { val: props.sectionId ? props.title1 : "", isValid: true },
      startDate: {
        val: props.sectionId ? new Date(props.startDate1) : new Date(),
        isValid: true,
      },
      endDate: {
        val: props.sectionId ? new Date(props.endDate1) : null,
        isValid: true,
      },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);

    const title = ref("");

    function clearValidity(input) {
      sectionData[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;

      if (sectionData.sectionTitle.val == "") {
        sectionData.sectionTitle.isValid = false;
        formIsValid.value = false;
      }

      if (!sectionData.startDate.val || sectionData.startDate.val == "") {
        sectionData.startDate.isValid = false;
        formIsValid.value = false;
      }

      if (!sectionData.endDate.val || sectionData.endDate.val == "") {
        sectionData.endDate.isValid = false;
        formIsValid.value = false;
      }
    }

    function updateSection() {
      validateFrom();
      if (!formIsValid.value) {
        invalidmessage.value = "Required  fields missing";
        return;
      }
      addNewSection();
    }

    function cancelButton() {
      emit("hide-componant");
    }

    onMounted(() => {
      title.value.focus();
    });

    const addNewSection = async () => {
      try {
        //isLoading.value = true;
        const token = store.getters["user/token"];
        const userId = store.getters["user/loggedUserId"];

        const dataObj = {
          id: props.sectionId,
          studentId: userId,
          listIndex: props.listIndex,
          startDate: sectionData.startDate.val,
          endDate: sectionData.endDate.val,
          title: sectionData.sectionTitle.val,
        };

        const response = await fetch(backendURL + "/api/addThesisSection/", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...dataObj,
          }),
        });
        const resposeData = await response.json();
        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error saving to my labrary"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          emit("hide-componant");
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    const deleteSectionButton = async () => {
      if (confirm("Do you really want to delete?")) {
        try {
          //isLoading.value = true;
          const token = store.getters["user/token"];

          const response = await fetch(
            backendURL + "/api/deleteSection/" + props.sectionId,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
              },
            }
          );
          const resposeData = await response.json();
          if (!response.ok) {
            const error = new Error(
              resposeData.message || "Error Deleting thesis section"
            );
            throw error;
          }

          if (resposeData.valid) {
            isLoading.value = false;
            emit("hide-componant");
          } else {
            const error = new Error(resposeData.message);
            throw error;
          }
        } catch (error) {
          isLoading.value = false;
          alert(error.message);
        }
      }
    };

    return {
      sectionData,
      formIsValid,
      invalidmessage,
      clearValidity,
      updateSection,
      cancelButton,
      title,
      isLoading,
      deleteSectionButton,
    };
  },
};
</script>
