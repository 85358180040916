import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import store from "./store/index.js";

import BaseDialog from "./components/UI/BaseDialog.vue";
import Notifications from "@kyvg/vue3-notification";

import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/scss/bootstrap.scss";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import gAuthPlugin from "vue3-google-oauth2";
import vueCountryRegionSelect from "vue3-country-region-select";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Notifications);

let gauthClientId =
  "52809810-pppvsd6ncm9nvfuj2p5kepdtrobenikh.apps.googleusercontent.com";
const gAuthOptions = {
  clientId: gauthClientId,
  scope: "email",
  prompt: "consent",
  //fetch_basic_profile: false,
};
app.use(gAuthPlugin, gAuthOptions);
app.component("base-dialog", BaseDialog);

app.mount("#app");
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(vueCountryRegionSelect);
