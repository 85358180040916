<template>
  <div class="container">
    <div class="alert alert-success" role="alert" v-show="jsonSuc">
      Succesfully saved!
    </div>
    <div class="alert alert-danger" role="alert" v-show="jsonErr">
      Something went wrong!
    </div>
    <div class="container">
      <div class="my-progress-boxes">
        <div class="row">
          <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
            <form autocomplete="off" class="row" @submit.prevent="resourceSave">
              <div class="row" :class="{ errors: rndValidation }">
                <rndView
                  :detailId="detailID"
                  @selected-rnd="selectedRNDFunc"
                ></rndView>
                <div class="error-message">
                  Please select a Research and development.
                </div>
              </div>
              <div class="row">
                <sdgView
                  :sdgSelected="sdgSelected"
                  @selected-sdg="selectedSDGFunc"
                ></sdgView>
              </div>

              <div
                class="col-12"
                :class="{ errors: !resourceData.title.isValid }"
              >
                <label for="title" class="form-label"
                  >Title <span class="text-muted"></span
                ></label>
                <input
                  type="hidden"
                  id="id"
                  name="id"
                  v-model.trim="resourceData.id.val"
                />
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Title"
                  class="form-control"
                  v-model.trim="resourceData.title.val"
                  @blur="clearValidity('title')"
                  maxlength="255"
                />
                <div class="error-message">
                  Please enter title of the resource.
                </div>
              </div>

              <div
                class="col-sm-12 mt-2"
                :class="{ errors: !resourceData.description.isValid }"
              >
                <label for="desc" class="form-label">Description</label>
                <textarea
                  type="text"
                  id="desc"
                  name="desc"
                  placeholder="Description"
                  class="form-control"
                  v-model.trim="resourceData.description.val"
                  @blur="clearValidity('desc')"
                  maxlength="1000"
                />
                <div class="error-message">
                  Please enter description of the resource.
                </div>
              </div>

              <div
                class="col-sm-12 mt-2"
                :class="{ errors: !resourceData.type.isValid }"
              >
                <label class="form-label" for="web"> Type </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="type"
                    id="web"
                    value="Web"
                    v-model.trim="resourceData.type.val"
                    @blur="clearValidity('type')"
                  />
                  <label class="form-check-label" for="web"> Web site </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="type"
                    id="book"
                    value="Book"
                    v-model.trim="resourceData.type.val"
                    @blur="clearValidity('type')"
                  />
                  <label class="form-check-label" for="book"> Book </label>
                </div>
                <div class="error-message">
                  Please enter type of the resource.
                </div>
              </div>

              <div
                class="col-sm-12 mt-2"
                :class="{ errors: !resourceData.url.isValid }"
              >
                <label for="url" class="form-label"
                  >URL(recommended for Wikidata URL, and DOI URL)
                </label>
                <input
                  type="text"
                  id="url"
                  name="url"
                  placeholder="URL"
                  class="form-control"
                  v-model.trim="resourceData.url.val"
                  @blur="clearValidity('url')"
                  maxlength="255"
                />
                <div class="error-message">
                  Please enter url of the resource.
                </div>
              </div>

              <div
                class="col-sm-12 mt-2"
                :class="{ errors: !resourceData.doi.isValid }"
              >
                <label for="doi" class="form-label"
                  >DOI URL(recommended for Wikidata URL, and DOI URL)
                </label>
                <input
                  type="text"
                  id="doi"
                  name="doi"
                  placeholder="DOI URL"
                  class="form-control"
                  v-model.trim="resourceData.doi.val"
                  @blur="clearValidity('doi')"
                  maxlength="255"
                />
                <div class="error-message">
                  Please enter DOI url of the resource.
                </div>
              </div>

              <div class="col-sm-12 mt-2">
                <label for="freeAccount" class="form-label">Account type</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="freeAccount"
                    id="free"
                    value="true"
                    v-model.trim="resourceData.freeAccount.val"
                    @blur="clearValidity('freeAccount')"
                  />
                  <label class="form-check-label" for="free"> Free </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="freeAccount"
                    id="paid"
                    value="false"
                    v-model.trim="resourceData.freeAccount.val"
                    @blur="clearValidity('freeAccount')"
                  />
                  <label class="form-check-label" for="paid"> Paid </label>
                </div>
              </div>

              <div
                class="col-sm-12 mt-2"
                :class="{ errors: !resourceData.thesisSection.isValid }"
              >
                <label for="thesisSectione" class="form-label"
                  >Thesis section</label
                >
                <div
                  class="form-check"
                  v-for="(thesis, index) in thesisSections"
                  :key="index"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="thesis"
                    id="thesis-Title"
                    :value="index"
                    v-model="resourceData.thesisSection.val"
                  />
                  <label class="form-check-label" for="thesis-Title">
                    {{ thesis }}
                  </label>
                </div>
                <div class="error-message">
                  Please click thesis section of the resource.
                </div>
              </div>

              <div
                class="col-sm-12 mt-2"
                :class="{ errors: !resourceData.publisher.isValid }"
              >
                <label for="publisher" class="form-label"
                  >Publisher/Jurnal</label
                >
                <input
                  type="text"
                  id="publisher"
                  name="publisher"
                  placeholder="Publisher/Jurnal"
                  class="form-control"
                  v-model.trim="resourceData.publisher.val"
                  @blur="clearValidity('publisher')"
                  maxlength="255"
                />
                <div class="error-message">
                  Please enter Publisher/Jurnal of the resource.
                </div>
              </div>

              <div
                class="col-sm-12 mt-2"
                :class="{ errors: !resourceData.author.isValid }"
              >
                <label for="author" class="form-label">Author</label>
                <input
                  type="text"
                  id="author"
                  name="author"
                  placeholder="Author"
                  class="form-control"
                  v-model.trim="resourceData.author.val"
                  @blur="clearValidity('author')"
                  maxlength="255"
                />
                <div class="error-message">
                  Please enter Author of the resource.
                </div>
              </div>

              <div class="col-sm-12 mt-2">
                <label for="openAccess" class="form-label">Access type</label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="openAccess"
                    id="open"
                    value="true"
                    v-model.trim="resourceData.openAccess.val"
                    @blur="clearValidity('openAccess')"
                  />
                  <label class="form-check-label" for="free"> Open </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="openAccess"
                    id="paid"
                    value="false"
                    v-model.trim="resourceData.openAccess.val"
                    @blur="clearValidity('openAccess')"
                  />
                  <label class="form-check-label" for="paid"> Paid </label>
                </div>
              </div>

              <div class="col-sm-12 mt-2">
                <vuejs-datepicker
                  class="form-control"
                  id="endDate"
                  name="endDate"
                  v-model="resourceData.date.val"
                  @blur="clearValidity('endDate')"
                />
              </div>

              <div
                class="d-grid gap-2 d-md-flex justify-content-md-left mt-2 mb-2"
              >
                <button class="btn-task--success me-md-2 mb-3" type="submit">
                  SAVE <i class="fas fa-save"></i>
                </button>
                <button class="btn-task--danger me-md-2 mb-3" type="button">
                  CANCEL <i class="fas fa-window-close"></i>
                </button>
              </div>

              <p v-if="!formIsValid" class="comman-error">
                {{ invalidmessage }}
              </p>
            </form>
            <!-- </div> -->
          </div>
        </div>
        <div class="row">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="resource in saveData" :key="resource.id">
                <td>{{ resource.title }}</td>
                <td>{{ resource.description }}</td>
                <td>{{ resource.type }}</td>
                <td>
                  <button @click="viewResource(resource.id)">View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import RNDview from "../../ResearchComponent/RnDTreeView";
import SDGView from "../../sdgComponent/AllSDG.vue";
import vuejsDatepicker from "vue3-datepicker";
import { backendURL } from "../../../app-constants";
import { useStore } from "vuex";
import BaseSpinner from "../../../components/UI/BaseSpinner.vue";
export default {
  components: {
    rndView: RNDview,
    sdgView: SDGView,
    "vuejs-datepicker": vuejsDatepicker,
    BaseSpinner,
  },
  setup() {
    const thesisSections = ref([]);
    const saveData = ref([]);
    const defaultDate = "2021-06-16";
    const DatePickerFormat = "yyyy";

    const store = useStore();
    const token = store.getters["user/token"];
    const detailID = ref();
    const sdgSelected = ref([]);
    const selectedSDG = ref([]);
    const selectedRND = ref();
    const jsonErr = ref(false);
    const jsonSuc = ref(false);

    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);
    const resourceData = reactive({
      id: { val: "0", isValid: true },
      title: { val: "", isValid: true },
      description: { val: "", isValid: true },
      url: { val: "", isValid: true },
      type: { val: "", isValid: true },
      freeAccount: { val: false, isValid: true },
      publisher: { val: "", isValid: true },
      author: { val: "", isValid: true },
      openAccess: { val: false, isValid: true },
      thesisSection: { val: null, isValid: true },
      sdgList: selectedSDG,
      date: { val: new Date(), isValid: true },
      doi: { val: "", isValid: true },
    });
    const rndValidation = ref(false);

    function validateFrom() {
      formIsValid.value = true;
      if (resourceData.title.val == "") {
        resourceData.title.isValid = false;
        formIsValid.value = false;
      } else {
        resourceData.title.isValid = true;
      }
      if (resourceData.description.val == "") {
        resourceData.description.isValid = false;
        formIsValid.value = false;
      } else {
        resourceData.description.isValid = true;
      }
      if (resourceData.url.val == "") {
        resourceData.url.isValid = false;
        formIsValid.value = false;
      } else {
        resourceData.url.isValid = true;
      }
      if (resourceData.publisher.val == "") {
        resourceData.publisher.isValid = false;
        formIsValid.value = false;
      } else {
        resourceData.publisher.isValid = true;
      }
      if (resourceData.author.val == "") {
        resourceData.author.isValid = false;
        formIsValid.value = false;
      } else {
        resourceData.author.isValid = true;
      }

      if (selectedRND.value === undefined) {
        //uncomment active rnd validation rndValidation.value = true; formIsValid.value = false;
        rndValidation.value = false;
       // formIsValid.value = true;
      } else {
        rndValidation.value = false;
      }
    }

    function resourceSave() {
      //isLoading.value = true;
      validateFrom();
      if (formIsValid.value) {
        let obj = {
          id: resourceData.id.val,
          title: resourceData.title.val,
          description: resourceData.description.val,
          type: resourceData.type.val,
          url: resourceData.url.val,
          freeAccount: resourceData.freeAccount.val,
          publisher: resourceData.publisher.val,
          author: resourceData.author.val,
          openAccess: resourceData.openAccess.val,
          thesisSection: resourceData.thesisSection.val,
          sdgList: selectedSDG.value,
          rndDetailField: selectedRND.value,
          date: resourceData.date.val,
          doi:resourceData.doi.val
        };

        fetch(backendURL + "/api/auth/resource/saveResource", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(obj),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              saveData.value = res.data;
              jsonErr.value = false;
              jsonSuc.value = true;
              resourceData.id.val = 0;
              resourceData.title.val = "";
              resourceData.description.val = "";
              resourceData.type.val = "";
              resourceData.url.val = "";
              resourceData.freeAccount.val = false;
              resourceData.publisher.val = "";
              resourceData.author.val = "";
              resourceData.openAccess.val = false;
              resourceData.thesisSection.val = "";
              selectedSDG.value = [];
              selectedRND.value = "";
              resourceData.date.val = new Date();
              isLoading.value = false;
              resourceData.doi.val = "";
            } else {
              jsonErr.value = true;
              jsonSuc.value = false;
              isLoading.value = false;
            }
          });
      } else {
        invalidmessage.value = "Required  fields missing";
        isLoading.value = false;
      }
    }

    function clearValidity(input) {
      resourceData[input].isValid = true;
    }

    onMounted(() => {
      loadAllThesisSection();
    });

    const loadAllThesisSection = async () => {
      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/resource/listAllThesisSection", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          thesisSections.value = res.data;
        });

      /*Load all Resource */
      fetch(backendURL + "/api/auth/resource/listAllResourceAdmin", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          saveData.value = res.data;
        });
    };

    const selectedSDGFunc = (data) => {
      selectedSDG.value.length = 0;
      data.forEach((ids) => {
        selectedSDG.value.push({ id: ids });
      });
    };

    const selectedRNDFunc = (data) => {
      selectedRND.value = "";
      selectedRND.value = data;
    };

    const viewResource = (id) => {
      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/resource/getResourceById/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          selectedSDG.value = res.data.sdgList;
          detailID.value = res.data.rndDetailField;
          sdgSelected.value = res.data.sdgList;
          selectedRND.value = res.data.rndDetailField;
          resourceData.id.val = res.data.id;
          resourceData.title.val = res.data.title;
          resourceData.description.val = res.data.description;
          resourceData.type.val = res.data.type;
          resourceData.url.val = res.data.url;
          resourceData.freeAccount.val = res.data.freeAccount;
          resourceData.publisher.val = res.data.publisher;
          resourceData.author.val = res.data.author;
          resourceData.openAccess.val = res.data.openAccess;
          resourceData.thesisSection.val = res.data.thesisSection;
          resourceData.date.val = new Date(res.data.date);
          resourceData.doi.val = res.data.doi;
        });
    };

    return {
      saveData,
      formIsValid,
      resourceSave,
      resourceData,
      accType: ["Free", "Paid"],
      clearValidity,
      defaultDate,
      DatePickerFormat,
      detailID,
      thesisSections,
      sdgSelected,
      selectedSDGFunc,
      selectedRNDFunc,
      jsonSuc,
      jsonErr,
      viewResource,
      rndValidation,
      isLoading,
    };
  },
};
</script>
