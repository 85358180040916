<template>
  <thesis-supervisor-head-details
    :thesisId="thesisId"
  ></thesis-supervisor-head-details>
  <!-- MY PROGRESS SECTION -->
  <div class="container">
    <div class="row row-cols-sm-12">
      <div class="col-xxl-8 col-xl-8 order-xl-1 mb-4">
        <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4">
          <div class="my-progress-boxes">
            <div>
              <progress-chart :thesisId="thesisId"></progress-chart>
            </div>
          </div>
        </div>

        <!-- COMMUNICATION WITH SUPERVISOR Forum-->

        <common-forum :thesisId="thesisId" :isStudent="false"></common-forum>
      </div>

      <div class="col-xxl-4 col-xl-4 order-xl-1 mb-4">
        <div class="column">
          <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4">
            <shared-thesi-sections :thesisId="thesisId"></shared-thesi-sections>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- END MY PROGRESS SECTION -->
</template>
<script>
import CommonForum from "../../forum/CommonForum.vue";
import ProgressChart from "../../student/progress/ProgressChart.vue";
import ThesisSupervisorHeadDetails from "./ThesisSupervisorHeadDetails.vue";
import SharedThesiSections from "./SharedThesiSections.vue";
export default {
  props: ["thesisId"],
  components: {
    CommonForum,
    ProgressChart,
    ThesisSupervisorHeadDetails,
    SharedThesiSections,
  },
};
</script>
