export const SectionStatus = {
  PENDING: "PENDING",
  OVERDUE: "OVERDUE",
  COMPLETED: "COMPLETED",
};

export const UserRoles = {
  SYS_ADMIN: "SYS_ADMIN",
  STUDENT: "STUDENT",
  SUPERVISOR: "SUPERVISOR",
};

export const NotificationTypes = {
  REQUEST: "REQUEST",
  COMMANFORUM: "COMMANFORUM",
  SECTIONFORUM: "SECTIONFORUM",
};

export const NotificationTypesText = {
  REQUEST: "Request",
  COMMANFORUM: "Common forum",
  SECTIONFORUM: "Section forum",
};

export const backendURL = "https://dev.thesispro.se";
export const stripeAPIToken =
  "pk_test_51IiEYuGEQbAxRIheP6JPlGG89oehvReHhCpdyV5RnUrigCaQhBmBy7u92f6o67SgsPUiwb6mmi7SIYoNq83xQ9QU00JoPUB3uB";

export const isUserPaymentActivated = true;

/* Linked In keys */
export const linkedinClientId = "86xith6m3wfn40";
export const linkedinClientSecret = "3yqpjHQ2O350IixC";
export const appURL = "https://dev.thesispro.se";
