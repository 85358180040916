<template>
  <!-- THE NAVBAR SECTION -->
  <container class="intro-section">
    <nav class="navbar navbar-expand-lg navbar-light menu">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="../../assets/images/logo.svg" alt="Thesis Pro" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link active">HOME</router-link>
            </li>
          </ul>

          <router-link to="/signin" class="rounded-pill btn-rounded">
            SIGN IN
            <span><i class="fas fa-user"></i></span>
          </router-link>
        </div>
      </div>
    </nav>
  </container>
  <!-- THE NAVBAR SECTION END -->

  <!-- SIGNIN SECTION -->

  <section id="contact" class="get-started">
    <div class="container">
      <div class="row text-center">
        <h1 class="display-2--intro fw-bold text-uppercase text-light">SET PASSWORD</h1>
        <!-- <div class="heading-line"></div> -->
        <p class="lead mt-2 text-light">Set your password in thesis pro account</p>
      </div>

      <!-- SIGNIN FORM -->
      <div class="row">
       <div class="col-12 col-lg-6 p-3 d-none d-sm-block">
          <div class="login-info w-100">
            
            <img src="../../assets/images/illustrations/set_password.svg" class="d-block mx-lg-auto img-fluid" alt="Thesis Pro" width="500" height="400" loading="lazy">
          </div>
        </div>
        <div class="col-12 col-lg-6 bg-white shadow p-3">
          <div class="form w-100 pb-2">
            <h4 class="display-3--title mb-1 fw-blod">
              SET PASSWORD
            </h4>
            <div class="social-container text-center">
              <a href="#" class="social"><i class="fab fa-facebook-f"></i></a>
              <a href="#" class="social"><i class="fab fa-google"></i></a>
              <a href="#" class="social"><i class="fab fa-linkedin-in"></i></a>
              <span class="text-center mb-3"
                >or use your user account for login</span
              >
            </div>
            <form @submit.prevent="setPassword" class="row" autocomplete="off">
              <div
                class="col-12"
                :class="{ errors: !loginData.password.isValid }"
              >
                <label for="password" class="form-label">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  name="password"
                  v-model="loginData.password.val"
                  @blur="clearValidity('password')"
                  @keyup="passwordValidity('password')"
                />
                <div class="error-message">
                  Password is required
                </div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !loginData.confirmPassowrd.isValid }"
              >
                <label for="confirmPassowrd" class="form-label">
                  Confirm password <span class="text-muted"></span>
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="confirmPassowrd"
                  name="confirmPassowrd"
                  v-model.trim="loginData.confirmPassowrd.val"
                  @blur="clearValidity('confirmPassowrd')"
                />
                <div class="error-message">
                  ConfirmPassowrd is required
                </div>
              </div>

                  <div class="message">
                  <h5>Password must contain the following:</h5>
                  <p :class="lowermessage ? 'validPass' : 'invalidPass'">
                    <i class="far fa-check-circle"></i> A
                    <b>lowercase</b> letter
                  </p>
                  <p :class="uppermessage ? 'validPass' : 'invalidPass'">
                    <i class="far fa-check-circle"></i> A
                    <b>capital (uppercase)</b> letter
                  </p>
                  <p :class="numbermessage ? 'validPass' : 'invalidPass'">
                    <i class="far fa-check-circle"></i> A <b>number</b>
                  </p>
                  <p :class="lengthmessage ? 'validPass' : 'invalidPass'">
                    <i class="far fa-check-circle"></i> Minimum
                    <b>8 characters</b>
                  </p>
                </div>

              <div class="text-center d-grid mt-2">
                <button
                  class="btn btn-primary rounded-pill pt-3 pb-3"
                  type="submit"
                >
                  SUBMIT
                  <i class="fas fa-paper-plane"></i>
                </button>
              </div>

              <p v-if="!formIsValid" class="comman-error">
                {{ invalidmessage }}
              </p>
            </form>
          </div>
        </div>
        <!-- END SIGNIN FORM -->
      </div>
    </div>
  </section>
  <!--  END CONTACT US/LOGIN SECTION -->
  <the-footer></the-footer>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { backendURL } from "../../app-constants.js";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";

export default {
  components: { BaseSpinner, TheFooter },
  props: ["token"],
  setup(props) {
    const router = useRouter();

    const loginData = reactive({
      confirmPassowrd: { val: "", isValid: true },
      password: { val: "", isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);

    const lowermessage = ref(false);
    const uppermessage = ref(false);
    const numbermessage = ref(false);
    const lengthmessage = ref(false);

    function clearValidity(input) {
      loginData[input].isValid = true;
    }

       function passwordValidity(input) {
      loginData[input].isValid = true;

      var lowerCaseLetters = /[a-z]/g;
      if (loginData.password.val.match(lowerCaseLetters)) {
        lowermessage.value = true;
      } else {
        lowermessage.value = false;
        formIsValid.value = false;
      }

      var upperCaseLetters = /[A-Z]/g;
      if (loginData.password.val.match(upperCaseLetters)) {
        uppermessage.value = true;
      } else {
        uppermessage.value = false;
        formIsValid.value = false;
      }

      var numbers = /[0-9]/g;
      if (loginData.password.val.match(numbers)) {
        numbermessage.value = true;
      } else {
        numbermessage.value = false;
        formIsValid.value = false;
      }

      if (loginData.password.val.length >= 8) {
        lengthmessage.value = true;
      } else {
        lengthmessage.value = false;
        formIsValid.value = false;
      }
    }

    function validateFrom() {
      formIsValid.value = true;
      if (loginData.confirmPassowrd.val == "") {
        loginData.confirmPassowrd.isValid = false;
        formIsValid.value = false;
      }
      if (loginData.password.val == "") {
        loginData.password.isValid = false;
        formIsValid.value = false;
      }
    }

    async function setPassword() {
      //isLoading.value = true;
      validateFrom();
      if (!formIsValid.value) {
        invalidmessage.value = "Required  fields missing";
        return;
      }
      if (loginData.password.val != loginData.confirmPassowrd.val) {
        isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = "Passwords missmatch";
        return;
      }
      try {
        const response = await fetch(backendURL + "/api/auth/setPassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: props.token,
            password: loginData.password.val,
          }),
        });

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error set user password"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          router.push("/signin");
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = error.message;
      }
    }

    return {
      loginData,
      formIsValid,
      invalidmessage,
      clearValidity,
      setPassword,
      isLoading,
      lowermessage,
      uppermessage,
      numbermessage,
      lengthmessage,
      passwordValidity,
    };
  },
};
</script>
