<template>
  <!-- USERS-->
  <div class="alert alert-success" role="alert" v-show="jsonSuc">
    Succesfully blocked!
  </div>
  <div class="alert alert-danger" role="alert" v-show="jsonErr">
    Something went wrong!
  </div>
  <div>
    <div class="container">
      <div class="my-progress-boxes">
      <div class="row text-start">
        <div class="sysadmin mb-1">
          <h4>USERS IN THE SYSTEM</h4> 
        </div>
      </div>

      <div class="row text-start">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <!-- ACCORDION ITEM 1 -->
          <div class="accordion-item--sysadmin mt-3 mb-3">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <h4>1. PRESENT STUDNETs IN THE SYSTEM</h4>
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>University</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in userData" :key="user.id">
                      <td>{{ user.fullName }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.country }}</td>
                      <td>{{ user.university }}</td>
                      <td>
                        <button @click="blockUser(user.id)">Block</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- ACCORDION ITEM 2 -->
          <div class="accordion-item--sysadmin mt-3 mb-3">
            <h2 class="accordion-header" id="flush-headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                <h4>2.  PRESENT SUPERVISORs IN THE SYSTEM</h4>
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>Univercity</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in supervisorData" :key="user.id">
                      <td>{{ user.fullName }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.country }}</td>
                      <td>{{ user.university }}</td>
                      <td>
                        <button @click="blockUser(user.id)">Block</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- ACCORDION ITEM 2 -->
          <div class="accordion-item--sysadmin mt-3 mb-3">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                <h4>3. BLOCKED USERS</h4>
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>Univercity</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in blockUserData" :key="user.id">
                      <td>{{ user.fullName }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.country }}</td>
                      <td>{{ user.university }}</td>
                      <td>
                        <button @click="unblockUser(user.id)">Unblock</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <!-- END -->
</template>
<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { backendURL } from "../../../app-constants";
export default {
  setup() {
    const store = useStore();
    const userData = ref([]);
    const blockUserData = ref([]);
    const jsonErr = ref(false);
    const jsonSuc = ref(false);
    const supervisorData = ref([]);

    onMounted(() => {
      loadUsers();
      loadBlockUsers();
      loadSUpervisor();
    });

    const loadUsers = async () => {
      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/loadAllUser", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          userData.value = res.data;
        });
    };

      const loadSUpervisor = async () => {
      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/loadAllSupervisor", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          supervisorData.value = res.data;
        });
    };

    const loadBlockUsers = async () => {
      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/loadAllBlockUser", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          blockUserData.value = res.data;
        });
    };

    const blockUser = (id) => {
      const token = store.getters["user/token"];
      let obj = {
        id: id,
      };
      fetch(backendURL + "/api/auth/blockUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            userData.value = res.data;
            jsonSuc.value = true;
            loadBlockUsers();
          } else {
            jsonErr.value = true;
          }
        });
    };

    const unblockUser = (id) => {
      const token = store.getters["user/token"];
      let obj = {
        id: id,
      };
      fetch(backendURL + "/api/auth/unblockUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            blockUserData.value = res.data;
            jsonSuc.value = true;
            loadUsers();
          } else {
            jsonErr.value = true;
          }
        });
    };

    return {
      userData,
      blockUserData,
      blockUser,
      unblockUser,
      supervisorData
    };
  },
};
</script>
