<template>
  <the-header></the-header>
  <!-- MY PROGRESS SECTION --> 
  <section id="notifications" class="user-credentials">
    <div class="container">
      <div class="help-guide">
        <h4 class="main-heading text-start">Welcome to your profile</h4> 
      </div>
    </div>
    <div class="container"> 
      <div class="alert alert-success" role="alert" v-show="jsonSuc">
        Succesfully saved!
      </div>
      <div class="alert alert-danger" role="alert" v-show="jsonErr">
        Something went wrong!
      </div>
      <div class="row">
        <div class="col-lg-8 col-md-6 col-sm-12 col-xs-12 mb-3">
          <div class="my-progress-boxes">
          <div class="col-lg -12 col-md-6 col-sm-12 col-xs-12"></div>
          <!-- <div class="my-profile-boxes"> -->
            <h5 class="writing-content--title">INFORMATION</h5>
            <form class="needs-validation" novalidate>
              <div class="row g-3">
                <div
                  class="col-sm-6"
                  :class="{ errors: !regData.firstName.isValid }"
                >
                  <label for="firstName" class="form-label">First name</label>
                  <input
                    type="hidden"
                    id="id"
                    class="form-control"
                    name="id"
                    v-model.trim="regData.id.val"
                  />
                  <input
                    type="text"
                    id="firstName"
                    class="form-control"
                    name="firstName"
                    v-model.trim="regData.firstName.val"
                    @blur="clearValidity('firstName')"
                  />
                  <div class="error-message">First name is required.</div>
                </div>

                <div
                  class="col-sm-6"
                  :class="{ errors: !regData.lastName.isValid }"
                >
                  <label for="lastName" class="form-label">Last name</label>
                  <input
                    type="text"
                    id="lastName"
                    class="form-control"
                    name="lastName"
                    v-model.trim="regData.lastName.val"
                    @blur="clearValidity('lastName')"
                  />
                  <div class="error-message">Last name is required.</div>
                </div>

                <div
                  class="col-sm-12"
                  :class="{ errors: !regData.emailAddress.isValid }"
                >
                  <label for="email" class="form-label">Email</label>
                  <input
                    type="email"
                    id="emailAddress"
                    name="emailAddress"
                    v-model="regData.emailAddress.val"
                    class="form-control"
                    @blur="clearValidity('emailAddress')"
                    readonly
                  />
                  <div class="error-message">Email is required.</div>
                </div>

                <div
                  class="col-sm-12"
                  :class="{ errors: !regData.university.isValid }"
                >
                  <label for="university" class="form-label">University</label>
                  <input
                    type="text"
                    id="university"
                    class="form-control"
                    name="university"
                    v-model="regData.university.val"
                    @blur="clearValidity('country')"
                  />
                  <div class="error-message">Valid last name is required.</div>
                </div>

                <div
                  class="col-sm-12"
                  :class="{ errors: !regData.country.isValid }"
                >
                  <label for="country" class="form-label">Country</label>
                   <country-select class="form-control" v-model="regData.country.val" :country="country1"></country-select>
   
                  <!-- <input
                    type="text"
                    id="country"
                    class="form-control"
                    name="country"
                    v-model="regData.country.val"
                    @blur="clearValidity('country')"
                  /> -->
                  <div class="error-message">Valid country is required.</div>
                </div>

                <div
                  class="col-sm-12"
                  :class="{ errors: !regData.thesisType.isValid }"
                >
                  <label class="form-label">Thesis Type</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    v-model="regData.thesisType.val"
                  >
                    <option value="bachelor">Bachelor</option>
                    <option value="master">Master</option>
                    <option value="phd">PhD</option>
                  </select>
                  <div class="error-message">
                    Valid thesis type is required.
                  </div>
                </div>

                <div class="d-grid gap-2 d-md-flex justify-content-md-left">
                  <button
                    class="btn-task--success me-md-2"
                    type="button"
                    @click="updateUser"
                  >
                    SAVE <i class="fas fa-save"></i>
                  </button>
                </div>
              </div>
            </form>
          <!-- </div> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-3">
          <div class="my-progress-boxes">
            <!-- <div class="my-emailprofile"> -->
              <h5 class="writing-content--title mb-5">PERSONAL</h5>

              <div class="col-auto">
                <profile-image></profile-image>

                <h6 class="my-profile-boxes--mail mt-1 text-center">
                  {{ regData.emailAddress.val }}
                </h6>
              </div>

              <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                <router-link
                  class="profile--danger me-md-2"
                  type="button"
                  to="/change-pasword"
                >
                  CHANGE PASSWORD <i class="fas fa-key"></i>
                </router-link>
              </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div> 


  </section> 
  <!-- END MY PROGRESS SECTION -->
  <the-footer></the-footer>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import TheHeader from "../../components/layouts/TheHeader.vue";
import ProfileImage from "../../components/user/ProfileImage.vue";
import { backendURL } from "../../app-constants";
import TheFooter from "../../components/layouts/TheFooter.vue";


export default {
  components: {
    TheHeader,
    ProfileImage,
    TheFooter    
  },
  setup() {
    const store = useStore();
    const jsonErr = ref(false);
    const jsonSuc = ref(false);

    const country1= ref('');
     

    const userId = store.getters["user/loggedUserId"];

    const regData = reactive({
      id: { val: "", isValid: true },
      firstName: { val: "", isValid: true },
      lastName: { val: "", isValid: true },
      emailAddress: { val: "", isValid: true },
      country: { val: "", isValid: true },
      university: { val: "", isValid: true },
      thesisType: { val: "", isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);

    function clearValidity(input) {
      regData[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;

      if (regData.firstName.val == "") {
        regData.firstName.isValid = false;
        formIsValid.value = false;
      }
      if (regData.lastName.val == "") {
        regData.lastName.isValid = false;
        formIsValid.value = false;
      }
      if (regData.emailAddress.val == "") {
        regData.emailAddress.isValid = false;
        formIsValid.value = false;
      }
    }

    function updateUser() {
      validateFrom();
      if (!formIsValid.value) {
        invalidmessage.value = "Required  fields missing";
        return;
      }

      let obj = {
        id: regData.id.val,
        firstName: regData.firstName.val,
        lastName: regData.lastName.val,
        email: regData.emailAddress.val,
        country: regData.country.val,
        university: regData.university.val,
        thesisType: regData.thesisType.val,
      };

      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/updateUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            jsonSuc.value = true;
          } else {
            jsonErr.value = true;
          }
        });
    }

    onMounted(() => {
      loadUserData();
    });

    // Get UserDetal from Id
    function loadUserData() {
      /*Load USER DATA   */
      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/getUserById/" + userId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          regData.id.val = res.data.id;
          regData.firstName.val = res.data.firstName;
          regData.lastName.val = res.data.lastName;
          regData.emailAddress.val = res.data.email;
          regData.country.val = res.data.country;
          regData.university.val = res.data.university;
          regData.thesisType.val = res.data.thesisType;
          country1.value = res.data.country;
        });
    }
 

    return {
      regData,
      formIsValid,
      invalidmessage,
      clearValidity,
      updateUser,
      loadUserData,
      jsonSuc,
      jsonErr, 
      country1,
      
    };
  },
};
</script>
