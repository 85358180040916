<template>
  <!-- THE NAVBAR SECTION -->
  <container class="intro-section">
    <nav class="navbar navbar-expand-lg navbar-light menu shadow fixed-top">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../../assets/images/logo.svg" alt="Thesis Pro" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link
                to="/user-profile"
                href="#"
                class="d-block link-dark text-decoration-none"
                aria-expanded="false"
              >
                <img
                  :src="profImage"
                  alt="pic"
                  width="40"
                  height="40"
                  class="rounded-circle mt-10 shadow-sm"
                />
              </router-link>
            </li>

            <li class="nav-item">
              <router-link :to="gotoUserContainer" class="nav-link">
                <i class="fas fa-home"></i> HOME
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/user-notifications" class="nav-link">
                <i class="fas fa-bell"></i>
                NOTIFICATIONS
                <span v-if="notificationcCount > 0" class="indicator">{{
                  notificationcCount
                }}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <a>
                <button
                  @click="userSingout"
                  type="button"
                  class="rounded-pill btn-log"
                >
                  LOG OUT
                  <span><i class="fas fa-sign-out-alt"></i></span></button
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </container>
  <ConfirmDialog></ConfirmDialog>
  <!-- THE NAVBAR SECTION END -->
</template>

<script>
import ConfirmDialog from "primevue/confirmdialog";
export default {
  components: {
    ConfirmDialog,
  },
  computed: {
    loggedUserRole() {
      return this.$store.getters["user/loggedUserRole"];
    },
    hasThesis() {
      return this.$store.getters["user/isThesisActivated"];
    },

    notificationcCount() {
      return this.$store.getters["user/notificationCount"];
    },
    gotoUserContainer() {
      let url = "/signin";
      if (this.loggedUserRole == "ADMIN") {
        url = "/admin";
      } else if (this.loggedUserRole == "STUDENT") {
        if (this.hasThesis) {
          url = "/student/student-progress";
        } else {
          url = "/student/thesis-start";
        }
      } else if (this.loggedUserRole == "SUPERVISOR") {
        url = "/supervisor/student-list";
      }
      return url;
    },
    profImage() {
      return this.$store.getters["user/usersImage"];
    },
  },
  methods: {
    userSingout() {
      this.$confirm.require({
        message: "Are you sure you want to logout?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$store.dispatch("user/userLoggedOut");
          this.$router.replace("/signin");
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
  },
};
</script>
