<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("user/autoLogIn");
  },
};
</script>
<style>
@import url("./assets/css/fontawesome.css");
</style>

<style lang="scss">
@import "./scss/style.scss";

.error-message {
  display: none;
  color: red;
}
.comman-error {
  color: red;
}

div.errors {
  input {
    border-color: red;
  }
  .error-message {
    display: block;
  }
}
</style>
