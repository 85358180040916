<template>
  <section class="thesis-writing-boxes">
    <div class="row thesis-writing no-gutters">
      <div class="col-xl-7 col-md-6 mb-1 px-2 px-md-3">
        <div class="row">
          <div class="col-md-1 text-right">
            <!-- Icon -->

            <div class="text-center" :class="sctStatusIconColourClass">
              <router-link :to="'/student/thesis-section/' + id" class="thesis"
                ><i class="fas" :class="sctStatusIconClass"></i>
              </router-link>
            </div>
          </div>
          <div class="col-md-11 text-right text-600">
            <div class="ml-3 media-body">
              <div>
                <h6 class="thesis-writing font-weight-semi-bold border-bottom">
                  <!-- <router-link
                    class="text-900"
                    :to="'/student/thesis-section/' + id"
                    >{{ title }} <i class="fas fa-marker mx-1"></i>
                  </router-link> -->
                  <label @click="loadEdit" style="cursor: pointer;">{{ title }} <i class="fas fa-marker mx-1"></i></label>
                </h6>
              </div>
              <div class="buttons-center-sm">
                <router-link
                    class="btn-sm me-md-2 btn-task--edit text-light"
                    :to="'/student/thesis-section/' + id"
                    >Write
                  </router-link>
                <label
                  :class="sctStatusButonColourClass"
                  class="btn-sm me-md-2"
                >
                  {{ sctStatus }}
                </label>
                
                <!-- <button
                  type="button"
                  class="btn-sm me-md-2 btn-task--edit"
                  @click="loadEdit"
                >
                  Edit
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-md-6 mb-3 px-2 px-md-3">
        <div class="row">
          <div class="col-lg-12">
            <div class="row g-2">
              <div class="col-md-6">
                <label class="date-label" for="floatingInputGrid"
                  >STARTING DATE</label
                >
                <input
                  type="text"
                  :value="startDate"
                  class="form-control"
                  readonly
                />
              </div>
              <div class="col-md-6">
                <label class="date-label" for="floatingInputGrid"
                  >FINISHING DATE</label
                >
                <input
                  type="text"
                  :value="endDate"
                  class="form-control"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <edit-section
      v-if="showAddNew"
      @hide-componant="hideEdit"
      :listIndex="0"
      :sectionId="id"
      :title1="title"
      :startDate1="startDate"
      :endDate1="endDate"
    ></edit-section>
  </section>
</template>

<script>
import { computed, ref } from "vue";

import { SectionStatus } from "../../../app-constants";
import EditSection from "./EditSection.vue";

export default {
  props: ["id", "title", "startDate", "endDate", "sectionStatus"],
  emits: ["reload-list"],
  components: {
    EditSection,
  },

  setup(props, { emit }) {
    const showAddNew = ref(false);

    const sctStatus = computed(() => {
      return props.sectionStatus;
    });

    const sctStatusIconColourClass = computed(() => {
      return props.sectionStatus == SectionStatus.COMPLETED
        ? "thesis-icons--success"
        : props.sectionStatus == SectionStatus.OVERDUE
        ? "thesis-icons--danger"
        : "thesis-icons--warning";
    });

    const sctStatusButonColourClass = computed(() => {
      return props.sectionStatus == SectionStatus.COMPLETED
        ? "btn-task--success"
        : props.sectionStatus == SectionStatus.OVERDUE
        ? "btn-task--danger"
        : "btn-task--warning";
    });

    const sctStatusIconClass = computed(() => {
      return props.sectionStatus == SectionStatus.COMPLETED
        ? "fa-check"
        : props.sectionStatus == SectionStatus.OVERDUE
        ? "fa-pencil-alt"
        : "fa-history";
    });

    const loadEdit = () => {
      showAddNew.value = true;
    };

    const hideEdit = () => {
      showAddNew.value = false;
      emit("reload-list");
    };

    return {
      sctStatus,
      sctStatusIconClass,
      sctStatusIconColourClass,
      sctStatusButonColourClass,
      loadEdit,
      hideEdit,
      showAddNew,
    };
  },
};
</script>
