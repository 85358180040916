<template>
  <section class="resource-boxes">
    <notifications position="top center"></notifications>
    <div class="row resources no-gutters">
      <div class="col-xl-12 col-md-12 mb-1 px-2 px-md-3">
        <div class="row">
          <div class="col-md-12 text-right text-600">
            <div class="ml-3 media-body">
              <div>
                <h6 class="resource font-weight-semi-bold border-bottom mb-3">
                  <a :href="resource.url" target="_blank">{{
                    resource.title
                  }}</a>
                </h6>
              </div>

              <div class="publiser-sdg">
                <p class="media-body--publiser">
                  {{ resource.author }} {{ resource.source }}
                </p>
              </div>

              <p>{{ resource.description }}</p>

              <div class="resourse-rights">
                <div class="resource-icons--success text-start">
                  <a @click="updateMyLibray" class="resources"
                    ><i :class="addIconCss"></i
                  ></a>
                </div>
                <p class="resource--add-text">{{ addText }}</p>

                <!-- start citation -->
                <div class="resource-icons--success text-start" v-if="citationVal">
                  <a
                    class="resources"
                    data-bs-toggle="collapse"
                    :href="'#collapseExample' + resource.id"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <i class="fas fa-quote-left"></i>
                  </a>
                </div>
                <p class="resource--add-text text-uppercase" v-if="citationVal">
                  {{ citationVal.replace(/[0-9]/g, '') }} cite
                </p>
                <!-- end citation -->
              </div>

              <!-- start citation -->
              <div class="resourse-rights">
                <div
                  :id="'collapseExample' + resource.id"
                  class="collapse row g-2"
                >
                  <div class="col-auto">
                    <input
                      type="text"
                      id="doiUrl"
                      name="doiUrl"
                      placeholder="Enter DOI URL"
                      class="form-control"
                      v-model.trim="doiUrl"
                    />
                  </div>
                  <div class="col-auto">
                    <button
                      class="btn-lg btn-task--edit text-light mb-1"
                      @click="citesLoad"
                    >
                      Create
                    </button>
                  </div>
                  <p v-html="citationTxt"></p>
                </div>
              </div>
              <!-- end citation -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref, computed, onMounted } from "vue";

import { useStore } from "vuex";
import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";
import { notify } from "@kyvg/vue3-notification";
export default {
  components: { BaseSpinner },
  props: ["resource", "isInbibray"],
  emits: ["refresh-list"],

  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref(false);
    const citationTxt = ref("");
    const citationVal = ref("");
    const doiUrl = ref("");

    const addIconCss = computed(() => {
      return props.isInbibray ? "fas fa-minus" : "fas fa-plus";
    });

    const addText = computed(() => {
      return props.isInbibray ? "Remove from my library" : "Add to my library";
    });

    const updateMyLibray = async () => {
      if (props.isInbibray) {
        removeMyLibray();
      } else {
        addToMyLibray();
      }
    };

    const removeMyLibray = async () => {
      try {
        //isLoading.value = true;
        const token = store.getters["user/token"];

        const response = await fetch(
          backendURL + "/api/removeFromWebLibrary/" + props.resource.id,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error remove from my labrary"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          emit("refresh-list");
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    const addToMyLibray = async () => {
      try {
        //isLoading.value = true;
        const token = store.getters["user/token"];
        const userId = store.getters["user/loggedUserId"];

        const dataObj = {
          ...props.resource,
          studentId: userId,
        };

        const response = await fetch(backendURL + "/api/addToWebLibrary/", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...dataObj,
          }),
        });
        const resposeData = await response.json();
        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error saving to my labrary"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          notify({
            type: "success",
            title: "SUCCESS",
            text: "Done..",
          });
          // alert("Done..");
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    onMounted(() => {
      loadDetails();
    });

    const loadDetails = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getUserThesisDetails/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          citationVal.value = resposeData.data.citation;
        }
      }
    };
    const citesLoad = async () => {
      if (citationVal.value !== null) {
        if (doiUrl.value != "") {
          try {
            const Cite = require("citation-js");
            let example = await new Cite.async(doiUrl.value);

            let output = example.format("bibliography", {
              format: "html",
              template: citationVal.value,
              lang: "en-US",
            });
            citationTxt.value = output;
          } catch (error) {
            notify({
              type: "error",
              title: "ERROR",
              text: "Invalid DOI url",
            });
          }
        } else {
          notify({
            type: "warn",
            title: "Note",
            text: "Enter valid DOI",
          });
        }
      } else {
        notify({
          type: "warn",
          title: "Citation",
          text: "Select citation type in detail thesis page",
        });
      }
    };

    return {
      addIconCss,
      addText,
      updateMyLibray,
      citesLoad,
      citationTxt,
      doiUrl,
      citationVal,
      isLoading,
    };
  },
};
</script>
