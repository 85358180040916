<template>
  <!-- USERS-->
  <div>
    <div class="container">
      <div class="row text-start">
        <div class="sysadmin mb-1">
          <h5 class="user-credentials-boxes--title mb-1">
            Select your specific Research and Development area
            <sup><i class="fa fa-asterisk"></i></sup>
          </h5> 
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-xl-4 col-md-6 mb-1">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col" v-if="boardCategories !== 0">
                  <h3 class="text-uppercase text-muted mb-2">Broad field</h3>
                  <div
                    class="form-check form-switch"
                    v-for="c in boardCategories"
                    :key="c.id"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="c.id"
                      name="c.id"
                      :value="c.id"
                      v-model="checkedBoardCategories"
                      @change="checkBoardCat($event, c.id)"
                      :checked="
                        Object.keys(checkNarrowObj).length !== 0
                          ? c.id === checkNarrowObj.broadField.id
                          : false
                      "
                    />
                    <label class="form-check-label" for="c.id">
                      <!-- <strong>{{ c.id }}</strong> - -->
                      {{ c.broad_title }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-1">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col" v-if="narrowCategories !== 0">
                  <h3 class="text-uppercase text-muted mb-2">NARROW field</h3>
                  <div
                    class="form-check form-switch"
                    v-for="n in narrowCategories"
                    :key="n.id"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="n.id"
                      name="n.id"
                      :value="n.id"
                      :options="n.id"
                      v-model="checkedNarrowCategories"
                      @change="checkNarrowCat($event, n.id)"
                      :checked="n.id === checkNarrowObj.id"
                    />
                    <label class="form-check-label" for="n.id">
                      {{ n.narrow_title }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 mb-1">
          <div class="card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col" v-if="detailCategories !== 0">
                  <h3 class="text-uppercase text-muted mb-2">DETAILED FIELD</h3>
                  <div
                    class="form-check form-switch"
                    v-for="d in detailCategories"
                    :key="d.id"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      id="d.id"
                      name="d.id"
                      :value="d.id"
                      :options="d.id"
                      v-model="checkedDetailCategories"
                      @change="getSelectedRND"
                      :checked="d.id === checkDetailId"
                    />
                    <label class="form-check-label" for="d.id">
                      {{ d.detail_title }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { backendURL } from "../../app-constants.js";
export default {
  props: ["detailId"],
  emits: ["selected-rnd"],
  data() {
    return {
      broadItem: false,
      checkedBoardCategories: [],
      checkedNarrowCategories: [],
      checkedDetailCategories: [],
      boardCategories: [],
      narrowCategories: [],
      detailCategories: [],
      token: "",
      checkDetailId: 0,
      checkNarrowObj: {},
    };
  },

  watch: {
    detailId: {
      handler(after) {
        if (after) {
          this.checkDetailId = after;
          //If detail id pass
          this.narrowCategories.length = 0;
          this.detailCategories.length = 0;

          fetch(backendURL + "/api/auth/listNarrowByDetailId/" + after, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.token,
            },
          })
            .then((res) => res.json())
            .then((res) => {
              this.narrowCategories = res.data;
            });

          fetch(backendURL + "/api/auth/listDetailByNarrowId/" + after, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.token,
            },
          })
            .then((res) => res.json())
            .then((res) => {
              this.detailCategories = res.data;
            });

          // Get checked Narrow id and detail id
          fetch(backendURL + "/api/auth/listCheckedNarrow/" + after, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.token,
            },
          })
            .then((res) => res.json())
            .then((res) => {
              this.checkNarrowObj = res.data;
            });
        }
      },
      immediate: true,
    },
  },

  methods: {
    checkBoardCat: function (event, broadId) {
      if (event.currentTarget.checked) {
        this.narrowCategories.length = 0;
        this.detailCategories.length = 0;

        fetch(backendURL + "/api/auth/listAllRndNarrowField/" + broadId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
        })
          .then((res) => res.json())
          .then((res) => {
            this.narrowCategories = res.data;
          });
      }
    },
    checkNarrowCat: function (event, narrowId) {
      if (event.currentTarget.checked) {
        this.detailCategories.length = 0;
        fetch(backendURL + "/api/auth/listAllRndDetailField/" + narrowId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
        })
          .then((res) => res.json())
          .then((res) => {
            this.detailCategories = res.data;
          });
      }
    },
    getSelectedRND: function () {
      this.$emit("selected-rnd", this.checkedDetailCategories);
    },
  },
  mounted() {
    this.boardCategories.length = 0;
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/listAllRndBroadField", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.boardCategories = res.data;
      });
  },
};
</script>
