<template>
  <!-- THE NAVBAR SECTION -->
  <!-- <container class="intro-section"> -->
  <nav class="navbar navbar-expand-lg navbar-light menu">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo.svg" alt="Thesis Pro" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link active">HOME</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- </container> -->
  <!-- THE NAVBAR SECTION END -->

  <div class="container col-xxl-12 px-4">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-1">
      <div class="col-lg-6 payment">
        <span class="payment--cancel"><i class="fas fa-times-circle"></i></span>
        <h1 class="payment--cancel-text">
          Cancel Payment!
        </h1>
        <p class="lead">
          PAYMENT HAS BEEN CANCELLED AND WILL NOT BE CHARGED.
        </p>
        <span class="payment-button">
          <button class="button-payment--cancel" @click="done">
            OK
          </button>
        </span>

        <div class="d-grid gap-2 d-md-flex justify-content-md-start"></div>
      </div>
      <div class="col-10 col-sm-8 col-lg-6 mb-4">
        <img
          src="../../assets/images/cancel.svg"
          class="d-block mx-lg-auto img-fluid"
          alt="Bootstrap Themes"
          width="400"
          height="400"
          loading="lazy"
        />
      </div>
    </div>
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import { backendURL } from "../../app-constants";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";
export default {
  props: ["paymentId"],
  components: {
    BaseSpinner,
  },
  setup(props) {
    const router = useRouter();
    const isLoading = ref(false);

    const done = () => {
      router.push("/signin");
    };

    onMounted(() => {
      updatePayment();
    });

    const updatePayment = async () => {
      //isLoading.value = true;
      const response = await fetch(
        backendURL + "/api/auth/updatePaymentStatus/",
        {
          method: "POST",
          headers: {
            // Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentId: props.paymentId,
            paymentStatus: "CANCELED",
          }),
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          isLoading.value = false;
        }
      }
      isLoading.value = false;
    };

    return {
      done,
      isLoading,
    };
  },
};
</script>
