<template>
  <span>
    <a :href="sdg.sdgLink" target="_blank">
      <img
        :src="sdgImageUrl"
        alt="sdg"
        width="40"
        height="40"
        class="sdg-icon mt-10 shadow-sm"
      />
    </a>
  </span>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  props: ["sdg"],
  setup(props) { 
    const sdgImageUrl = computed(() => {
      return require("../../../assets/images/sdg/" + props.sdg.sdgIcon);
    });
    return {
      sdgImageUrl,
    };
  },
};
</script>
