<template>
   <nav class="navbar navbar-expand-lg navbar-light menu">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../assets/images/logo.svg" alt="Thesis Pro" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link active">HOME</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- </container> -->
  <!-- THE NAVBAR SECTION END -->

  <div class="container col-xxl-12 px-4">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-1">
    <!--  <div class="col-lg-6 payment">
        <span class="payment--correct"
          ><i class="far fa-check-circle"></i
        ></span>
        <h1 class="payment--correct-text">
         404 Page not found
        </h1>
        <p class="lead text-light">
          SORRY, THAT PAGE CAN'T BE FOUND
        </p> 
        <div class="d-grid gap-2 d-md-flex justify-content-md-start"></div>
      </div> -->
      <!-- <div class="col-10 col-sm-8 col-lg-6 mb-4"> -->
        <img
          src="../assets/images/404.svg"
          class="d-block mx-lg-auto"
          alt="Bootstrap Themes"
          width="500"
          height="500"
          loading="lazy"
        />
      <!-- </div> -->
    </div>
  </div>
</template>
