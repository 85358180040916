<template>
  <!--MENU SECTION -->

  <section id="dashbord" class="dashboard-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <nav-item
            to="/student/student-progress"
            title="MY PROGRESS"
            iconClass="fas fa-tasks"
            iconBg="bg-info"
          ></nav-item>
        </div>

        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <nav-item
            to="/student/thesis-writing"
            title="THESIS WRITING"
            iconClass="fas fa-pen"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <nav-item
            to="/student/student-notes"
            title="My notes"
            iconClass="fas fa-sticky-note"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <nav-item
            to="/student/calender"
            title="CALENDAR"
            iconClass="fas fa-calendar"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <nav-item
            to="/student/web-resources"
            title="ONLINE RESULTS"
            iconClass="fab fa-google"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <nav-item
            to="/student/resources"
            title="RESOURCES"
            iconClass="fas fa-database"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <nav-item
            to="/student/my-library"
            title="My library"
            iconClass="fas fa-book-reader"
            iconBg="bg-info"
          ></nav-item>
        </div>

        <div class="col-xl-3 col-md-6 col-6 mb-3">
          <nav-item
            to="/student/tips-guides"
            title="Tips & Guides"
            iconClass="fas fa-list-ul"
            iconBg="bg-info"
          ></nav-item>
        </div>
      </div>
    </div>   
  </section>

  <!-- MENU SECTION END -->
</template>

<script>
import NavItem from "../UI/NavItem.vue"; 

export default {
  components: {
    NavItem,
  },
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTime:null
    };
  },
  mounted() {
    this.events.forEach((event) => { 
      window.addEventListener(event, this.resetTimer);
    });

    this.setTimer();
  },
  methods: {
    setTimer: function () {
      this.warningTime = setTimeout(this.warningMessage,30 * 60000);
    },
    resetTimer: function () {
      clearTimeout(this.warningTime);
      this.setTimer();
    },
    warningMessage:function(){
      this.$store.dispatch("user/userLoggedOut");
      this.$router.replace("/signin");
    }
  } 
};
</script>
