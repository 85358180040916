<template>
  <div class="container">
    <div class="help-guide">
      <h4 class="main-heading text-start">Resources</h4>
      <div class="float-end"><Canvace :page="page" :iconId="1"></Canvace></div>
    </div>
  </div>
  <div class="container">
    <div class="col-12">
      <notifications position="top center"></notifications>

      <div class="row row-cols-sm-12">
        <!-- Filters -->
        <div class="col-xxl-3 col-xl-3 order-xl-1 mb-4">
          <div class="my-progress-boxes">
            <student-resource-filter
              @filter-resources="filterResources"
            ></student-resource-filter>
          </div>
        </div>
        <!-- Resource list -->

        <div class="col-xxl-9 col-xl-9 order-xl-1 mb-4">
          <div class="my-progress-boxes">
            <student-resource-list-block
              v-for="resource in resourceFiltersList"
              :key="resource.id"
              :resource="resource"
              :libraryList="libraryList"
              @update-my-library="updateMyLibray"
            ></student-resource-list-block>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import StudentResourceListBlock from "./StudentResourceListBlock.vue";
import StudentResourceFilter from "./StudentResourceFilter.vue";
import { backendURL } from "../../../app-constants";
import { notify } from "@kyvg/vue3-notification";
import Canvace from "../../canvasComponent/OffCanvace.vue";

export default {
  components: {
    StudentResourceListBlock,
    StudentResourceFilter,
    Canvace,
  },
  setup() {
    const store = useStore();
    const resourceList = ref([]);
    const resourceFiltersList = ref([]);

    const libraryList = ref([]);
    const myLibraryList = ref([]);
    const page = "resource";

    onMounted(() => {
      loadAllMyResource();
    });

    const loadAllMyResource = async () => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      fetch(
        backendURL + "/api/auth/resource/listAllResourceByUserId/" + userId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            myLibraryList.value = res.data;
            for (var j = 0; j < myLibraryList.value.length; j++) {
              libraryList.value.push(myLibraryList.value[j].resourceId);
            }
            loadAllResource();
          }
        });
    };

    const loadAllResource = async () => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      fetch(backendURL + "/api/auth/resource/listAllResource/" + userId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resourceList.value = res.data;
          resourceFiltersList.value = resourceList.value;
        });
    };

    const updateMyLibray = (Obj) => {
      if (Obj.myLibObj.isInbibray) {
        //ff
      } else {
        const token = store.getters["user/token"];
        const userId = store.getters["user/loggedUserId"];
        fetch(backendURL + "/api/auth/resource/saveMyResource", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            user: { id: userId },
            resource: { id: Obj.myLibObj.id },
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              libraryList.value.push(Obj.myLibObj.id);
              notify({
                type: "success",
                title: "SUCCESS",
                text: "Added to my libray!",
              });
            }
          });
      }
    };

    const filterResources = (filter) => {
      let result = resourceList.value;
      if (filter.year) {
        let thisYear = new Date().getFullYear();
        let lastYear = thisYear - 1;
        if (filter.year == 0) {
          result = result.filter(
            (res) => res.dateTime.split("-")[0] == thisYear
          );
        }
        if (filter.year == -1) {
          result = result.filter(
            (res) => res.dateTime.split("-")[0] == lastYear
          );
        }
        if (filter.year == -2) {
          result = result.filter(
            (res) => res.dateTime.split("-")[0] < lastYear
          );
        }
      } 
     
      if (filter.openAccess && filter.openAccess.length > 0) {
        result = result.filter(
          (res) => filter.openAccess.indexOf(res.publicAccess) != -1
        );
      }
      if (filter.sdg && filter.sdg.length > 0) {
        result = result.filter((res) =>
          checkSDGContains(res.sdgList, filter.sdg)
        );
      }
      resourceFiltersList.value = [];
      for (let i = 0; i < result.length; i++) {
        resourceFiltersList.value.push(result[i]);
      }
    };

    return {
      libraryList,
      updateMyLibray,
      filterResources,
      resourceFiltersList,
      loadAllResource,
      resourceList,
      page,
    };
  },
};
function checkSDGContains(resourceSDG, filterSDG) {
  for (let i = 0; i < resourceSDG.length; i++) {
    for (let j = 0; j < filterSDG.length; j++) {
      if (resourceSDG[i].id == filterSDG[j]) {
        return true;
      }
    }
  }
  return false;
}
</script>
