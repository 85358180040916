<template>
  <div class="container">
    <div class="help-guide">
      <h4 class="main-heading text-start">THESIS WRITING - {{sectionTitle}}</h4>
    </div>
  </div>

  <thesis-head-details></thesis-head-details>
  <!-- MY PROGRESS SECTION -->

  <div class="container">
    <div class="row row-cols-sm-12">
      <div class="col-xxl-8 col-xl-8 order-xl-1 mb-4">
        <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4">
          <write-section :sectionId="sectionId"></write-section>

          <forum-home :sectionId="sectionId" :isStudent="true"></forum-home>
        </div>
      </div>
      <div class="col-xxl-4 col-xl-4 order-xl-1 mb-4">
        <div class="column">
          <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4">
            <rnd-resource-list :sectionId="sectionId"></rnd-resource-list>
          </div>
          <!-- <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4"></div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- END MY PROGRESS SECTION -->
</template>

<script>
import WriteSection from "./WriteSection.vue";
import RndResourceList from "./RndResourceList.vue";
import ForumHome from "../../forum/ForumHome.vue";
import ThesisHeadDetails from "./ThesisHeadDetails.vue";
import { backendURL } from "../../../app-constants";
export default {
  components: {
    WriteSection,
    RndResourceList,
    ForumHome,
    ThesisHeadDetails,
  },
  props: ["sectionId"],
  data(){    
    return{
      sectionTitle:""
    }
  },
    mounted() {
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/getThesisSectionWritingDetails/"+this.sectionId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.sectionTitle = res.data.title;
      });
  }
};
</script>
