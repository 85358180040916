import { createRouter, createWebHistory } from "vue-router";

import store from "../store/index.js";

import Home from "../pages/Home.vue";
import SignIn from "../pages/user/SignIn.vue";
import Registor from "../pages/user/Register.vue";
import UserPayments from "../pages/user/UserPayments.vue";
import UserPaymentSuccess from "../pages/user/UserPaymentSuccess.vue";
import UserPaymentFalse from "../pages/user/UserPaymentFalse.vue";
import UserProfile from "../pages/user/UserProfile.vue";
import UserNotifications from "../pages/user/UserNotifications.vue";
import SetPassword from "../pages/user/SetPassword.vue";
import ChangePassword from "../pages/user/ChangePassword.vue";
import FogotPassword from "../pages/user/FogotPassword.vue";
import LinkedInSuccess from "../components/user/ext-log-ins/LinkedInSuccess.vue";

import AdminContainer from "../pages/admin/AdminContainer.vue";

import StudentContainer from "../pages/student/StudentContainer.vue";
import StudentProgress from "../components/student/StudentProgress.vue";
import StartThesis from "../components/student/StartThesis.vue";
import ThesisWritingStart from "../components/student/thesis-writing/ThesisWritingStart.vue";
import ThesisSectionDetails from "../components/student/thesis-writing/ThesisSectionDetails.vue";
import CompleteThesis from "../components/student/CompleteThesis.vue";
import StudentCalendar from "../components/student/student-calendar/StudentCalender.vue";
import StudentResources from "../components/student/resources/StudentResources.vue";
import MyLibrary from "../components/student/resources/MyLibrary.vue";
import WebResource from "../components/student/crawler/WebResource.vue";

import SupervisorContainer from "../pages/supervisor/SupervisorContainer.vue";
import NotFound from "../pages/NotFound.vue";

import ResearchAndDevelopment from "../components/admin/research/ResearchAndDevelopment";
import ResourceManagement from "../components/admin/resource/ResourceManagement.vue";

import UserManagement from "../components/admin/userManagement/UserManagementView";
import TermsAndCondition from "../components/admin/termsNcondition/TermsNCondition";
import FAQManagement from "../components/admin/faqManagement/FaqAddPage";
import ThesisSubmitStdList from "../components/supervisor/thesisApprovals/ThesisSubmitStudents";
import StudentSharedThesis from "../components/supervisor/thesisApprovals/StudentShareThesis";
import ThesisSectionForSupervisor from "../components/supervisor/thesisApprovals/ThesisSectionForSupervisor.vue";
import StudentRequest from "../components/supervisor/student-request/StudentRequestList";
import StudentTipsAndGuide from "../components/student/tipsAndGuid/TipsGuidView.vue";
import AdminTips from "../components/admin/tips/AdminTipsAndGuid.vue";
import InformationGuid from "../components/admin/informationGuid/AddInformationGuid.vue";

import RegistorSupervisor from "../pages/supervisor/RegistorSupervisor.vue";
import SupervisorRegistrationSuccess from "../pages/supervisor/SupervisorRegistrationSuccess.vue";
import UserPayment from "../components/admin/paymentManagement/UserPayment.vue";
import StudentNotes from "../components/student/notes/StudentNotes.vue";
import TermsCondition from "../pages/footer/Terms.vue";
import PrivacyTerms from "../components/admin/termsNcondition/PrivacyTerms.vue";
import PrivacyPolicy from "../pages/footer/PrivacyPolicy.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Home },
    { path: "/signin", component: SignIn },
    { path: "/registor", component: Registor },
    { path: "/payments/:id", component: UserPayments, props: true },
    {
      path: "/payments-success/:paymentId",
      component: UserPaymentSuccess,
      props: true,
    },
    {
      path: "/payments-fails/:paymentId",
      component: UserPaymentFalse,
      props: true,
    },
    { path: "/fogot-password", component: FogotPassword },
    {
      path: "/user-profile",
      component: UserProfile,
      meta: { requiresAuth: true },
    },
    {
      path: "/user-notifications",
      component: UserNotifications,
      meta: { requiresAuth: true },
    },
    { path: "/set-password/:token", component: SetPassword, props: true },
    {
      path: "/change-pasword",
      component: ChangePassword,
      meta: { requiresAuth: true },
    },
    {
      path: "/supervisor-invitation/:token",
      component: RegistorSupervisor,
      props: true,
    },
    {
      path: "/supervisor-registration-success",
      component: SupervisorRegistrationSuccess,
      props: true,
    },
    {
      path: "/linkedin-success",
      component: LinkedInSuccess,
      props: true,
    },

    {
      path: "/admin",
      component: AdminContainer,
      children: [
        { path: "contact", component: null, meta: { requiresAuth: true } },
        {
          path: "research-development",
          component: ResearchAndDevelopment,
          meta: { requiresAuth: true },
        },
        {
          path: "resource-mgt",
          component: ResourceManagement,
          meta: { requiresAuth: true },
        },
        {
          path: "user-mgt",
          component: UserManagement,
          meta: { requiresAuth: true },
        },
        {
          path: "terms-condition",
          component: TermsAndCondition,
          meta: { requiresAuth: true },
        },
        {
          path: "faq-mgt",
          component: FAQManagement,
          meta: { requiresAuth: true },
        },
        {
          path: "tips-mgt",
          component: AdminTips,
          meta: { requiresAuth: true },
        },
        {
          path: "information-guid",
          component: InformationGuid,
          meta: { requiresAuth: true },
        },
        {
          path: "user-payment",
          component: UserPayment,
          meta: { requiresAuth: true },
        },
        {
          path: "privacy-terms",
          component: PrivacyTerms,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: "/student",
      component: StudentContainer,
      children: [
        {
          path: "thesis-start",
          component: StartThesis,
          meta: { requiresAuth: true },
        },
        {
          path: "student-progress",
          component: StudentProgress,
          meta: { requiresAuth: true },
        },
        {
          path: "thesis-writing",
          component: ThesisWritingStart,
          meta: { requiresAuth: true },
        },
        {
          path: "thesis-complete",
          component: CompleteThesis,
          meta: { requiresAuth: true },
        },
        {
          path: "thesis-section/:sectionId",
          component: ThesisSectionDetails,
          props: true,
          meta: { requiresAuth: true },
        },
        {
          path: "calender",
          component: StudentCalendar,
          meta: { requiresAuth: true },
        },
        {
          path: "resources",
          component: StudentResources,
          meta: { requiresAuth: true },
        },
        {
          path: "my-library",
          component: MyLibrary,
          meta: { requiresAuth: true },
        },
        {
          path: "web-resources",
          component: WebResource,
          meta: { requiresAuth: true },
        },
        {
          path: "tips-guides",
          component: StudentTipsAndGuide,
          meta: { requiresAuth: true },
        },
        {
          path: "student-notes",
          component: StudentNotes,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: "/supervisor",
      component: SupervisorContainer,
      children: [
        { path: "contact", component: null, meta: { requiresAuth: true } },
        {
          path: "student-list",
          component: ThesisSubmitStdList,
          meta: { requiresAuth: true },
        },
        {
          path: "student-shared-thesis/:thesisId",
          component: StudentSharedThesis,
          meta: { requiresAuth: true },
          props: true,
        },
        {
          path: "view-student-section/:sectionId",
          component: ThesisSectionForSupervisor,
          meta: { requiresAuth: true },
          props: true,
        },
        {
          path: "student-requests",
          component: StudentRequest,
          meta: { requiresAuth: true },
        },
      ],
    },
    { path: "/:notFound(.*)", component: NotFound },
    {
      path: "/terms-condition",
      component: TermsCondition,
      props: true,
    },
    {
      path: "/privacy-policy",
      component: PrivacyPolicy,
      props: true,
    },
  ],
});

router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !store.getters["user/isAuthenticated"]) {
    next("/signin");
  } else {
    next();
  }
});

export default router;
