<template>
   
    <div class="my-progress-boxes shadow-none" style="box-shadow: none !important;">
      <div class="row row-cols-sm-12">
        <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4">
          <web-resource-list-block
            v-for="resource in resourceList"
            :key="resource.id"
            :resource="resource"
            :isInbibray="true"
            @refresh-list="searchInWeb"
          ></web-resource-list-block>
        </div>
      </div>
    </div>
 
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref,onMounted } from "vue";
import { useStore } from "vuex";

import WebResourceListBlock from "../crawler/WebResourceListBlock.vue";
import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";

export default {
  components: {
    WebResourceListBlock,
    BaseSpinner,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);

    const resourceList = ref([]);

    onMounted(() => {
      searchInWeb();
    });

    const searchInWeb = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getMyWebLibrary/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          resourceList.value = resposeData.data;
        }
      }
      isLoading.value = false;
    };

    return {
      isLoading,
      resourceList,
      searchInWeb,
    };
  },
};
</script>
