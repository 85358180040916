<template>
  <div class="container">
    <div class="alert alert-success" role="alert" v-show="jsonSuc">
      Succesfully {{successMsg}}!
    </div>
    <div class="alert alert-danger" role="alert" v-show="jsonErr">
      Something went wrong!
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
        <form autocomplete="off" class="row" @submit.prevent="saveMainTips">
          <div class="col-12" :class="{ errors: mainValidation }">
            <input type="hidden" id="mId" name="mId" v-model="mId" />
            <label for="quest" class="form-label"
              >Main<span class="text-muted"></span
            ></label>
            <input
              type="text"
              id="mTips"
              name="mTips"
              placeholder="Tips"
              class="form-control"
              v-model="mTips"
              maxlength="255"
            />
            <div class="error-message">Please enter main tips.</div>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-left mt-2">
            <button class="btn-task--success me-md-2 mb-3" type="submit">
              SAVE <i class="fas fa-save"></i>
            </button>
            <button class="btn-task--danger me-md-2 mb-3" type="button">
              CANCEL <i class="fas fa-window-close"></i>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <table class="table table-striped table-bordered mt-2">
        <thead>
          <tr>
            <th>Main</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="mainTips in allMaintips" :key="mainTips.id">
            <td>{{ mainTips.mainTips }}</td>
            <td><button @click="viewResource(mainTips.id)">View</button></td>
            <td>
              <button @click="publishResource(mainTips.id)">{{mainTips.enable ? 'Unpublish' : 'Publish'}}</button>
            </td>
            <td>
              <button @click="deleteResource(mainTips.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { backendURL } from "../../../app-constants";
export default {
  data() {
    return {
      mTips: "",
      mId: "",
      mainTips: "",
      allMaintips: [],
      jsonErr: false,
      jsonSuc: false,
      mainValidation: false,
      formIsValid: false,
      successMsg:"saved"
    };
  },
  methods: {
    validateFrom: function () {
      this.formIsValid = true;
      if (this.mTips == "") {
        this.mainValidation = true;
        this.formIsValid = false;
      }
    },

    saveMainTips: function () {
      this.validateFrom();
      if (this.formIsValid) {
        this.mainValidation = false;

        this.token = this.$store.getters["user/token"];
        var object = { id: this.mId, mainTips: this.mTips };
        fetch(backendURL + "/api/auth/tip/saveMainTips", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
          body: JSON.stringify(object),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              this.allMaintips = res.data;
              this.mId = "";
              this.mTips = "";
              this.jsonSuc = true;
              this.successMsg = "saved";
            } else {
              this.jsonErr = true;
              this.successMsg = "";
            }
          });
      }
    },
    viewResource: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/loadMainTipsById/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.mId = res.data.id;
          this.mTips = res.data.mainTips;
        });
    },
    publishResource: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/publishMainTips", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ id: id }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            this.allMaintips = res.data;
            this.jsonSuc = true; 
            this.successMsg = "saved";
          } else {
            this.jsonErr = true;
            this.successMsg = "";
          }
        });
    },
    deleteResource: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/deleteMainTips", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ id: id }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            this.allMaintips = res.data;
            this.jsonSuc = true;
            this.successMsg = "deleted";
          } else {
            this.jsonErr = true;
            this.successMsg = "";
          }
        });
    },
  },
  mounted() {
    let token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/tip/loadMainTips", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allMaintips = res.data;
      });
  },
};
</script>
