<template>
  <div class="container">
    <div class="alert alert-success" role="alert" v-show="jsonSuc">
      Succesfully saved!
    </div>
    <div class="alert alert-danger" role="alert" v-show="jsonErr">
      Something went wrong!
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
        <form autocomplete="off" class="row" @submit.prevent="saveNarrowTips">
          <div class="col-12" :class="{ errors: resourceValidation }">
            <label for="quest" class="form-label"
              >Resource <span class="text-muted"></span
            ></label>
            <input type="hidden" id="nId" name="nId" v-model="nId" />
            <input
              type="text"
              id="quest"
              name="quest"
              class="form-control"
              v-model="nTips"
              maxlength="250"
            />
            <div class="error-message">Please enter resource.</div>
          </div>
          <div class="col-12" :class="{ errors: catValidation }">
            <label class="form-label">Category</label>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="subTips"
            >
              <option v-for="m in allSubTips" :key="m.id" :value="m.id">
                {{ m.subTips }}
              </option>
            </select>
            <div class="error-message">Please select category.</div>
          </div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-left mt-2">
            <button class="btn-task--success me-md-2 mb-3" type="submit">
              SAVE <i class="fas fa-save"></i>
            </button>
            <button class="btn-task--danger me-md-2 mb-3" type="button">
              CANCEL <i class="fas fa-window-close"></i>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <table class="table table-striped table-bordered mt-2">
        <thead>
          <tr>
            <th>Resource</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="narrow in allNarrows" :key="narrow.id">
            <td>{{ narrow.narrowTips }}</td>
            <td><button @click="viewResource(narrow.id)">View</button></td>
            <td><button @click="deleteResource(narrow.id)">Delete</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { backendURL } from "../../../app-constants";
export default {
  props: ["loadSubTips", "loadAllNarrowTips"],
  data() {
    return {
      subTips: "",
      allSubTips: [],
      subTipsObj: {},
      nTips: "",
      jsonErr: false,
      jsonSuc: false,
      allNarrows: [],
      nId: "",
      formIsValid: false,
      resourceValidation: false,
      catValidation: false,
    };
  },
  watch: {
    loadSubTips: {
      handler(subTips) {
        this.allSubTips = subTips;
      },
    },
    loadAllNarrowTips: {
      handler(narrowTips) {
        this.allNarrows = narrowTips;
      },
    },
  },
  methods: {
    validateFrom: function () {
      this.formIsValid = true;
      if (this.nTips == "") {
        this.resourceValidation = true;
        this.formIsValid = false;
      }
      if (this.subTips == "") {
        this.catValidation = true;
        this.formIsValid = false;
      }
    },
    saveNarrowTips: function () {
      this.validateFrom();
      if (this.formIsValid) {
        this.resourceValidation = false;
        this.catValidation = false;

        this.token = this.$store.getters["user/token"];
        this.subTipsObj = { id: this.subTips };
        var object = {
          subTips: this.subTipsObj,
          narrowTips: this.nTips,
          id: this.nId,
        };
        fetch(backendURL + "/api/auth/tip/saveNarrowTips", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
          body: JSON.stringify(object),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              this.allNarrows = res.data;
              this.subTips = "";
              this.nTips = "";
              this.nId = "";
              this.jsonSuc = true;
            } else {
              this.jsonErr = true;
            }
          });
      }
    },
    viewResource: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/loadNarrowTipsById/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.nId = res.data.id;
          this.nTips = res.data.narrowTips;
          this.subTips = res.data.subTips.id;
        });
    },
    deleteResource: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/tip/deleteNarrowTipsById/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ id: id }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            this.allNarrows = res.data;
            this.jsonSuc = true;
          } else {
            this.jsonErr = true;
          }
        });
    },
  },
  mounted() {
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/tip/loadSubTips", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allSubTips = res.data;
      });

    fetch(backendURL + "/api/auth/tip/loadNarrowTips", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.allNarrows = res.data;
      });
  },
};
</script>
