<template>
  <div class="container">
    <div class="help-guide">
      <h4 class="main-heading text-start">ACTIVITY CALENDAR</h4>
      <div class="float-end">
        <Canvace :page="page" :iconId="1"></Canvace>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="my-progress-boxes">
      <h5 class="writing-content--title mb-3">CALENDAR SETTINGS</h5>
      <user-calendar></user-calendar>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import UserCalendar from "../../user-calender/UserCalendar.vue";
import { useStore } from "vuex";
import Canvace from "../../canvasComponent/OffCanvace.vue";
export default {
  components: {
    UserCalendar,
    Canvace,
  },
  setup() {
    const page = "calender";
    const store = useStore();
    const loggedUser = computed(() => {
      return store.getters["user/loggedUser"];
    });
    return {
      loggedUser,
      page,
    };
  },
};
</script>
