<template>
  <!-- <a href="#" class="social"><i class="fab fa-facebook-f"></i></a> -->

  <button @click="handleClickGoogleSignIn" class="google-button">
    <span class="social-login--google"
      ><img
        src="../../../assets/images/google-logo.svg"
        width="36"
        loading="lazy"
        alt="Thesis Pro"
    /></span>
  </button>
  <a :href="linkedInurl"
    ><span class="social-login--linkedin"
      ><img
        src="../../../assets/images/linkedin_logo.svg"
        width="36"
        loading="lazy"
        alt="Thesis Pro"/></span
  ></a>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import BaseSpinner from "../../UI/BaseSpinner.vue";
import {
  backendURL,
  isUserPaymentActivated,
  UserRoles,
  linkedinClientId,
  //linkedinClientSecret,
  appURL,
} from "../../../app-constants";

export default {
  props: ["role", "token"],
  components: {
    BaseSpinner,
  },

  methods: {
    async handleClickGoogleSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();

        if (!googleUser) {
          return null;
        }

        var profile = this.$gAuth.instance.currentUser.get().getBasicProfile();
        const signUpExternal = {
          email: profile.getEmail(),
          firstName: profile.getGivenName(),
          lastName: profile.getFamilyName(),
          imageUrl: profile.getImageUrl(),
          role: this.role,
          token: this.token,
        };
        this.signInUser(signUpExternal);
      } catch (error) {
        //on fail do something
        alert("Error " + (error.message ? error.message : ""));
        console.error(error);
        return null;
      }
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const isLoading = ref(false);

    const signInUser = async (paylod) => {
      //isLoading.value = true;
      try {
        await store.dispatch("user/loginExternal", paylod);
        const loggedUserRole = store.getters["user/loggedUserRole"];
        if (isUserPaymentActivated && loggedUserRole == UserRoles.STUDENT) {
          checkUserPayments(loggedUserRole);
        } else {
          isLoading.value = false;
          goToSystem(loggedUserRole);
        }
      } catch (error) {
        isLoading.value = false;
        alert("message - 02 " + error.message);
      }
    };

    const checkUserPayments = async (loggedUserRole) => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/validateUserPayment/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          if (resposeData.data) {
            isLoading.value = false;
            goToSystem(loggedUserRole);
          } else {
            isLoading.value = false;
            alert(resposeData.message);
            router.replace("/payments/" + userId);
          }
        } else {
          alert(resposeData.message);
        }
      }
      isLoading.value = false;
    };

    const goToSystem = async (loggedUserRole) => {
      await store.dispatch("user/updateNotification");
      if (loggedUserRole == UserRoles.SYS_ADMIN) {
        router.replace("/admin");
      } else if (loggedUserRole == UserRoles.STUDENT) {
        router.replace("/student");
      } else if (loggedUserRole == UserRoles.SUPERVISOR) {
        router.replace("/supervisor");
      }
    };

    /** linked in */
    const linkedInurl = computed(() => {
      localStorage.setItem("extToken", props.token);
      localStorage.setItem("extRole", props.role);
      const rediredUrl = appURL + "/linkedin-success";
      return (
        "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" +
        linkedinClientId +
        "&redirect_uri=" +
        rediredUrl +
        "&state=fdfdfdfd&scope=r_liteprofile%20r_emailaddress"
      );
    });

    return { signInUser, isLoading, linkedInurl };
  },
};
</script>
