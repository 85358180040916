<template>
  <div class="card-body">
    <p class="card-body--task mb-2">
      <router-link
        class="text-900"
        :to="'/supervisor/view-student-section/' + section.id"
      >
        {{ section.title }}
      </router-link>
    </p>

    <p class="card-body--msg">
      <!-- <strong>"</strong> -->
      {{ section.shareNote }}
      <!-- <strong>"</strong> -->
    </p>
    <div class="card-body-progress">
      <p class="card-body--deadline">{{ section.endDate }}</p>
      <p :class="statusCssClass">{{ section.sectionStatus }}</p>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";

import { SectionStatus } from "../../../app-constants";

export default {
  props: ["section"],
  setup(props) {
    const statusCssClass = computed(() => {
      return props.section.sectionStatus == SectionStatus.PENDING
        ? "card-body--pending"
        : props.section.sectionStatus == SectionStatus.COMPLETED
        ? "card-body--completed"
        : "card-body--overdue";
    });

    return {
      statusCssClass,
    };
  },
};
</script>
