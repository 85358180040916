<template>
  <the-header></the-header>
  <supervisor-nav-container></supervisor-nav-container>
  <router-view></router-view>
   <the-footer></the-footer>
</template>

<script>
import TheHeader from "../../components/layouts/TheHeader.vue";
import SupervisorNavContainer from "../../components/supervisor/SupervisorNavContainer.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";

export default {
  components: {
    TheHeader,
    SupervisorNavContainer,
    TheFooter
  },
  mounted() {
    this.$router.push("/supervisor/student-list");
  },
};
</script>
