<template>
  <thesis-supervisor-head-details
    :thesisId="thesisId"
  ></thesis-supervisor-head-details>

  <div class="container">
    <div class="row row-cols-sm-12">
      <div class="col-xxl-8 col-xl-8 order-xl-1 mb-4">
        <div class="my-progress-boxes">
          <div class="writing-content">
            <h5 class="writing-content--title">{{ sectionTitle }}</h5>
          </div>
          <div class="mb-3">
            <quill-editor
              v-model:value="state.content"
              :options="state.editorOption"
              :disabled="state.disabled"
            />
          </div>
        </div>
        <forum-home :sectionId="sectionId" :isStudent="false"></forum-home>
      </div>
      <div class="col-xxl-4 col-xl-4 order-xl-1 mb-4">
        <div class="column">
          <div class="col-xxl-12 col-xl-12 order-xl-1 mb-4">
            <shared-thesi-sections :thesisId="thesisId"></shared-thesi-sections>
          </div>
        </div>
      </div>
    </div>
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
import { quillEditor } from "vue3-quill";

import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";
import ForumHome from "../../forum/ForumHome.vue";
import ThesisSupervisorHeadDetails from "./ThesisSupervisorHeadDetails.vue";
import SharedThesiSections from "./SharedThesiSections.vue";

export default {
  props: ["sectionId"],
  components: {
    quillEditor,
    ForumHome,
    ThesisSupervisorHeadDetails,
    BaseSpinner,
    SharedThesiSections,
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const thesisId = ref(null);
    const sectionTitle = ref("");
    const sectionStatus = ref(null);
    //const sectionDetails = ref(null);

    const state = reactive({
      // dynamicComponent: null,
      content: "",
      _content: "",
      editorOption: {
        placeholder: "Enter Text",
        modules: {
          toolbar: [
            // custom toolbars options
            // will override the default configuration
          ],
        },
      },
      disabled: true,
    });

    const loadDetails = async (selectedSectionId) => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const response = await fetch(
        backendURL + "/api/getThesisSectionWritingDetails/" + selectedSectionId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          thesisId.value = resposeData.data.studentThesis.id;
          sectionTitle.value = resposeData.data.title;
          sectionStatus.value = resposeData.data.sectionStatus;
          state.content = resposeData.data.details;
        }
      }
      isLoading.value = false;
    };

    onMounted(() => {
      loadDetails(props.sectionId);
    });

    watch(
      () => props.sectionId,
      (value) => {
        loadDetails(value);
      }
    );

    return {
      isLoading,
      thesisId,
      sectionTitle,
      sectionStatus,
      state,
    };
  },
};
</script>
