<template>
  <div id="app">
    <notifications position="top center"></notifications>
    <!-- <h1>My Calendar</h1> -->
    <div class="row row-cols-sm-12">
      <div class="col-xxl-3 col-xl-3 mb-2">
        <div>
          <label class="form-label">Period UOM</label>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="displayPeriodUom"
          >
            <option value="month">Month</option>
            <option value="week">Week</option>
            <option value="year">year</option>
          </select>
        </div>

        <div>
          <label class="form-label mt-4">Period Count</label>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="displayPeriodCount"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>
        <div>
          <label class="form-label mt-4">Starting day of the week</label>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="startingDayOfWeek"
          >
            <option value="0">Sunday</option>
            <option value="1">Monday</option>
            <option value="2">Tuesday</option>
            <option value="3">Wednesday</option>
            <option value="4">Thrrsday</option>
            <option value="5">Friday</option>
            <option value="6">Saturday</option>
          </select>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="displayWeekNumbers"
            v-model="displayWeekNumbers"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Show week number
          </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="showTimes"
            v-model="showTimes"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Show times
          </label>
        </div>

        <!-- <div class="calendar-boxes"> -->
        <div>
          <add-calender-event @add-new-event="addNewEvent"></add-calender-event>
        </div>
      </div>

      <div class="col-xxl-9 col-xl-9 mb-10">
        <calendar-view
          :show-date="showDate"
          class="theme-default"
          :items="calenderEvents"
          :displayPeriodUom="displayPeriodUom"
          :displayPeriodCount="displayPeriodCount"
          :startingDayOfWeek="startingDayOfWeek"
          :displayWeekNumbers="displayWeekNumbers"
          :showTimes="showTimes"
          @click-item="deleteItem"
        >
          <template #header="{ headerProps }">
            <calendar-view-header
              :header-props="headerProps"
              @input="setShowDate"
            />
          </template>
        </calendar-view>
      </div>
    </div>
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>
<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";

import AddCalenderEvent from "./AddCalenderEvent.vue";

import "vue-simple-calendar/dist/style.css";
// The next two lines are optional themes
import "vue-simple-calendar/static/css/default.css";

import BaseSpinner from "../UI/BaseSpinner.vue";
import { backendURL } from "../../app-constants";
import { useConfirm } from "primevue/useconfirm";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: {
    CalendarView,
    CalendarViewHeader,
    AddCalenderEvent,
    BaseSpinner,
  },
  data: function () {
    return {
      displayPeriodUom: "month",
      displayPeriodCount: 1,
      startingDayOfWeek: 0,
      showDate: new Date(),
      displayWeekNumbers: false,
      showTimes: false,
      isLoading: false,
      calenderEvents: [],
      useConfirm,
    };
  },

  methods: {
    setShowDate(d) {
      this.showDate = d;
    },
    async addNewEvent(eventObj) {
      let nextId = this.calenderEvents.size;
      const calEvent = {
        id: nextId,
        startDate: eventObj.startDate.val,
        endDate: eventObj.endDate.val,
        title: eventObj.title.val,
        userId: this.$store.getters["user/loggedUserId"],
      };
      this.isLoading = true;
      const token = this.$store.getters["user/token"];

      try {
        const response = await fetch(
          backendURL + "/api/addCustomCalenderEvent",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(calEvent),
          }
        );

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error save section data"
          );
          throw error;
        }

        if (resposeData.valid) {
          this.isLoading = false;
          this.loadItems();
          // this.calenderEvents.push(calEvent);
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        this.isLoading = false;
        alert(error.message);
      }
    },
    deleteItem(item) {
      try {
        this.$confirm.require({
          message: "Do you want to delete?",
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            this.token = this.$store.getters["user/token"];
            fetch(backendURL + "/api/deleteCustomCalenderEvent", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.token,
              },
              body: JSON.stringify(item.originalItem.id),
            })
              .then((res) => res.json())
              .then((res) => {
                if (!res.valid) {
                  notify({
                    type: "warn",
                    title: "Note",
                    text: "Can't delete thesis item!",
                  });
                } else {
                  notify({
                    type: "success",
                    title: "SUCCESS",
                    text: "Successfully deleted",
                  });
                  this.loadItems();
                }
              });
          },
          reject: () => {
            //callback to execute when user rejects the action
          },
        });
      } catch (error) {
        this.isLoading = false;
        alert(error.message);
      }
    },
    loadItems() {
      this.token = this.$store.getters["user/token"];
      const userId = this.$store.getters["user/loggedUserId"];
      fetch(backendURL + "/api/getUserCalendarEvents/" + userId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.calenderEvents = [...res.data];
        });
    },
  },

  async mounted() {
    this.isLoadin = true;
    const token = this.$store.getters["user/token"];
    const userId = this.$store.getters["user/loggedUserId"];
    const response = await fetch(
      backendURL + "/api/getUserCalendarEvents/" + userId,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          //     //"Content-Type": "application/json",
        },
      }
    );
    const resposeData = await response.json();
    if (response.ok) {
      if (resposeData.valid) {
        this.calenderEvents = [...resposeData.data];
      }
    }
    this.isLoading = false;
  },
};
</script>
