<template>
  <the-header></the-header>
  <section id="contact" class="get-started mt-5">
    <div class="container">
      <div class="row text-center">
        <h2 class="login-text text-start mb-2">
          Change your Thesis Pro password
        </h2>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 p-3 d-none d-sm-block">
          <div class="login-info w-100">
            
            <img src="../../assets/images/illustrations/set_password.svg" class="d-block mx-lg-auto img-fluid" alt="Thesis Pro" width="400" height="400" loading="lazy">
          </div>
        </div>
        <div class="col-12 col-lg-6 bg-white shadow p-3">
          
            <div class="container">
              <form
                @submit.prevent="setPassword"
                class="row"
                autocomplete="off"
              >
                <div
                  class="col-12"
                  :class="{ errors: !loginData.extPassword.isValid }"
                >
                  <label for="password" class="form-label"
                    >Existing Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    id="extPassword"
                    name="extPassword"
                    v-model="loginData.extPassword.val"
                    @blur="clearValidity('extPassword')"
                  />
                  <div class="error-message">Existing Password is required</div>
                </div>

                <div
                  class="col-12"
                  :class="{ errors: !loginData.password.isValid }"
                >
                  <label for="password" class="form-label">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    name="password"
                    v-model="loginData.password.val"
                    @blur="clearValidity('password')"
                    @keyup="passwordValidity('password')"
                  />
                  <div class="error-message">Password is required</div>
                </div>

                <div
                  class="col-12"
                  :class="{ errors: !loginData.confirmPassowrd.isValid }"
                >
                  <label for="confirmPassowrd" class="form-label">
                    Confirm password <span class="text-muted"></span>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="confirmPassowrd"
                    name="confirmPassowrd"
                    v-model.trim="loginData.confirmPassowrd.val"
                    @blur="clearValidity('confirmPassowrd')"
                  />
                  <div class="error-message">ConfirmPassowrd is required</div>
                </div>

                <div class="message">
                  <h5>Password must contain the following:</h5>
                  <p :class="lowermessage ? 'validPass' : 'invalidPass'">
                    <i class="far fa-check-circle"></i> A
                    <b>lowercase</b> letter
                  </p>
                  <p :class="uppermessage ? 'validPass' : 'invalidPass'">
                    <i class="far fa-check-circle"></i> A
                    <b>capital (uppercase)</b> letter
                  </p>
                  <p :class="numbermessage ? 'validPass' : 'invalidPass'">
                    <i class="far fa-check-circle"></i> A <b>number</b>
                  </p>
                  <p :class="lengthmessage ? 'validPass' : 'invalidPass'">
                    <i class="far fa-check-circle"></i> Minimum
                    <b>8 characters</b>
                  </p>
                </div>

                <div class="text-center d-grid mt-3">
                  <button
                    class="btn btn-primary rounded-pill pt-3 pb-3"
                    type="submit"
                  >
                    SUBMIT
                    <i class="fas fa-paper-plane"></i>
                  </button>
                </div>
                <p v-if="!formIsValid" class="comman-error">
                  {{ invalidmessage }}
                </p>
              </form>
            </div>
           
        </div>
      </div>
    </div>
  </section>
  <the-footer></the-footer>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import TheHeader from "../../components/layouts/TheHeader.vue";
import { backendURL } from "../../app-constants";
import TheFooter from "../../components/layouts/TheFooter.vue";
export default {
  components: {
    TheHeader,
    TheFooter,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const loginData = reactive({
      extPassword: { val: "", isValid: true },
      confirmPassowrd: { val: "", isValid: true },
      password: { val: "", isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    function clearValidity(input) {
      loginData[input].isValid = true;
    }

    const lowermessage = ref(false);
    const uppermessage = ref(false);
    const numbermessage = ref(false);
    const lengthmessage = ref(false);

    function passwordValidity(input) {
      loginData[input].isValid = true;

      var lowerCaseLetters = /[a-z]/g;
      if (loginData.password.val.match(lowerCaseLetters)) {
        lowermessage.value = true;
      } else {
        lowermessage.value = false;
        formIsValid.value = false;
      }

      var upperCaseLetters = /[A-Z]/g;
      if (loginData.password.val.match(upperCaseLetters)) {
        uppermessage.value = true;
      } else {
        uppermessage.value = false;
        formIsValid.value = false;
      }

      var numbers = /[0-9]/g;
      if (loginData.password.val.match(numbers)) {
        numbermessage.value = true;
      } else {
        numbermessage.value = false;
        formIsValid.value = false;
      }

      if (loginData.password.val.length >= 8) {
        lengthmessage.value = true;
      } else {
        lengthmessage.value = false;
        formIsValid.value = false;
      }
    }

    function validateFrom() {
      formIsValid.value = true;
      if (loginData.extPassword.val == "") {
        loginData.extPassword.isValid = false;
        formIsValid.value = false;
      }

      if (loginData.confirmPassowrd.val == "") {
        loginData.confirmPassowrd.isValid = false;
        formIsValid.value = false;
      }
      if (loginData.password.val == "") {
        loginData.password.isValid = false;
        formIsValid.value = false;
      }
    }

    function setPassword() {
      validateFrom();

      if (!formIsValid.value) {
        invalidmessage.value = "Required  fields missing";
        return;
      }
      if (loginData.password.val != loginData.confirmPassowrd.val) {
        formIsValid.value = false;
        invalidmessage.value = "Passwords missmatch";
        return;
      }

      if (loginData.extPassword.val == loginData.password.val) {
        formIsValid.value = false;
        invalidmessage.value = "Can't user existing password as new password";
        return;
      }

      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      var object = {
        userId: userId,
        existPassword: loginData.extPassword.val,
        newPassword: loginData.confirmPassowrd.val,
      };
      fetch(backendURL + "/api/auth/updatePassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(object),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.data === false) {
            invalidmessage.value = "Existing password is incorrect";
            formIsValid.value = false;
            return;
          } else {
            store.dispatch("user/userLoggedOut");
            router.push("/signin");
          }
        });
    }

    return {
      loginData,
      formIsValid,
      invalidmessage,
      clearValidity,
      setPassword,
      lowermessage,
      uppermessage,
      numbermessage,
      lengthmessage,
      passwordValidity,
    };
  },
};
</script>
 