<template>
  <!-- THE NAVBAR SECTION -->
  <!-- <container class="intro-section"> -->
  <nav class="navbar navbar-expand-lg navbar-light menu">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo.svg" alt="Thesis Pro" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link active">HOME</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- </container> -->
  <!-- THE NAVBAR SECTION END -->
  <!-- SIGNIN SECTION -->

  <div class="container-fluid">
    <div class="my-alerts">
      <div class="alert alert-info alert-dismissible fade show" role="alert">
        User accout creaed successfully, A emai has sent to your email address
        for create password
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>
