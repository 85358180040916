<template>
  <the-header></the-header>
  <student-nav-container v-if="hasThesis"></student-nav-container>
  <router-view></router-view>
  <the-footer></the-footer>
</template>

<script>
import TheHeader from "../../components/layouts/TheHeader.vue";
import StudentNavContainer from "../../components/student/StudentNavContainer.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";

export default {
  components: {
    TheHeader,
    StudentNavContainer,
    TheFooter
  },
  computed: {
    hasThesis() {
      return this.$store.getters["user/isThesisActivated"];
    },
  },

  mounted() {
    if (this.hasThesis) {
      this.$router.push("/student/student-progress");
    } else {
      this.$router.push("/student/thesis-start");
    }
  },
};
</script>
