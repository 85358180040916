<template>
  <button
    :class="sctStatusButonColourClass"
    class="me-md-2"
    type="button"
    @click="toggleStatus"
  >
    {{ sctStatusText }} <i :class="sctStatusIconColourClass"></i>
  </button>
  <base-spinner v-if="isLoading"></base-spinner>
</template>
<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import BaseSpinner from "../../UI/BaseSpinner.vue";

import { SectionStatus, backendURL } from "../../../app-constants";

export default {
  components: {
    BaseSpinner,
  },
  props: ["sectionId", "sectionStatus"],
  emits: ["reload-section"],
  setup(props, { emit }) {
    const store = useStore();
    const isLoading = ref(false);

    const sctStatusIconColourClass = computed(() => {
      return props.sectionStatus == SectionStatus.COMPLETED
        ? "fas fa-circle-notch"
        : "fas fa-check-square";
    });

    const sctStatusText = computed(() => {
      return props.sectionStatus == SectionStatus.COMPLETED
        ? "Mark as not complete"
        : "Mark as complete";
    });
    const sctStatusButonColourClass = computed(() => {
      return props.sectionStatus == SectionStatus.COMPLETED
        ? "btn-task--warning"
        : "btn-task--success";
    });

    const toggleStatus = async () => {
      if (confirm("Do you sure?")) {
        //isLoading.value = true;
        const token = store.getters["user/token"];

        try {
          const response = await fetch(
            backendURL + "/api/toggleSectionStatus/" + props.sectionId,
            {
              method: "GET",
              headers: {
                //"Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );

          const resposeData = await response.json();

          if (!response.ok) {
            const error = new Error(
              resposeData.message || "Error save section data"
            );
            throw error;
          }

          if (resposeData.valid) {
            isLoading.value = false;
            emit("reload-section");
          } else {
            const error = new Error(resposeData.message);
            throw error;
          }
        } catch (error) {
          isLoading.value = false;
          alert(error.message);
        }
      }
    };

    return {
      sctStatusIconColourClass,
      sctStatusText,
      isLoading,
      toggleStatus,
      sctStatusButonColourClass,
    };
  },
};
</script>
