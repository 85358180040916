<template>
  <!-- <the-header></the-header> -->

  <nav class="navbar navbar-expand-lg navbar-light menu">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo.svg" alt="Thesis Pro" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link active">HOME</router-link>
          </li>
        </ul>
        <router-link to="/signin" class="rounded-pill btn-rounded">
          LOG IN
          <span><i class="fas fa-user"></i></span>
        </router-link>
      </div>
    </div>
  </nav>

  <div class="container">
    <div class="help-guide">
      <h4 class="main-heading text-start">Privacy</h4>
    </div>
    <div class="my-progress-boxes">
      <div class="writing-content"> 
        <span v-html="terms"></span>
      </div>
    </div>
  </div>
  <the-footer></the-footer>
</template>

<script>
import { backendURL } from "../../app-constants";
// import TheHeader from "../../components/layouts/TheHeader.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";
export default {
  components: {
    // TheHeader,
    TheFooter,
  },
  data() {
    return {
      terms: "",
    };
  },
  mounted() {
    // this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/terms/listAllTerms/privacy", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.terms = res.data.termsText;
      });
  },
};
</script>
