<template>
  <!-- MY PROGRESS SECTION -->
  <div class="container">
    <h5 class="main-heading text-start">DETAILS ABOUT YOUR THESIS</h5>
    <div class="row">
      <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10 mt-5">
        <div class="user-credentials-boxes">
          <!-- user-credentials-form -->
          <form @submit.prevent="updateThesis">
            <div class="row mt-4">
              <div :class="{ errors: !thesisData.researchArea.isValid }">
                <rn-d-tree-view
                  :detailId="thesisData.researchArea.val"
                  @selected-rnd="getSelectedRND"
                ></rn-d-tree-view>
                <div class="error-message">
                  Please select a Research and development.
                </div>
              </div>
            </div>

            <div class="row g-3 mt-3 mx-3">
              <div
                class="col-12"
                :class="{ errors: !thesisData.thesisTitle.isValid }"
              >
                <label for="thesisTitle" class="form-label"
                  >Title of the thesis
                  <span class="text-muted"
                    >(Automatic search of resources from the web, based on
                    keywords. See the section of Web Resources)</span
                  ></label
                ><sup><i class="fa fa-asterisk"></i></sup>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    id="thesisTitle"
                    name="thesisTitle"
                    v-model.trim="thesisData.thesisTitle.val"
                    @blur="clearValidity('thesisTitle')"
                    maxlength="255"
                    style="margin-top: 0px; margin-bottom: 0px; height: 70px"
                  ></textarea>
                  <label for="floatingTextarea"
                    >Add your thesis title here</label
                  >
                  <div class="error-message">
                    Please enter Title of the thesis.
                  </div>
                </div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.keyWords.isValid }"
              >
                <label for="keyWords" class="form-label"
                  >Keywords
                  <span class="text-muted"
                    >(Automatic search of resources from the web, based on
                    keywords. See the section of Web Resources)</span
                  ></label
                >
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    id="keyWords"
                    name="keyWords"
                    v-model.trim="thesisData.keyWords.val"
                    @blur="clearValidity('keyWords')"
                    maxlength="255"
                    style="margin-top: 0px; margin-bottom: 0px; height: 100px"
                  ></textarea>
                  <label for="floatingTextarea"
                    >Add comma seperated keywords</label
                  >
                  <div class="error-message">Please enter keywords.</div>
                </div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.researchQuestion.isValid }"
              >
                <label for="textarea" class="form-label"
                  >Research question & idea <span class="text-muted"></span
                ></label>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    id="researchQuestion"
                    name="researchQuestion"
                    v-model.trim="thesisData.researchQuestion.val"
                    @blur="clearValidity('researchQuestion')"
                    maxlength="255"
                    style="margin-top: 0px; margin-bottom: 0px; height: 113px"
                  ></textarea>
                  <label for="floatingTextarea"
                    >Add your reseach question and research idea
                  </label>
                  <div class="error-message">
                    Please enter reseach question.
                  </div>
                </div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.startDate.isValid }"
              >
                <label for="startDate" class="form-label">Starting date</label>
                <sup><i class="fa fa-asterisk"></i></sup>
                <datepicker
                  class="form-control"
                  id="startDate"
                  name="startDate"
                  v-model="thesisData.startDate.val"
                  @blur="clearValidity('startDate')"
                />
                <div class="error-message">Please select start date.</div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.endDate.isValid }"
              >
                <label for="endDate" class="form-label"
                  >When do you plan to finish?</label
                >
                <datepicker
                  class="form-control"
                  id="endDate"
                  name="endDate"
                  v-model="thesisData.endDate.val"
                  @blur="clearValidity('endDate')"
                />
                <div class="error-message">Please select end date.</div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.citation.isValid }"
              >
                <label for="endDate" class="form-label"
                  >Select citation type?</label
                >

                <select
                class="form-select"
                aria-label="Default select example"
               v-model="thesisData.citation.val"
              >
                <option value="apa">
                  APA
                </option>
                <option value="vancouver">
                  Vancouver
                </option>
                <option value="harvard1">
                  Harvard
                </option>
                <option value="csl">
                  CSL-JSON
                </option>
                <option value="bibtex">
                  BibTeX
                </option>
                <option value="bibtxt">
                  Bib.TXT
                </option>
              </select>

                <div class="error-message">Please select citation type.</div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.supervisor.isValid }"
                v-if="thesisData.supervisor.val"
              >
                <label for="supervisor" class="form-label">Supervisor</label>

                <input
                  type="text"
                  class="form-control"
                  id="supervisor"
                  name="supervisor"
                  v-model="thesisData.supervisor.val"
                  @blur="clearValidity('supervisor')"
                  readonly
                />
                <div class="error-message">Please select end date.</div>
              </div>

              <div class="col-12" v-if="hasPendingRequest">
                <p class="thesis-about--supervisor mb-0">
                  Waiting for supervisor response
                </p>
              </div>
              <div class="col-12">
                <button
                  type="button"
                  class="btn-task--success me-md-2"
                  @click="toggleChageSupervisor"
                  v-if="!showChangeSupervisor"
                >
                  Change Superviosr
                </button>
              </div>
              <div class="col-12" v-if="showChangeSupervisor">
                <select-supervisor
                  :thesisId="thesisData.id.val"
                  @refresh-data="refreash"
                ></select-supervisor>
              </div>

              <div class="row g-0">
                <div class="col-12">
                  <AllSDG
                    :sdgSelected="sdgSelected"
                    @selected-sdg="getSelectedSDG"
                  ></AllSDG>
                </div>
              </div>

              <div class="d-grid gap-2 d-md-flex justify-content-md-left">
                <button class="btn-task--success me-md-2">
                  SAVE <i class="fas fa-save"></i>
                </button>
                <button
                  class="btn-task--danger me-md-2"
                  type="button"
                  @click="cancelButton"
                >
                  CANCEL <i class="fas fa-window-close"></i>
                </button>
              </div>
              <p v-if="!formIsValid" class="comman-error">
                {{ invalidmessage }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- </section> -->
  <!-- END MY PROGRESS SECTION -->
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import Datepicker from "vue3-datepicker";
import RnDTreeView from "../ResearchComponent/RnDTreeView.vue";
import AllSDG from "../../components/sdgComponent/AllSDG.vue";
import { backendURL } from "../../app-constants";
import BaseSpinner from "../UI/BaseSpinner.vue";
import SelectSupervisor from "./SelectSupervisor.vue";
import { useConfirm } from "primevue/useconfirm";

export default {
  components: {
    Datepicker,
    RnDTreeView,
    AllSDG,
    BaseSpinner,
    SelectSupervisor,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isLoading = ref(false);
    const confirm = useConfirm();

    const thesisData = reactive({
      id: { val: null, isValid: true },
      researchArea: { val: null, isValid: true },
      thesisTitle: { val: "", isValid: true },
      researchQuestion: { val: "", isValid: true },
      startDate: { val: null, isValid: true },
      endDate: { val: null, isValid: true },
      keyWords: { val: null, isValid: true },
      sdgList: { val: null, isValid: true },
      supervisor: { val: null, isValid: true },
      citation: { val: null, isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const sdgSelected = ref([]);
    const hasPendingRequest = ref(false);
    const showChangeSupervisor = ref(true);

    function clearValidity(input) {
      thesisData[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;
      if (!thesisData.researchArea.val || thesisData.researchArea.val == "") {
        thesisData.researchArea.isValid = false;
        formIsValid.value = false;
      }
      if (thesisData.thesisTitle.val == "") {
        thesisData.thesisTitle.isValid = false;
        formIsValid.value = false;
      }

      if (!thesisData.startDate.val || thesisData.startDate.val == "") {
        thesisData.startDate.isValid = false;
        formIsValid.value = false;
      }

     /* if (thesisData.citation.val == "") {
        thesisData.citation.isValid = false;
        formIsValid.value = false;
      }*/
    }

    function getSelectedSDG(sdgList) {
      thesisData.sdgList.val = sdgList;
    }

    function getSelectedRND(researchAreaX) {
      thesisData.researchArea.val = researchAreaX;
    }

    function updateThesis() {
      confirm.require({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          updateThesisAfterConfirm();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    }

    async function updateThesisAfterConfirm() {
      //isLoading.value = true;
      validateFrom();
      if (!formIsValid.value) {
        isLoading.value = false;
        invalidmessage.value = "Required  fields missing";
        return;
      }
      try {
        //Prepar sdgObject
        const sdgObjList = new Array();
        if (thesisData.sdgList.val) {
          for (let i = 0; i < thesisData.sdgList.val.length; i++) {
            sdgObjList.push({ id: thesisData.sdgList.val[i] });
          }
        }

        const token = store.getters["user/token"];
        const userId = store.getters["user/loggedUserId"];
        const response = await fetch(backendURL + "/api/updateThesis", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: thesisData.id.val,
            researchAndDevelop: { id: thesisData.researchArea.val },
            sdgs: sdgObjList,
            title: thesisData.thesisTitle.val,
            keyWords: thesisData.keyWords.val,
            researchQuestion: thesisData.researchQuestion.val,
            startingDate: thesisData.startDate.val,
            endDate: thesisData.endDate.val,
            student: { id: userId },
            citation:thesisData.citation.val
          }),
        });
        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error update student thesis"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          store.dispatch("user/updateUserThesisStatus");
          //  alert("Thesis updated successfully");
          router.push("/student/thesis-writing");
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = error.message;
      }
    }

    function cancelButton() {
      router.push("/student/thesis-writing");
    }

    onMounted(() => {
      loadDetails();
    });

    const loadDetails = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getUserThesisDetails/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          sdgSelected.value = resposeData.data.sdgIds;
          thesisData.id.val = resposeData.data.id;
          thesisData.researchArea.val = resposeData.data.randdId;
          thesisData.thesisTitle.val = resposeData.data.title;
          thesisData.researchQuestion.val = resposeData.data.researchQuestion;
          thesisData.startDate.val = resposeData.data.startingDate
            ? new Date(resposeData.data.startingDate)
            : null;
          thesisData.endDate.val = resposeData.data.startingDate
            ? new Date(resposeData.data.endDate)
            : null;
          thesisData.keyWords.val = resposeData.data.keyWords;
          thesisData.sdgList.val = resposeData.data.sdgIds;
          thesisData.supervisor.val = resposeData.data.supervisor
            ? resposeData.data.supervisor.fullName
            : "";
          hasPendingRequest.value = resposeData.data.hasPendingRequest;
          showChangeSupervisor.value = !(
            thesisData.supervisor.val || hasPendingRequest.value
          );
          thesisData.citation.val = resposeData.data.citation;
        }
      }
      isLoading.value = false;
    };

    const toggleChageSupervisor = () => {
      showChangeSupervisor.value = true;
    };

    const refreash = () => {
      loadDetails();
    };

    return {
      thesisData,
      formIsValid,
      invalidmessage,
      clearValidity,
      updateThesis,
      cancelButton,
      isLoading,
      getSelectedSDG,
      getSelectedRND,
      sdgSelected,
      hasPendingRequest,
      showChangeSupervisor,
      toggleChageSupervisor,
      refreash,
    };
  },
};
</script>
