<template>
  <!-- <router-link :to="to"> 
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
           
            <h3 class="text-uppercase text-muted mb-2">
              {{ title }}
            </h3>
           
            <span class="h6 mb-0">
              {{ subtitle }}
            </span>
          </div>
          <div class="col-auto-sysadmin">
            
            <span :class="iconBg"><i :class="iconClass"></i></span>
          </div>
        </div>
      </div>
    </div>
  </router-link> -->

  <div class="main_menu">
    <router-link :to="to">
    <div class="menu-list"> 
      
        <div class="menu-list__item">
          <span class="menu-list__item__icon"><i :class="iconClass"></i></span>
          <span class="menu-list__item__title">&nbsp; {{ title }}</span>
        </div>
      
    </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["to", "title", "subtitle", "iconClass", "iconBg"],
};
</script> 
