<template>
  <div class="writing-content">
    <h5 class="writing-content--title">
      DEADLINES
    </h5>
  </div>

  <!-- TASKS -->

  <div class="card mb-2" v-for="deadLine in deadLineList" :key="deadLine.id">
    <div class="card-body">
      <p class="card-body--task">{{ deadLine.title }}</p>
      <p class="card-body--deadline">{{ deadLine.endDate }}</p>
    </div>
  </div>

  <!-- TASKS END -->
  <base-spinner v-if="isLoading"></base-spinner>
</template>
<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";

import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";
export default {
  props: ["thesisId"],
  components: {
    BaseSpinner,
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const deadLineList = ref([]);

    const loadDeadlines = async (selectedThesisId) => {
      if (selectedThesisId) {
        //isLoading.value = true;
        const token = store.getters["user/token"];
        const response = await fetch(
          backendURL + "/api/getThesisLatestDeadLines/" + selectedThesisId,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            isLoading.value = false;
            deadLineList.value = resposeData.data;
          }
        }
        isLoading.value = false;
      }
    };

    onMounted(() => {
      loadDeadlines(props.thesisId);
    });

    watch(
      () => props.thesisId,
      (value) => {
        loadDeadlines(value);
      }
    );

    return {
      isLoading,
      deadLineList,
    };
  },
};
</script>
