<template>
  <section class="notification-boxes--msg mb-2" :class="notifiUnreadColourCss">
    <div class="row no-gutters mb-1">
      <div class="col-xl-5 col-md-6 mb-1 px-2 px-md-3">
        <div class="row">
          <div class="col-md-1 text-center">
            <!-- profile picture of student-->

            <div class="user-picture">
              <img
                :src="notifiObj.userImage"
                alt="mdo"
                width="50"
                height="50"
                class="rounded-circle shadow-sm"
              />
            </div>
          </div>
          <div class="col-md-11 text-right text-600">
            <div class="ml-2 media-body">
              <div class="notification">
                <p class="notification--sender">{{ notifiObj.sender }}</p>

                <p class="notification--title mb-2">
                  <a href="#" @click="reDirectedToPage"
                    >{{ notifiObj.message }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-7 col-md-6 px-2 px-md-3">
        <div class="row">
          <div class="col-lg-12">
            <div class="row g-2">
              <div class="col-md-3">
                <p class="notification--type">TYPE</p>
                <p class="notification--type-descrip">
                  {{ notifiType }}
                </p>
              </div>
              <div class="col-md-4">
                <p class="notification--type">received on</p>
                <p class="notification--type-descrip">
                  {{ notifiObj.date }}
                </p>
              </div>
              <div class="col-md-5 mt-0">
                <div class="col-md-5 mt-0">
                  <div class="social-container text-center">
                    <a href="#" class="social" @click="markAsRead1"
                      ><i class="fas fa-envelope-open-text"></i
                    ></a>
                    <a href="#" class="social" @click="deleteNotification"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  backendURL,
  NotificationTypes,
  NotificationTypesText,
  UserRoles,
} from "../../app-constants";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";
import { notify } from "@kyvg/vue3-notification";

export default {
  props: ["notifiObj"],
  emits: ["load-notifications"],
  components: {
    BaseSpinner,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const isLoading = ref(false);

    const notifiUnreadColourCss = computed(() => {
      return props.notifiObj.read ? "" : "notifi-unread";
    });

    const notifiType = computed(() => {
      return NotificationTypesText[props.notifiObj.type];
    });

    const reDirectedToPage = () => {
      markAsRead();
    };

    const markAsRead = async () => {
      if (!props.notifiObj.read) {
        //isLoading.value = true;
        const token = store.getters["user/token"];
        const response = await fetch(
          backendURL + "/api/notificationMarkAsRead/" + props.notifiObj.id,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            isLoading.value = false;
            store.dispatch("user/updateNotification");
            redirecTo();
          }
        }
        isLoading.value = false;
      } else {
        redirecTo();
      }
    };

    const redirecTo = () => {
      let pageUrl = null;
      if (props.notifiObj.type == NotificationTypes.REQUEST) {
        if (props.notifiObj.role == UserRoles.SUPERVISOR) {
          pageUrl = "/supervisor/student-requests";
        }
      } else if (props.notifiObj.type == NotificationTypes.COMMANFORUM) {
        if (props.notifiObj.role == UserRoles.SUPERVISOR) {
          pageUrl =
            "/supervisor/student-shared-thesis/" + props.notifiObj.commonId;
        } else if (props.notifiObj.role == UserRoles.STUDENT) {
          pageUrl = "/student/student-progress";
        }
      } else if (props.notifiObj.type == NotificationTypes.SECTIONFORUM) {
        if (props.notifiObj.role == UserRoles.SUPERVISOR) {
          pageUrl =
            "/supervisor/view-student-section/" + props.notifiObj.commonId;
        } else if (props.notifiObj.role == UserRoles.STUDENT) {
          pageUrl = "/student/thesis-section/" + props.notifiObj.commonId;
        }
      }
      if (pageUrl) {
        router.push(pageUrl);
      }
    };

    const markAsRead1 = async () => {
      if (!props.notifiObj.read) {
        //isLoading.value = true;
        const token = store.getters["user/token"];
        const response = await fetch(
          backendURL + "/api/notificationMarkAsRead/" + props.notifiObj.id,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            isLoading.value = false;
            store.dispatch("user/updateNotification");
            emit("load-notifications");
          }
        }
        isLoading.value = false;
      }
    };

    const deleteNotification = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const response = await fetch(
        backendURL + "/api/deleteNotification/" + props.notifiObj.id,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          isLoading.value = false;
          notify({
            type: "success",
            title: "SUCCESS",
            text: "Notification deleted",
          });
          store.dispatch("user/updateNotification");
          emit("load-notifications");
        }
      }
      isLoading.value = false;
    };

    return {
      notifiUnreadColourCss,
      reDirectedToPage,
      isLoading,
      notifiType,
      markAsRead1,
      deleteNotification,
    };
  },
};
</script>

<style scoped>
.notifi-unread {
  background-color: #e6eaf7;
}
</style>
