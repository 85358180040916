<template>
  <button class="btn-task--success me-md-2" type="button" @click="toWordFile">
    Email document <i class="fas fa-file-word"></i>
  </button>
  <img
    v-if="showProgress"
    src="@/assets/images/loding1.gif"
    alt="sdg"
    width="50"
    height="50"
    class="mt-10 shadow-sm"
  />
  <span class="thesis-icons--success" v-if="showSuccess">
    <i class="fas fa-check lg" style="color:rgb(41 212 22);"></i>
  </span>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import * as quillToWord from "quill-to-word";
import { backendURL } from "../../../app-constants";

const quillToWordConfig = {
  exportAs: "blob",
};

export default {
  props: ["quill", "sectionTitle", "sectionId"],
  setup(props) {
    const store = useStore();
    const showProgress = ref(false);
    const showSuccess = ref(false);
    const filename = ref("");

    const toWordFile = async () => {
      showProgress.value = true;
      showSuccess.value = false;

      const docAsBlob = await quillToWord.generateWord(
        props.quill.getContents(),
        quillToWordConfig
      );
      const userId = store.getters["user/loggedUserId"];
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("sectionId", props.sectionId);
      formData.append("document", docAsBlob);
      formData.append("docType", ".docx");
      formData.append("fileName", filename.value);
      uploadProfileData(formData);
    };

    const uploadProfileData = async (formData) => {
      try {
        const token = store.getters["user/token"];
        const response = await fetch(backendURL + "/api/sendSectionWordCopy", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            con: "multipart/form-data",
          },
          body: formData,
        });
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            alert(resposeData.message);
            showProgress.value = false;
            showSuccess.value = true;
          }
        } else {
          showProgress.value = false;
          showSuccess.value = true;
        }
      } catch (error) {
        showProgress.value = false;
        showSuccess.value = true;
        alert(error);
      }
    };

    watch(
      () => props.sectionTitle,
      (value) => {
        filename.value = value + ".docx";
      }
    );

    return {
      showProgress,
      showSuccess,
      toWordFile,
    };
  },
};
</script>
