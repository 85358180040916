<template>
  <the-header></the-header>
  <admin-nav-container></admin-nav-container>
  <router-view></router-view>
  <the-footer></the-footer>
</template>

<script>
import TheHeader from "../../components/layouts/TheHeader.vue";
import AdminNavContainer from "../../components/admin/AdminNavContainer.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";

export default {
  components: {
    TheHeader,
    AdminNavContainer,
    TheFooter,
  },
};
</script>
