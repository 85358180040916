<template>
  <!-- MY PROGRESS SECTION -->

  <section class="my-thesis-boxes">
    <div class="container">
      <div class="help-guide">
        <h4 class="main-heading text-start">DETAILS ABOUT YOUR THESIS</h4>
        <div class="float-end"></div>
      </div>
    </div>
  </section>

  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
        <div class="user-credentials-boxes">
          <!-- <h5 class="user-credentials-boxes--title mt-5 mb-1">
           
          </h5> -->

          <!-- user-credentials-form -->
          <form @submit.prevent="saveThesis">
            <div class="row mt-4">
              <div :class="{ errors: !thesisData.researchArea.isValid }">
                <rn-d-tree-view @selected-rnd="getSelectedRND"></rn-d-tree-view>
                <div class="error-message">
                  Please select a Research and development.
                </div>
              </div>
            </div>

            <div class="row g-3 mt-3 mx-3">
              <div
                class="col-12"
                :class="{ errors: !thesisData.thesisTitle.isValid }"
              >
                <label for="thesisTitle" class="form-label"
                  >Title of the thesis
                  <span class="text-muted"
                    >(Automatic search of resources from the web, based on
                    keywords. See the section of Web Resources)</span
                  ></label
                >
                <sup><i class="fa fa-asterisk"></i></sup>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    id="thesisTitle"
                    name="thesisTitle"
                    v-model.trim="thesisData.thesisTitle.val"
                    @blur="clearValidity('thesisTitle')"
                  ></textarea>
                  <label for="floatingTextarea"
                    >Add your thesis title here</label
                  >
                  <div class="error-message">
                    Please enter Title of the thesis.
                  </div>
                </div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.keyWords.isValid }"
              >
                <label for="keyWords" class="form-label"
                  >Keywords
                  <span class="text-muted"
                    >(Automatic search of resources from the web, based on
                    keywords. See the section of Web Resources)</span
                  ></label
                >
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    id="keyWords"
                    name="keyWords"
                    v-model.trim="thesisData.keyWords.val"
                    @blur="clearValidity('keyWords')"
                  ></textarea>
                  <label for="floatingTextarea"
                    >Add comma seperated keywords</label
                  >
                  <div class="error-message">Please enter keywords.</div>
                </div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.researchQuestion.isValid }"
              >
                <label for="textarea" class="form-label"
                  >Research question & idea <span class="text-muted"></span
                ></label>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    id="researchQuestion"
                    name="researchQuestion"
                    rows="4"
                    v-model.trim="thesisData.researchQuestion.val"
                    @blur="clearValidity('researchQuestion')"
                  ></textarea>
                  <label for="floatingTextarea"
                    >Add your reseach question and research idea
                  </label>
                  <div class="error-message">
                    Please enter reseach question.
                  </div>
                </div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.startDate.isValid }"
              >
                <label for="startDate" class="form-label">Starting date</label>
                <sup><i class="fa fa-asterisk"></i></sup>
                <datepicker
                  class="form-control"
                  id="startDate"
                  name="startDate"
                  v-model="thesisData.startDate.val"
                  @blur="clearValidity('startDate')"
                />
                <div class="error-message">Please select start date.</div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.endDate.isValid }"
              >
                <label for="endDate" class="form-label"
                  >When do you plan to finish? <span class="text-muted"></span
                ></label>

                <datepicker
                  class="form-control"
                  id="endDate"
                  name="endDate"
                  v-model="thesisData.endDate.val"
                  @blur="clearValidity('endDate')"
                />
                <div class="error-message">Please select end date.</div>
              </div>

              <div
                class="col-12"
                :class="{ errors: !thesisData.citation.isValid }"
              >
                <label for="endDate" class="form-label"
                  >Select citation type?</label
                >

                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="thesisData.citation.val"
                >
                  <option value="apa">APA</option>
                  <option value="vancouver">Vancouver</option>
                  <option value="harvard1">Harvard</option>
                  <option value="csl">CSL-JSON</option>
                  <option value="bibtex">BibTeX</option>
                  <option value="bibtxt">Bib.TXT</option>
                </select>

                <div class="error-message">Please select citation type.</div>
              </div>

              <div class="row g-0">
                <div class="col-12">
                  <AllSDG @selected-sdg="getSelectedSDG"></AllSDG>
                </div>
              </div>

              <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                <button class="btn btn-outline-success me-md-2">
                  SAVE <i class="fas fa-save"></i>
                </button>
                <button
                  v-if="false"
                  class="btn btn-outline-danger me-md-2"
                  type="button"
                >
                  CANCEL <i class="fas fa-window-close"></i>
                </button>
              </div>
              <p v-if="!formIsValid" class="comman-error">
                {{ invalidmessage }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- </section> -->
  <!-- END MY PROGRESS SECTION -->
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Datepicker from "vue3-datepicker";
import RnDTreeView from "../ResearchComponent/RnDTreeView.vue";
import AllSDG from "../../components/sdgComponent/AllSDG.vue";
import { backendURL } from "../../app-constants.js";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";

export default {
  components: {
    Datepicker,
    RnDTreeView,
    AllSDG,
    BaseSpinner,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const thesisData = reactive({
      researchArea: { val: null, isValid: true },
      thesisTitle: { val: "", isValid: true },
      researchQuestion: { val: "", isValid: true },
      startDate: { val: null, isValid: true },
      endDate: { val: null, isValid: true },
      keyWords: { val: null, isValid: true },
      sdgList: { val: null, isValid: true },
      citation: { val: null, isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);

    function clearValidity(input) {
      thesisData[input].isValid = true;
    }

    function getSelectedRND(researchAreaX) {
      thesisData.researchArea.val = researchAreaX;
    }

    function getSelectedSDG(sdgList) {
      thesisData.sdgList.val = sdgList;
    }

    function validateFrom() {
      formIsValid.value = true;
      if (!thesisData.researchArea.val || thesisData.researchArea.val == "") {
        thesisData.researchArea.isValid = false;
        formIsValid.value = false;
      }
      if (thesisData.thesisTitle.val == "") {
        thesisData.thesisTitle.isValid = false;
        formIsValid.value = false;
      }

      if (!thesisData.startDate.val || thesisData.startDate.val == "") {
        thesisData.startDate.isValid = false;
        formIsValid.value = false;
      }

     /* if (thesisData.citation.val == "") {
        thesisData.citation.isValid = false;
        formIsValid.value = false;
      }*/
    }

    async function saveThesis() {
      //isLoading.value = true;
      validateFrom();
      if (!formIsValid.value) {
        isLoading.value = false;
        invalidmessage.value = "Required  fields missing";
        return;
      }

      try {
        //Prepar sdgObject
        const sdgObjList = new Array();
        if (thesisData.sdgList.val) {
          for (let i = 0; i < thesisData.sdgList.val.length; i++) {
            sdgObjList.push({ id: thesisData.sdgList.val[i] });
          }
        }

        const token = store.getters["user/token"];
        const userId = store.getters["user/loggedUserId"];
        const response = await fetch(backendURL + "/api/createThesis", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            researchAndDevelop: { id: thesisData.researchArea.val },
            sdgs: sdgObjList,
            title: thesisData.thesisTitle.val,
            keyWords: thesisData.keyWords.val,
            researchQuestion: thesisData.researchQuestion.val,
            startingDate: thesisData.startDate.val,
            endDate: thesisData.endDate.val,
            student: { id: userId },
            citation:thesisData.citation.val
          }),
        });
        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error create student thesis"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          store.dispatch("user/updateUserThesisStatus");
          router.push("/student/student-progress");
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = error.message;
      }
    }

    return {
      thesisData,
      formIsValid,
      invalidmessage,
      clearValidity,
      saveThesis,
      isLoading,
      getSelectedRND,
      getSelectedSDG,
    };
  },
};
</script>
