<template>
  <div class="resource-filter">
    <h5 class="resource-filter--title border-bottom mb-2">YEAR</h5>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="year"
        id="thisYear"
        v-model="filter.year"
        value="0"
        @change="filterResources"
      />
      <label class="form-check-label" for="thisYear"> This year </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="year"
        id="lstYear"
        v-model="filter.year"
        value="-1"
        @change="filterResources"
      />
      <label class="form-check-label" for="lstYear"> Last year </label>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="year"
        id="older"
        v-model="filter.year"
        value="-2"
        @change="filterResources"
      />
      <label class="form-check-label" for="older"> Older </label>
    </div>
  </div>

  <div class="resource-filter">
    <h5 class="resource-filter--title border-bottom mb-2 mt-3">ACCESS TYPE</h5>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        :value="true"
        id="openAccess"
        v-model="filter.openAccess"
        @change="filterResources"
      />
      <label class="form-check-label" for="openAccess"> Open access </label>
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        :value="false"
        id="closeAccess"
        v-model="filter.openAccess"
        @change="filterResources"
      />
      <label class="form-check-label" for="closeAccess"> Paid access </label>
    </div>
  </div>

  <div class="resource-filter">
    <h5 class="resource-filter--title border-bottom mb-2 mt-3">SDG</h5>
    <div class="form-check" v-for="s in sdgList" :key="s.id">
      <input
        class="form-check-input"
        type="checkbox"
        :value="s.id"
        id="flexCheckDefault"
        v-model="filter.sdg"
        @change="filterResources"
      />
      <label class="form-check-label" for="flexCheckDefault">
        {{ s.sdgName }}
      </label>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { reactive } from "@vue/reactivity";
import { backendURL } from "../../../app-constants";
import { useStore } from "vuex";
export default {
  emits: ["filter-resources"],
  setup(props, { emit }) {
    const filter = reactive({
      year: null,
      openAccess: [true, false],
      sdg: [],
    });
    const store = useStore();
    const sdgList = ref([]);

    onMounted(() => {
      loadAllThesisSDG();
    });

    const loadAllThesisSDG = async () => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      fetch(backendURL + "/api/getThesisSdg/" + userId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            sdgList.value = res.data;
          }
        });
    };

    const filterResources = () => {
      emit("filter-resources", filter);
    };

    return {
      filter,
      filterResources,
      sdgList,
    };
  },
};
</script>
