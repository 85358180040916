<template>
  <!-- THE NAVBAR SECTION -->
  <container class="intro-section">
    <nav class="navbar navbar-expand-lg navbar-light menu shadow fixed-top">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../assets/images/logo.svg" alt="Thesis Pro" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">HOME</a>
            </li>
          </ul>
          <router-link to="/signin" class="rounded-pill btn-rounded">
            LOG IN
            <span><i class="fas fa-user"></i></span>
          </router-link>
        </div>
      </div>
    </nav>
  </container>
  <!-- THE NAVBAR SECTION END -->

  <!--INTRO SECTION -->

  <section id="home" class="intro-section">
    <div class="container col-xxl-12 px-4 py-1">
      <div class="row flex-lg-row-reverse align-items-center g-5">
        <div class="col-10 col-sm-8 col-lg-6">
          <img
            src="../assets/images/illustrations/research.svg"
            class="d-block mx-lg-auto img-fluid"
            alt="Thesis Pro"
            width="500"
            height="400"
            loading="lazy"
          />
        </div>
        <div class="col-lg-6">
          <h1 class="display-5 fw-bold lh-1.5 mb-3">
            Complete Your Individual Academic Thesis
            <strong class="display-5-strong mt-1">Don't loose time!</strong>
          </h1>

          <div class="d-grid gap-2 d-md-flex justify-content-md-start">
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <router-link to="/registor">
                <button class="rounded-pill btn-rounded">
                  START TODAY
                  <span><i class="fas fa-arrow-right"></i></span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- END INTRO SECTION -->

  <!-- FEATURES SECTION -->

  <section id="features" class="our-features">
    <div class="container-fluid">
      <div class="row text-center">
        <h4 class="display-2--intro">Thesis Pro Features</h4>
        <div class="heading-line"></div>
      </div>
    </div>

    <!-- Description content -->
    <div class="container">
      <div class="row pt-2 pb-2 mt-2 mb-1">
        <div class="col-md-12 border-right"></div>
      </div>
    </div>
    <!-- CONTENT FOR FEATURES -->
    <div class="container">
      <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-4">
        <div class="col">
          <div
            class="
              card card-cover
              h-100
              overflow-hidden
              text-white
              bg-white
              rounded-5
            "
          >
            <div
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <div class="feature-box">
                <h2 class="pt-1 mt-1 mb-4 display-6--numbers">1.</h2>
                <span><i class="fas fa-grip-horizontal"></i></span>
              </div>
              <h2 class="pt-1 mt-1 mb-4 display-6--text">
                Empowerment - structure, guidelines and resources
              </h2>
            </div>
          </div>
        </div>

        <div class="col">
          <div
            class="
              card card-cover
              h-100
              overflow-hidden
              text-white
              bg-white
              rounded-5
            "
          >
            <div
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <div class="feature-box">
                <h2 class="pt-1 mt-1 mb-4 display-6--numbers">2.</h2>
                <span><i class="fas fa-globe"></i></span>
              </div>
              <div class="feature-box">
                <h2 class="pt-1 mt-1 mb-4 display-6--text">
                  Self-assess your thesis according to universal criteria/ or
                  add your local university criteria
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div
            class="
              card card-cover
              h-100
              overflow-hidden
              text-white
              bg-none
              rounded-5
            "
          >
            <div
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <div class="feature-box">
                <h2 class="pt-1 mt-1 mb-4 display-6--numbers">3.</h2>
                <span><i class="fas fa-hands-helping mx-1"></i></span>
              </div>
              <h2 class="pt-1 mt-1 mb-4 display-6--text">
                Supporting you to complete your individual academic thesis:
                Bachelor, Master, PhD
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-0">
        <div class="col">
          <div
            class="
              card card-cover
              h-100
              overflow-hidden
              text-white
              bg-white
              rounded-5
            "
          >
            <div
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <div class="feature-box">
                <h2 class="pt-1 mt-1 mb-4 display-6--numbers">4.</h2>
                <span><i class="fas fa-chart-line"></i></span>
              </div>
              <h2 class="pt-1 mt-1 mb-4 display-6--text">
                Self-monitor your progress
              </h2>
            </div>
          </div>
        </div>

        <div class="col">
          <div
            class="
              card card-cover
              h-100
              overflow-hidden
              text-white
              bg-white
              rounded-5
            "
          >
            <div
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <div class="feature-box">
                <h2 class="pt-1 mt-1 mb-4 display-6--numbers">5.</h2>
                <span><i class="fas fa-user-tie"></i></span>
              </div>
              <h2 class="pt-1 mt-1 mb-4 display-6--text">
                Option: Connect your supervisor
              </h2>
            </div>
          </div>
        </div>

        <div class="col">
          <div
            class="
              card card-cover
              h-100
              overflow-hidden
              text-white
              bg-white
              rounded-5
            "
          >
            <div
              class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1"
            >
              <div class="feature-box">
                <h2 class="pt-1 mt-1 mb-4 display-6--numbers">6.</h2>
                <span><i class="fas fa-sticky-note"></i></span>
              </div>
              <h2 class="pt-1 mt-1 mb-4 display-6--text">
                Keep your notes and resources in one place
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- END CONTENT FOR FEATURES -->

  <!-- Introduction -->
  <div class="container col-xxl-12 px-4 py-5">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
      <div class="col-10 col-sm-8 col-lg-6">
        <img
          src="../assets/images/illustrations/intro.svg"
          class="d-block mx-lg-auto img-fluid"
          alt="Bootstrap Themes"
          width="450"
          height="500"
          loading="lazy"
        />
      </div>
      <div class="col-lg-6">
        <h1 class="display-2--intro fw-bold text-uppercase mb-4 text-light">
          Every student needs to finalise a thesis in order to graduate
        </h1>
        <p class="intro-text">
          It can be a stressful period for students. About half the students
          never complete their thesis and many students whom complete are
          delayed for months or even years. Many completed theses are not up to
          international standards.
        </p>
        <p class="intro-text"> 
          In <strong class="fw-bold"> THESIS PRO </strong> you find additional
          resources which help you to complete your work faster and align it to
          high international standards. You can use <strong class="fw-bold">THESIS PRO</strong> as an expanded
          digital coursebook that guides you through the process, connect you to
          relevant guidelines, monitor your progress and shape your thesis with
          correct content and form.
        </p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start"></div>
      </div>
    </div>
  </div>
  <!-- End Introduction -->

  <!-- FAQ-SECTION -->
  <section id="prices" class="get-started">
    <div class="container">
      <div class="row text-center">
        <h1 class="display-2--intro fw-bold text-uppercase text-light">PRICE PLANS</h1>
        <div class="heading-line mb-1"></div>
      </div>
      <div class="row row-cols-1 row-cols-md-3 mt-4 mb-3 text-center">
        <div class="col">
          <div class="card mb-4 rounded-0 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-bold">FREE</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $0<small class="text-muted fw-light"></small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>ONLY FOR 7 DAYS</li>
              </ul>
              <router-link to="/registor">
                <button class="rounded-pill btn-rounded">
                  REGISTER FOR FREE
                  <span><i class="fas fa-arrow-right"></i></span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-0 shadow-sm">
            <div class="card-header--monthly py-3 text-white">
              <h4 class="my-0 fw-bold">6 MONTHS PLAN</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $3.33<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>BILLED AS ONE PAYMENT OF $20</li>
              </ul>

              <router-link to="/registor">
                <button class="rounded-pill btn-rounded">
                  START TODAY
                  <span><i class="fas fa-arrow-right"></i></span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-0 shadow-sm border-primary">
            <div class="card-header--yearly py-3 text-white">
              <h4 class="my-0 fw-bold">YEARLY PLAN</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $3<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>BILLED AS ONE PAYMENT OF $36</li>
              </ul>
              <router-link to="/registor">
                <button class="rounded-pill btn-rounded">
                  START TODAY
                  <span><i class="fas fa-arrow-right"></i></span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
   <the-footer></the-footer>
</template>

<script>
//import SignInForm from "../components/user/SignInForm";
import TheFooter from "../components/layouts/TheFooter.vue";

export default {
  components: {
    TheFooter
    //  SignInForm,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;800;900&display=swap");
</style>