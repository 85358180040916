<template>
  <!--MENU SECTION -->

  <section id="dashbord" class="dashboard-section">
    <div class="container">
      <div class="row">
        <!-- <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/research-development"
            title="R & D"
            iconClass="fas fa-search-plus"
            iconBg="bg-info"
          ></nav-item>
        </div> --> 
        <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/terms-condition"
            title="TERM & COND."
            iconClass="fas fa-copy"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/privacy-terms"
            title="Privacy terms"
            iconClass="fas fa-tasks"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <!-- <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/student-start"
            title="ANTI-PLAGIARISM" 
            iconClass="fas fa-tasks" 
            iconBg="bg-info"
          ></nav-item>
        </div> -->
        <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/user-mgt"
            title="USER"
            iconClass="fas fa-user"
            iconBg="bg-info"
          ></nav-item>
        </div>
         <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/resource-mgt"
            title="RESOURCES"
            iconClass="fas fa-tasks"
            iconBg="bg-info"
          ></nav-item>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/user-payment"
            title="PAYMENTS"
            iconClass="fas fa-tasks"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/faq-mgt"
            title="FAQ"
            iconClass="fas fa-tasks"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/tips-mgt"
            title="Tips"
            iconClass="fas fa-tasks"
            iconBg="bg-info"
          ></nav-item>
        </div>
        <div class="col-xl-3 col-md-6 mb-4">
          <nav-item
            to="/admin/information-guid"
            title="Info. guide"
            iconClass="fas fa-tasks"
            iconBg="bg-info"
          ></nav-item>
        </div> 
      </div>
    </div>
  </section>

  <!-- MENU SECTION END -->
</template>

<script>
import NavItem from "../UI/NavItem.vue";

export default {
  components: {
    NavItem,
  },
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTime:null
    };
  },
  mounted() {
    this.events.forEach((event) => { 
      window.addEventListener(event, this.resetTimer);
    });

    this.setTimer();
  },
  methods: {
    setTimer: function () {
      this.warningTime = setTimeout(this.warningMessage,30 * 60000);
    },
    resetTimer: function () {
      clearTimeout(this.warningTime);
      this.setTimer();
    },
    warningMessage:function(){
      this.$store.dispatch("user/userLoggedOut");
      this.$router.replace("/signin");
    }
  } 
};
</script>
