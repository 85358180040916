<template>
  <!-- THE NAVBAR SECTION -->
  <!-- <container class="intro-section"> -->
  <nav class="navbar navbar-expand-lg navbar-light menu">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo.svg" alt="Thesis Pro" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link active">HOME</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- </container> -->
  <!-- THE NAVBAR SECTION END -->
  <!-- SIGNIN SECTION -->

  <div class="container-fluid">
    <div class="my-alerts">
      <div class="alert alert-info alert-dismissible fade show" role="alert">
        User account created successfully, A email has sent to your email
        address for create password
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
  <!-- <h2>User payments</h2> -->
  <!-- <h2>{{ id }}</h2> -->
  <section id="prices" class="get-started">
    <div class="container">
      <div class="row text-center">
        <h1 class="display-2--intro fw-bold text-uppercase">PRICE PLANS</h1>
        <!-- <div class="heading-line"></div> -->
        <p class="lead mt-2">Select your price plan</p>
      </div>
      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
        <div class="col">
          <div class="card mb-4 rounded-0 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-bold">FREE</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $0<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>ONLY FOR 7 DAYS</li>
              </ul>
              <button
                type="button"
                class="rounded-pill btn-rounded"
                @click="getFreePackage"
              >
                SIGN UP FOR FREE
                <span><i class="fas fa-arrow-right"></i></span>
              </button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-0 shadow-sm">
            <div class="card-header--monthly py-3 text-white">
              <h4 class="my-0 fw-bold">6 MONTHS PLAN</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $3.33<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>BILLED AS ONE PAYMENT OF $20</li>
              </ul>
              <stripe-pay-bytton
                :userId="id"
                :productName="'6 MONTHS PLAN'"
                :price="20"
                :productId="10"
                paymentPackage="_06_MONTHS"
              ></stripe-pay-bytton>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-0 shadow-sm border-primary">
            <div class="card-header--yearly py-3 text-white">
              <h4 class="my-0 fw-bold">YEARLY PLAN</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                $3<small class="text-muted fw-light">/mo</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>BILLED AS ONE PAYMENT OF $36</li>
              </ul>
              <stripe-pay-bytton
                :userId="id"
                :productName="'YEARLY PLAN'"
                :price="36"
                :productId="20"
                paymentPackage="_12_MONTHS"
              ></stripe-pay-bytton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <the-footer></the-footer>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";

import StripePayBytton from "../../components/user/payments/StripePayBytton.vue";
import { backendURL } from "../../app-constants";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";
export default {
  props: ["id"],
  components: {
    StripePayBytton,
    BaseSpinner,
    TheFooter,
  },
  setup(props) {
    const isLoading = ref(false);
    const router = useRouter();

    const getFreePackage = async () => {
      try {
        //isLoading.value = true;

        const response = await fetch(
          backendURL + "/api/auth/addFreeAccount/" + props.id,
          {
            method: "GET",
            headers: {
              //   Authorization: "Bearer " + token,
              //"Content-Type": "application/json",
            },
          }
        );

        const resposeData = await response.json();
        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error Get free package"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          if (resposeData.data != null) {
            router.replace("/payments-success/" + resposeData.data);
          } else {
            alert("Your Free package is over");
          }
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    return {
      isLoading,
      getFreePackage,
    };
  },
};
</script>
