<template>
  <!-- THE NAVBAR SECTION -->
  <container class="intro-section">
    <nav class="navbar navbar-expand-lg navbar-light menu">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../../../assets/images/logo.svg" alt="Thesis Pro" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link active">HOME</router-link>
            </li>
          </ul>

          <router-link to="/registor" class="rounded-pill btn-rounded">
            REGISTER
            <span><i class="fas fa-user"></i></span>
          </router-link>
        </div>
      </div>
    </nav>
  </container>
  <!-- THE NAVBAR SECTION END -->
  <base-spinner v-if="isLoading"></base-spinner>
</template>
<script>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  backendURL,
  isUserPaymentActivated,
  UserRoles,
  linkedinClientId,
  linkedinClientSecret,
  appURL,
} from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";

export default {
  components: {
    BaseSpinner,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const isLoading = ref(false);

    onMounted(() => {
      const code = route.query.code;
      //const state = route.query.state;
      processLinkedINLogin(code);
    });

    const processLinkedINLogin = async (code) => {
      isLoading.value = true;

      const jsonData = JSON.stringify({
        theCode: code,
        linkedinClientId: linkedinClientId,
        linkedinClientSecret: linkedinClientSecret,
        redirectURL: appURL + "/linkedin-success",
      });

      try {
        const response = await fetch(
          backendURL + "/api/auth/processLinkedInUser",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: jsonData,
          }
        );

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error Loging with LinedIn"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;

          const signUpExternal = {
            email: resposeData.data.emailAddress,
            firstName: resposeData.data.firstName,
            lastName: resposeData.data.lastName,
            imageUrl: resposeData.data.imageUrl,
            role: localStorage.getItem("extRole"),
            token: localStorage.getItem("extToken"),
          };
          localStorage.removeItem("extToken");
          localStorage.removeItem("extRole");

          signInUser(signUpExternal);
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
      }
    };

    const signInUser = async (paylod) => {
      //isLoading.value = true;
      try {
        await store.dispatch("user/loginExternal", paylod);
        const loggedUserRole = store.getters["user/loggedUserRole"];
        if (isUserPaymentActivated && loggedUserRole == UserRoles.STUDENT) {
          checkUserPayments(loggedUserRole);
        } else {
          isLoading.value = false;
          goToSystem(loggedUserRole);
        }
      } catch (error) {
        isLoading.value = false;
        alert("message - 02 " + error.message);
      }
    };

    const checkUserPayments = async (loggedUserRole) => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/validateUserPayment/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          if (resposeData.data) {
            isLoading.value = false;
            goToSystem(loggedUserRole);
          } else {
            isLoading.value = false;
            alert(resposeData.message);
            router.replace("/payments/" + userId);
          }
        } else {
          alert(resposeData.message);
        }
      }
      isLoading.value = false;
    };

    const goToSystem = async (loggedUserRole) => {
      await store.dispatch("user/updateNotification");
      if (loggedUserRole == UserRoles.SYS_ADMIN) {
        router.replace("/admin");
      } else if (loggedUserRole == UserRoles.STUDENT) {
        router.replace("/student");
      } else if (loggedUserRole == UserRoles.SUPERVISOR) {
        router.replace("/supervisor");
      }
    };

    return {
      isLoading,
    };
  },
};
</script>
