<template>
  <notifications position="top center"></notifications>
  <div class="album py-1 bg-none">
    <div class="container">
      <div class="help-guide">
        <h4 class="main-heading text-start">My notes</h4>
        <div class="float-end">
          <Canvace :page="page" :iconId="1"></Canvace>
        </div>
      </div>
    </div>

    <div class="container">
      <div
        class="
          row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1
          g-3 
        "
      >
        <div class="col">
          <div class="user-credentials-boxes shadow-sm">
            <div>
              <h6 class="notes--header">ADD YOUR NOTE HERE</h6>
            </div>
            <form @submit.prevent="saveNote">
              <div>
                <div>
                  <div
                    class="col-12"
                    :class="{ errors: !noteData.title.isValid }"
                  >
                    <label for="title" class="form-label"
                      >Title <span class="text-muted"></span
                    ></label>
                    <input
                      type="hidden"
                      id="nId"
                      name="nId"
                      v-model="noteData.id.val"
                    />
                    <input
                      type="text"
                      id="title"
                      name="title"
                      class="form-control"
                      v-model="noteData.title.val"
                      @blur="clearValidity('title')"
                    />
                    <div class="error-message">Please enter title.</div>
                  </div>

                  <div
                    class="col-12 mt-2"
                    :class="{ errors: !noteData.notes.isValid }"
                  >
                    <label class="form-label"
                      >Note <span class="text-muted"></span
                    ></label>
                    <quill-editor v-model:value="noteData.notes.val" />
                    <div class="error-message">Please enter note.</div>
                  </div>

                  <div class="col-12 mt-1">
                    <div>
                      <button class="btn-task--success me-md-2">Save</button>
                    </div>
                    <p v-if="!formIsValid" class="comman-error">
                      {{ invalidmessage }}
                    </p>
                    <small class="notes--date"></small>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
 

 <div class="album py-3 bg-none">
    <div class="container">
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 g-3">
        <div class="col" v-for="n in allnotes" :key="n.id">
          <div class="col d-flex flex-wrap align-content-start shadow-sm">
            <div class="card flex-wrap">
              <div class="card-header">
                <div class="notes-header">
                  <h5 class="notes--title">
                    {{ n.title }}
                  </h5>
                  <i
                    class="fas fa fa-trash mt-2"
                    @click="deleteNote(n.id)"
                  ></i>
                </div>
              </div>

              <div class="card-body pb-2">
                <div class="card-text">
                  <div
                    :class="addClassId === n.id ? addClass : 'hidi'"
                    v-html="n.notes"
                  ></div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group mt-2">
                    <button
                      type="button"
                      class="btn btn-sm btn-info mb-2"
                      @click="addExpandClass(n.id)"
                    >
                      more
                    </button>

                    <button
                      type="button"
                      class="btn btn-sm btn-warning mb-2"
                      @click="getNote(n.id)"
                    >
                      Edit
                    </button>
                  </div>

                  <small class="notes--date">{{ n.enterDate }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <base-spinner v-if="isLoading"></base-spinner>
  </div> 
  
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { backendURL } from "../../../app-constants";
import { notify } from "@kyvg/vue3-notification";
import { useStore } from "vuex";
import BaseSpinner from "../../../components/UI/BaseSpinner.vue";
import { quillEditor } from "vue3-quill";
import Canvace from "../../canvasComponent/OffCanvace.vue";
export default {
  components: { BaseSpinner, quillEditor, Canvace },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const formIsValid = ref(true);
    const allnotes = ref([]);
    const invalidmessage = ref(null);
    const noteData = reactive({
      id: { val: "0", isValid: true },
      title: { val: "", isValid: true },
      notes: { val: "", isValid: true },
      student: { val: null, isValid: true },
      enterDate: { val: new Date(), isValid: true },
    });

    const addClass = ref("hidi");
    const addClassId = ref("0");
    const page = "note";

    onMounted(() => {
      loadAllNote();
    });

    function clearValidity(input) {
      noteData[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;
      if (!noteData.title.val || noteData.title.val == "") {
        noteData.title.isValid = false;
        formIsValid.value = false;
      }
      if (noteData.notes.val == "") {
        noteData.notes.isValid = false;
        formIsValid.value = false;
      }
    }

    async function saveNote() {
      //isLoading.value = true;
      validateFrom();
      if (!formIsValid.value) {
        isLoading.value = false;
        invalidmessage.value = "Required  fields missing";
        return;
      }
      const userId = store.getters["user/loggedUserId"];
      const token = store.getters["user/token"];
      var object = {
        id: noteData.id.val,
        title: noteData.title.val,
        notes: noteData.notes.val,
        student: { id: userId },
        enterDate: new Date(),
      };

      fetch(backendURL + "/api/auth/createNotes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(object),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            this.allNotes = res.data;
            noteData.id.val = "0";
            noteData.title.val = "";
            noteData.notes.val = "";
            loadAllNote();
            notify({
              type: "success",
              title: "SUCCESS",
              text: "Successfully saved!",
            });
          } else {
            notify({
              type: "error",
              title: "ERROR",
              text: "Something went wrong!",
            });
          }
        });
    }

    const loadAllNote = async () => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      fetch(backendURL + "/api/auth/getAllNotes/" + userId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          allnotes.value = res.data;
        });
    };

    const getNote = async (id) => {
      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/getNoteById/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          noteData.id.val = res.data.id;
          noteData.title.val = res.data.title;
          noteData.notes.val = res.data.notes;
        });
    };

    const addExpandClass = async (id) => {
      addClassId.value = id;
      addClass.value === "hidi"
        ? (addClass.value = "expandContent")
        : (addClass.value = "hidi");
    };

    const deleteNote = async (id) => {
      var object = {
        id: id,
      };

      const token = store.getters["user/token"];
      fetch(backendURL + "/api/auth/deleteNotes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(object),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.valid) {
            loadAllNote();
            notify({
              type: "error",
              title: "SUCCESS",
              text: "Successfully deleted!",
            });
          } else {
            notify({
              type: "error",
              title: "ERROR",
              text: "Something went wrong!",
            });
          }
        });
    };

    return {
      allnotes,
      noteData,
      saveNote,
      clearValidity,
      invalidmessage,
      loadAllNote,
      addClass,
      addExpandClass,
      addClassId,
      getNote,
      page,
      deleteNote,
    };
  },
};
</script>
<style lang="scss" scoped>
.hidi {
  height: 200px !important;
  overflow: auto;
}
.expandContent {
  cursor: pointer;
  height: auto;
}
</style>
