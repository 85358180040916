<template>
  <button class="btn-task--success me-md-2" type="button" @click="toWordFile">
    To MS WORD <i class="fas fa-file-word"></i>
  </button>
  <img
    v-if="showProgress"
    src="@/assets/images/loding1.gif"
    alt="sdg"
    width="50"
    height="50"
    class="mt-10 shadow-sm"
  />
  <span class="thesis-icons--success" v-if="showSuccess">
    <i class="fas fa-check lg" style="color:rgb(41 212 22);"></i>
  </span>
</template>

<script>
import { ref, watch } from "vue";
import { saveAs } from "file-saver";
import * as quillToWord from "quill-to-word";

const quillToWordConfig = {
  exportAs: "blob",
};

export default {
  props: ["quill", "sectionTitle"],
  setup(props) {
    const showProgress = ref(false);
    const showSuccess = ref(false);
    const filename = ref("");

    const toWordFile = async () => {
      showProgress.value = true;
      showSuccess.value = false;

      const docAsBlob = await quillToWord.generateWord(
        props.quill.getContents(),
        quillToWordConfig
      );
      saveAs(docAsBlob, filename.value);
      showProgress.value = false;
      showSuccess.value = true;
    };

    watch(
      () => props.sectionTitle,
      (value) => {
        filename.value = value + ".docx";
      }
    );

    return {
      showProgress,
      showSuccess,
      toWordFile,
    };
  },
};
</script>
