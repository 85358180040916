<template>
  <button
    type="button"
    class="rounded-pill btn-rounded"
    @click="goToCheckout()"
  >
    START TODAY
    <span><i class="fas fa-arrow-right"></i></span>
  </button>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { onMounted, ref } from "vue";
//import { useStore } from "vuex";
// import LoadScript from "vue-plugin-load-script";

import { backendURL, stripeAPIToken } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";
export default {
  props: ["userId", "productName", "price", "productId", "paymentPackage"],
  components: {
    BaseSpinner,
  },
  setup(props) {
    //const store = useStore();
    const isLoading = ref(false);
    const stripe = ref("");

    onMounted(async () => {
      includeStripe(
        "js.stripe.com/v3/",
        function() {
          configureStripe();
        }.bind(this)
      );
    });

    const includeStripe = (URL, callback) => {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "https://" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    };

    const configureStripe = () => {
      stripe.value = window.Stripe(stripeAPIToken);
    };

    const goToCheckout = async () => {
      try {
        // const token = store.getters["user/token"];
        //const userId = store.getters["user/loggedUserId"];
        //isLoading.value = true;
        const checkoutData = {
          productName: props.productName,
          quantity: 1,
          price: props.price,
          productId: props.productId,
          userId: props.userId,
          paymentPackage: props.paymentPackage,
        };

        const response = await fetch(
          backendURL + "/api/auth/create-checkout-session",
          {
            method: "POST",
            headers: {
              //   Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(checkoutData),
          }
        );

        const resposeData = await response.json();
        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error Checkout payment"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          return stripe.value.redirectToCheckout({
            sessionId: resposeData.data.sessionId,
          });
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    return {
      isLoading,
      stripe,
      configureStripe,
      goToCheckout,
    };
  },
};
</script>
