<template>
  <div class="alert alert-success" role="alert" v-show="jsonSuc">
    Succesfully saved!
  </div>
  <div class="alert alert-danger" role="alert" v-show="jsonErr">
    Something went wrong!
  </div>
  <div class="container">
    <div class="row">
      <div class="my-progress-boxes">
        <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-10">
          <form autocomplete="off" class="row" @submit.prevent="saveInfoGuid">
            <div class="col-12" :class="{ errors: pageValidation }">
              <label class="form-label">Select page</label>
              <select
                class="form-select"
                aria-label="Default select example"
                v-model="page"
              >
                <option
                  v-for="option in options"
                  v-bind:value="option.value"
                  :key="option.id"
                >
                  {{ option.text }}
                </option>
              </select>
              <div class="error-message">Please select page.</div>
            </div>
            <div class="col-12" :class="{ errors: iconKeyValidation }">
              <label for="quest" class="form-label"
                >Icon id <span class="text-muted"></span
              ></label>
              <input type="hidden" id="rid" name="rid" v-model="resId" />
              <input
                type="text"
                id="id"
                name="id"
                class="form-control"
                v-model="id"
              />
              <div class="error-message">Please enter icon id.</div>
            </div>
            <div class="my-progress-boxes">
              <div class="col-12 mt-2" :class="{ errors: descValidation }">
                <quill-editor v-model:value="desc" />
                <div class="error-message">Please enter description.</div>
              </div>
            </div>

            <div class="col-12 mt-2">
              <button class="btn-task--success me-md-2 mb-3" type="submit">
                SAVE <i class="fas fa-save"></i>
              </button>
              <button class="btn-task--danger me-md-2 mb-3" type="button">
                CANCEL <i class="fas fa-window-close"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row my-4">
      <div class="my-progress-boxes">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Page</th>
              <th>Icon id</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="info in listOfInfo" :key="info.id">
              <td>{{ info.pageName }}</td>
              <td>{{ info.iconKey }}</td>
              <td>{{ info.description }}</td>
              <td><button @click="viewInfo(info.id)">View</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { quillEditor } from "vue3-quill";
import { backendURL } from "../../../app-constants";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      options: [
        { id: 1, text: "My progress", value: "progress" },
        { id: 2, text: "Thesis writing", value: "thesis" },
        { id: 3, text: "Library", value: "library" },
        { id: 4, text: "Calender", value: "calender" },
        { id: 5, text: "Web resources", value: "websource" },
        { id: 6, text: "Tips & guid", value: "tips" },
        { id: 7, text: "Resource", value: "resource" },
        { id: 8, text: "Own notes", value: "note" },
      ],
      id: "",
      desc: "",
      listOfInfo: [],
      jsonSuc: false,
      jsonErr: false,
      formIsValid: false,
      iconKeyValidation: false,
      pageValidation: false,
      descValidation: false,
      resId: "",
      page: "",
    };
  },
  methods: {
    validateFrom: function () {
      this.formIsValid = true;
      if (this.id == "") {
        this.iconKeyValidation = true;
        this.formIsValid = false;
      }
      if (this.page == "") {
        this.pageValidation = true;
        this.formIsValid = false;
      }
      if (this.desc == "") {
        this.descValidation = true;
        this.formIsValid = false;
      }
    },

    saveInfoGuid: function () {
      this.validateFrom();
      if (this.formIsValid) {
        this.iconKeyValidation = false;
        this.pageValidation = false;
        this.descValidation = false;

        var object = {
          id: this.resId,
          iconKey: this.id,
          pageName: this.page,
          description: this.desc,
        };
        this.token = this.$store.getters["user/token"];
        fetch(backendURL + "/api/auth/information/saveInformationGuid", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
          },
          body: JSON.stringify(object),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              this.resId = "";
              this.page = "";
              this.id = "";
              this.desc = "";

              this.listOfInfo = res.data;
              this.jsonSuc = true;
            } else {
              this.jsonErr = true;
            }
          });
      }
    },
    viewInfo: function (id) {
      let token = this.$store.getters["user/token"];
      fetch(backendURL + "/api/auth/information/getInformationGuidById/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.resId = "";
          this.page = "";
          this.id = "";
          this.desc = "";

          this.resId = res.data.id;
          this.page = res.data.pageName;
          this.id = res.data.iconKey;
          this.desc = res.data.description;
        });
    },
  },
  mounted() {
    this.token = this.$store.getters["user/token"];
    fetch(backendURL + "/api/auth/information/getAllInfo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.listOfInfo = res.data;
      });
  },
};
</script>
