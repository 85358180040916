<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="exampleModal"
  >
    <div class="modal-dialog" :class="modelClasses">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeDialog"
              v-if="!hideTitleClose"
            ></button>
          </slot>
        </div>
        <div class="modal-body">
          <slot> </slot>
        </div>
        <div class="modal-footer justify-content-start">
          <slot name="actions"> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  emits: ["close-dialog"],
  props: {
    title: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      required: false,
      /*modal-xl, modal-lg, modal-sm, modal-fullscreen, etc. */
    },
    hideTitleClose: {
      type: Boolean,
      required: false,
      default: false,
      /*modal-xl, modal-lg, modal-sm, modal-fullscreen, etc. */
    },
  },
  data() {
    return {
      showModal: false,
      modal: null,
      modelOptions: {
        keyboard: false,
        backdrop: "static",
      },
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
  computed: {
    modelClasses: function() {
      const classArray = new Array();
      classArray.push(this.size);

      return classArray.join(" ");
    },
  },
  watch: {
    show: function(val) {
      if (val) {
        this.modal.show();
        this.showModal = true;
      } else {
        this.modal.hide();
        this.showModal = false;
      }
    },
  },

  mounted() {
    this.modal = new Modal(this.$refs.exampleModal, this.modelOptions);
  },
};
</script>
