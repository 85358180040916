<template>
  <div class="my-progress-boxes shadow-none" style="box-shadow: none !important;">
    <notifications position="top center"></notifications>
    <div class="row row-cols-sm-12">
      <!-- Filters -->
      <div class="col-xxl-3 col-xl-3 order-xl-1 mb-4 d-none d-sm-block">
        <student-resource-filter
          @filter-resources="filterResources"
        ></student-resource-filter>
      </div>
      <!-- Resource list -->

      <div class="col-xxl-9 col-xl-9 order-xl-1 mb-4">
        <student-resource-list-block
          v-for="resource in resourceFiltersList"
          :key="resource.id"
          :resource="resource"
          :libraryList="libraryList"
          @update-my-library="updateMyLibray"
        ></student-resource-list-block>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import StudentResourceListBlock from "./StudentResourceListBlock.vue";
import StudentResourceFilter from "./StudentResourceFilter.vue";
import { backendURL } from "../../../app-constants";
import { notify } from "@kyvg/vue3-notification";
export default {
  components: {
    StudentResourceListBlock,
    StudentResourceFilter,
  },
  setup() {
    const store = useStore();
    const resourceList = ref([]);
    const resourceFiltersList = ref([]); 
    const libraryList = ref([]);

    const updateMyLibray = (Obj) => {
      if (Obj.myLibObj.isInbibray) {
        const token = store.getters["user/token"]; 
        fetch(backendURL + "/api/auth/resource/deleteMyResource", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            id: Obj.myLibObj.id,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.valid) {
              notify({
                type: "success",
                title: "SUCCESS",
                text: "Removed from my library!",
              });
              loadAllMyResource();
            }
          });
      } 
    };

    const filterResources = (filter) => { 
      let result = resourceList.value;
      if (filter.year) {
        let thisYear = new Date().getFullYear();
        let lastYear = thisYear - 1;
        if (filter.year == 0) {
          result = result.filter(
            (res) => res.dateTime.split("-")[0] == thisYear
          );
        }
        if (filter.year == -1) {
          result = result.filter(
            (res) => res.dateTime.split("-")[0] == lastYear
          );
        }
        if (filter.year == -2) {
          result = result.filter(
            (res) => res.dateTime.split("-")[0] < lastYear
          );
        }
      }
      if (filter.openAccess && filter.openAccess.length > 0) {
        result = result.filter(
          (res) => filter.openAccess.indexOf(res.publicAccess) != -1
        );
      }
      if (filter.sdg && filter.sdg.length > 0) {
        result = result.filter((res) =>
          checkSDGContains(res.sdgList, filter.sdg)
        );
      }
      resourceFiltersList.value = [];
      for (let i = 0; i < result.length; i++) {
        resourceFiltersList.value.push(result[i]);
      }
    };

    onMounted(() => {
      loadAllMyResource();
    });

    const loadAllMyResource = async () => {
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      fetch(
        backendURL + "/api/auth/resource/listAllResourceByUserId/" + userId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resourceList.value = res.data;
          resourceFiltersList.value = resourceList.value;

          for (var j = 0; j < resourceList.value.length; j++) {
            libraryList.value.push(resourceList.value[j].resourceId);
          }
        });
    };

    return {
      libraryList,
      updateMyLibray,
      filterResources,
      resourceFiltersList,
    };
  },
};

function checkSDGContains(resourceSDG, filterSDG) {
  for (let i = 0; i < resourceSDG.length; i++) {
    for (let j = 0; j < filterSDG.length; j++) {
      if (resourceSDG[i].id == filterSDG[j]) {
        return true;
      }
    }
  }
  return false;
}
</script>
