<template>
  <div class="my-progress-boxes">
    <div class="writing-content">
      <h5 class="writing-content--title">
        {{ sectionTitle }}
      </h5>
    </div>

    <div class="mb-3">
      <label for="exampleFormControlTextarea1" class="form-label"
        >Write your thesis section here</label
      >

      <component v-if="dynamicComponent" :is="dynamicComponent"></component>
      <quill-editor
        v-model:value="state.content"
        :options="state.editorOption"
        :disabled="state.disabled"
        @blur="onEditorBlur($event)"
        @ready="onEditorReady($event)"
        @change="onEditorChange($event)"
      />
    </div>
    <div>
      <button
        class="btn-task--success me-md-2"
        type="button"
        @click="saveCommnet"
      >
        SAVE <i class="fas fa-save"></i>
      </button>
      <button
        class="btn-task--warning me-md-2"
        type="button"
        @click="displayWithSuperviorDialog"
        :disabled="!isSave"
      >
        Share <i class="fas fa-share"></i>
      </button>
      <convert-to-word
        :sectionTitle="sectionTitle"
        :quill="quillObj"
      ></convert-to-word>

      <email-section-word
        :sectionTitle="sectionTitle"
        :quill="quillObj"
        :sectionId="sectionId"
      ></email-section-word>

      <mark-section
        :sectionId="sectionId"
        :sectionStatus="sectionStatus"
        @reload-section="loadDetails"
      ></mark-section>
    </div>
    <p class="thesis-save" v-show="!isSave">Click <strong class="thesis-save--save">save </strong>before share</p>
    <p v-if="successMessage" class="thesis-save">{{ successMessage }}</p>
  </div>
  <router-link
    to="/student/thesis-writing"
    class="btn-task--success me-md-2 text-light"
  >
    <i class="fas fa-share fa-flip-horizontal"></i>
    Back to sections
  </router-link>
  <!-- share input dialog -->
  <base-dialog
    :show="showModal"
    title="Message to Supervisor"
    @close-dialog="closeDialog"
  >
    <template #default>
      <div>
        <div class="col-12">
          <label for="userName" class="form-label"> Type a message </label>
          <textarea
            class="form-control"
            id="sahreMessage"
            name="sahreMessage"
            v-model.trim="sahreMessage"
            rows="3"
          ></textarea>
        </div>
      </div>
    </template>
    <template #actions>
      <button
        class="btn-task--success me-md-2"
        type="button"
        @click="sahreWithSupervior"
      >
        Share <i class="fas fa-share"></i>
      </button>
      <button
        class="btn-task--danger me-md-2"
        type="button"
        data-bs-dismiss="modal"
        @click="showModal = false"
      >
        CLOSE <i class="fas fa-times-circle"></i>
      </button>
    </template>
  </base-dialog>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { quillEditor } from "vue3-quill";

import ConvertToWord from "./ConvertToWord.vue";
import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";
import MarkSection from "./MarkSection.vue";
import EmailSectionWord from "./EmailSectionWord.vue";

export default {
  components: {
    quillEditor,
    ConvertToWord,
    BaseSpinner,
    MarkSection,
    EmailSectionWord,
  },
  props: ["sectionId"],
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);

    const state = reactive({
      // dynamicComponent: null,
      content: "",
      _content: "",
      editorOption: {
        placeholder: "Enter Text",
        modules: {
          // toolbar: [
          // custom toolbars options
          // will override the default configuration
          // ],
          // other moudle options here
        },
        // more options
      },
      disabled: false,
    });

    const showModal = ref(false);
    const sahreMessage = ref("");
    const successMessage = ref(null);
    const sectionTitle = ref("");
    const quillObj = ref(null);
    const isSave = ref(false);
    const sectionStatus = ref(null);

    const onEditorBlur = (quill) => {
      quillObj.value = quill;
    };
    const onEditorReady = (quill) => {
      isSave.value = true;
      quillObj.value = quill;
    };

    const onEditorChange = ({ quill, html, text }) => {
      isSave.value = false;
      console.log("editor change!", quill, html, text);
      //state._content = html
    };

    const saveCommnet = async () => {
      isSave.value = true;
      //isLoading.value = true;
      const token = store.getters["user/token"];

      try {
        const response = await fetch(
          backendURL + "/api/updateThesisSectionWrittenDetails",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
              sectionId: props.sectionId,
              writtenDetails: state.content,
            }),
          }
        );

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error save section data"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          successMessage.value = "Section data successfully saved";
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        successMessage.value = error.message;
      }
    };

    const displayWithSuperviorDialog = () => {
      showModal.value = true;
      successMessage.value = null;
    };
    const sahreWithSupervior = async () => {
      showModal.value = false;
      //isLoading.value = true;
      const token = store.getters["user/token"];

      try {
        const response = await fetch(
          backendURL + "/api/shareThesisSectionWithSupervisor",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
              sectionId: props.sectionId,
              shareNote: sahreMessage.value,
            }),
          }
        );

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(resposeData.message || "Error Share section");
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          successMessage.value = "Section successfully shared with supervisor";
          sahreMessage.value = "";
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        successMessage.value = error.message;
      }
    };

    const closeDialog = () => {
      showModal.value = false;
    };

    const loadDetails = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const response = await fetch(
        backendURL + "/api/getThesisSectionWritingDetails/" + props.sectionId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          state.content = resposeData.data.details;
          sectionTitle.value = resposeData.data.title;
          sectionStatus.value = resposeData.data.sectionStatus;
        }
      }
      isLoading.value = false;
    };

    onMounted(() => {
      loadDetails();
    });

    return {
      state,
      saveCommnet,
      sahreWithSupervior,
      showModal,
      closeDialog,
      displayWithSuperviorDialog,
      sahreMessage,
      successMessage,
      sectionTitle,
      onEditorBlur,
      quillObj,
      onEditorReady,
      onEditorChange,
      isSave,
      isLoading,
      sectionStatus,
      loadDetails,
    };
  },
};
</script>
