<template>
  <!-- THE NAVBAR SECTION -->
  <container class="intro-section">
    <nav class="navbar navbar-expand-lg navbar-light menu">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../../assets/images/logo.svg" alt="Thesis Pro" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link active">HOME</router-link>
            </li>
          </ul>

          <router-link to="/registor" class="rounded-pill btn-rounded">
            REGISTER
            <span><i class="fas fa-user"></i></span>
          </router-link>
        </div>
      </div>
    </nav>
  </container>
  <!-- THE NAVBAR SECTION END -->

  <!-- SIGNIN SECTION -->

  <section id="contact">
    <div class="container">
      <div class="row text-center">
        <h2 class="login-text text-start mb-0">LOG IN TO THESIS PRO</h2>
        <p class="lead mt-0 text-start text-light">
          Don't have an account yet?
          <router-link to="/registor"> REGISTER </router-link>
        </p>
      </div>
      <!--  <div class="row text-center">
        <h1 class="display-2--intro fw-bold text-uppercase">SIGN IN</h1> 
        <p class="lead mt-2">Sign in to your thesis pro account</p>
      </div> -->

      <!-- SIGNIN FORM -->
      <div class="row">
        <div class="col-12 col-lg-6 p-3 d-none d-sm-block">
          <div class="login-info w-100">
            
            <img src="../../assets/images/illustrations/sign_in.svg" class="d-block mx-lg-auto img-fluid" alt="Thesis Pro" width="500" height="400" loading="lazy">
          </div>
        </div>
        <div class="col-12 col-lg-6 bg-white shadow p-3">
          <sign-in-form :role="userRole"></sign-in-form>
        </div>
        <!-- END SIGNIN FORM -->
      </div>
    </div>
  </section>
  <!--  END CONTACT US/LOGIN SECTION -->
  <the-footer></the-footer>
</template>

<script>
import SignInForm from "../../components/user/SignInForm.vue";
import { UserRoles } from "../../app-constants";
import TheFooter from "../../components/layouts/TheFooter.vue";

export default {
  components: {
    SignInForm, 
    TheFooter
  },
  computed: {
    userRole() {
      return UserRoles.STUDENT;
    },
  },
  methods: {
    mounted() {
      this.$gAuth.signOut();
    },
  },
};
</script>
