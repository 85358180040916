<template>
  <!-- COMMUNICATION WITH SUPERVISOR -->

  <div
    class="my-progress-boxes shadow-none"
    style="box-shadow: none !important;"
  >
    <div class="writing-content">
      <h5 class="writing-content--title">
        LATEST COMMUNICATION
      </h5>
    </div>
    <div v-if="!hasSupervior" class="thesis-about--supervisor mb-0">
      Select or invite supervisor.
      <router-link
        to="/student/thesis-complete"
        class="btn-task--notifications btn-sm me-md-2"
      >
        <i class="fas fa-plus"></i>
      </router-link>
    </div>
    <div
      v-if="hasSupervior"
      class="forum mb-3"
      :class="{ errors: !messageObj.message.isValid }"
    >
      <div class="mb-1">
        <label for="message" class="form-label">Your message/ reply</label>
        <textarea
          class="form-control"
          rows="4"
          id="message"
          name="message"
          v-model.trim="messageObj.message.val"
          @blur="clearValidity('message')"
        ></textarea>
        <div class="error-message">
          Please enter a comment.
        </div>
      </div>
      <div>
        <button
          class="btn-task--success me-md-2 mb-3"
          type="button"
          @click="addComment"
        >
          SEND <i class="fas fa-paper-plane"></i>
        </button>
      </div>

      <!-- scorall -->
      <div
        class="overflow-auto p-0 mb-3 mb-md-0 me-md-3 bg-none"
        style="max-width:  auto; max-height: 300px;"
      >
        <forum-block
          v-for="comment in commentList"
          :key="comment.id"
          :isStudent="isStudent"
          :comment="comment"
          @refresh-froum="refreash"
        ></forum-block>
      </div>
    </div>
  </div>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { reactive, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
//import moment from "moment";

import { backendURL } from "../../app-constants";
import BaseSpinner from "../UI/BaseSpinner.vue";
import ForumBlock from "./ForumBlock.vue";

export default {
  components: {
    ForumBlock,
    BaseSpinner,
  },
  props: {
    isStudent: {
      type: Boolean,
      default: true,
    },
    thesisId: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const messageObj = reactive({ message: { val: "", isValid: true } });
    const hasSupervior = ref(false);

    function clearValidity(input) {
      messageObj[input].isValid = true;
    }

    function validateFrom() {
      if (messageObj.message.val == "") {
        messageObj.message.isValid = false;
      }
    }

    const addComment = async () => {
      validateFrom();
      if (!messageObj.message.isValid) {
        return;
      }

      try {
        //isLoading.value = true;
        const token = store.getters["user/token"];
        const userId = store.getters["user/loggedUserId"];

        const dataObj = {
          thesisId: props.thesisId,
          userId: userId,
          message: messageObj.message.val,
        };

        const response = await fetch(backendURL + "/api/addForumComment/", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...dataObj,
          }),
        });
        const resposeData = await response.json();
        if (!response.ok) {
          const error = new Error(resposeData.message || "Error add comment");
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          messageObj.message.val = "";
          loadForum(props.thesisId);
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };
    const commentList = ref([]);

    const loadForum = async (userThesisId) => {
      if (userThesisId) {
        //isLoading.value = true;
        const token = store.getters["user/token"];
        const userId = store.getters["user/loggedUserId"];
        const response = await fetch(backendURL + "/api/getCommonForum", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            thesisId: userThesisId,
          }),
        });
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            isLoading.value = false;
            commentList.value = resposeData.data;
          }
        }
        isLoading.value = false;
      }
    };

    const chackHasSupervisor = async (userThesisId) => {
      if (userThesisId) {
        const token = store.getters["user/token"];
        const response = await fetch(
          backendURL + "/api/hasSupervisor/" + userThesisId,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (response.ok) {
          if (resposeData.valid) {
            hasSupervior.value = resposeData.data;
          }
        }
      }
    };

    const refreash = () => {
      loadForum(props.thesisId);
    };

    onMounted(() => {
      chackHasSupervisor(props.thesisId);
      loadForum(props.thesisId);
    });

    watch(
      () => props.thesisId,
      (value) => {
        chackHasSupervisor(value);
        loadForum(value);
      }
    );

    return {
      commentList,
      clearValidity,
      addComment,
      messageObj,
      isLoading,
      hasSupervior,
      refreash,
    };
  },
};
</script>
