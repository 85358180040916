<template>
  <!-- THE NAVBAR SECTION -->
  <!-- <container class="intro-section"> -->
  <nav class="navbar navbar-expand-lg navbar-light menu">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo.svg" alt="Thesis Pro" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link active">HOME</router-link>
          </li>
        </ul>
        <router-link to="/signin" class="rounded-pill btn-rounded">
          SIGN IN
          <span><i class="fas fa-user"></i></span>
        </router-link>
      </div>
    </div>
  </nav>
  <!-- </container> -->
  <!-- THE NAVBAR SECTION END -->
  <!-- SIGNIN SECTION -->

  <section id="contact" class="login">
    <div class="container">
      <div class="row text-center">
        <h2 class="login-text text-start mb-0">Forgot your password?</h2>

        <p class="lead mt-0 text-start text-light">
          Don’t worry. Request a new one.
          <!-- <router-link to="/signin"> SIGN IN </router-link> -->
        </p>
      </div>

      <!-- SIGNIN FORM -->
      <div class="row">
        <div class="col-12 col-lg-6 p-3 d-none d-sm-block">
          <div class="login-info w-100">
            <img
              src="../../assets/images/illustrations/forget_pw.svg"
              class="d-block mx-lg-auto img-fluid"
              alt="Thesis Pro"
              width="300"
              height="300"
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6 bg-white shadow p-3">
          <div class="form w-100 pb-2">
            <h4 class="display-3--title mb-1 fw-blod">Forgot password ?</h4>

            <div class="social-login text-start">
              <div class="mt-4">
                <span class="text-start mb-3"
                  >Enter the email address you used for Thesis Pro. We will send
                  you instructions to reset your password.
                </span>
              </div>
            </div>

            <form
              @submit.prevent="resetPassword"
              autocomplete="off"
              class="row"
            >
              <div class="row g-3">
                <div
                  class="alert alert-info alert-dismissible fade show col-12"
                  role="alert"
                  v-if="!formIsValid"
                >
                  <span class="comman-error">{{ invalidmessage }}</span>
                </div>

                <div
                  class="col-12"
                  :class="{ errors: !regData.emailAddress.isValid }"
                >
                  <label for="emailAddress" class="form-label"
                    >Email <span class="text-muted"></span
                  ></label>
                  <input
                    type="email"
                    id="emailAddress"
                    name="emailAddress"
                    v-model="regData.emailAddress.val"
                    class="form-control"
                    @blur="clearValidity('emailAddress')"
                  />
                  <div class="error-message">
                    Please enter a valid email address.
                  </div>
                </div>
                <div class="text-center d-grid mt-2">
                  <button
                    type="submit"
                    class="btn btn-primary rounded-pill pt-3 pb-3"
                  >
                    Send Reset Instructions
                    <i class="fas fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- END SIGNIN FORM -->
    </div>
  </section>
  <!--  END CONTACT US/LOGIN SECTION -->
  <the-footer></the-footer>
  <base-spinner v-if="isLoading"></base-spinner>
</template>

<script>
import { ref, reactive } from "vue";
import { backendURL } from "../../app-constants.js";
import BaseSpinner from "../../components/UI/BaseSpinner.vue";
import TheFooter from "../../components/layouts/TheFooter.vue";

export default {
  components: { BaseSpinner, TheFooter },
  setup() {
    const regData = reactive({
      emailAddress: { val: "", isValid: true },
    });
    const formIsValid = ref(true);
    const invalidmessage = ref(null);
    const isLoading = ref(false);

    function clearValidity(input) {
      regData[input].isValid = true;
    }

    function validateFrom() {
      formIsValid.value = true;
      if (regData.emailAddress.val == "") {
        regData.emailAddress.isValid = false;
        formIsValid.value = false;
      }
    }

    async function resetPassword() {
      //isLoading.value = true;
      validateFrom();

      if (!formIsValid.value) {
        isLoading.value = false;
        invalidmessage.value = "Required  fields missing";
        return;
      }
      try {
        const response = await fetch(backendURL + "/api/auth/resetPassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: regData.emailAddress.val,
          }),
        });

        const resposeData = await response.json();

        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error Reset Password"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          formIsValid.value = false;
          invalidmessage.value = "Password reset link has sent to your email";
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        formIsValid.value = false;
        invalidmessage.value = error.message;
      }
    }

    return {
      regData,
      formIsValid,
      invalidmessage,
      clearValidity,
      resetPassword,
      isLoading,
    };
  },
};
</script>
