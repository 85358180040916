<template>
  <div class="container">
     <div class="help-guide">
    <h4 class="main-heading text-start">STUDENT THESES</h4>
     </div>
  </div>
  <section id="my-progress" class="my-progress">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 my-progress mb-10">
          <div class="my-progress-boxes">
            <!-- <div class="supervisor-content">
              <h5 class="supervisor-content--title">STUDENT THESES</h5>
            </div> -->
            <template v-for="thesis in thesisList" v-bind:key="thesis.id">
              <section class="supervisor-boxes">
                <div class="row no-gutters">
                  <div class="col-xl-7 col-md-6 mb-1 px-2 px-md-3">
                    <div class="row">
                      <div class="col-md-1 text-center">
                        <div class="student-picture">
                          <img
                            :src="thesis.imagePath"
                            alt="mdo"
                            width="50"
                            height="50"
                            class="rounded-circle mt-10 shadow-sm"
                          />
                        </div>
                      </div>
                      <div class="col-md-11 text-right text-600">
                        <div class="ml-2 media-body">
                          <div class="student-details border-bottom mb-2">
                            <h5 class="student-name">
                              <router-link
                                :to="
                                  '/supervisor/student-shared-thesis/' +
                                    thesis.id
                                "
                                class="thesis"
                                >{{ thesis.name }}</router-link
                              >
                            </h5>
                            <button
                              type="button"
                              class="student-progress--success"
                            >
                              Last updated on: {{ thesis.lastUpdatedDate }}
                            </button>
                          </div>
                          <div class="student-thesis">
                            <h5 class="student-thesis--title">
                              <a href="#">
                                {{ thesis.thesisTitle }}
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-5 col-md-6 mb-3 px-2 px-md-3">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="row g-2">
                          <div class="col-md-6">
                            <label class="date-label" for="floatingInputGrid"
                              >STARTING DATE</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              name="endDate"
                              v-model="thesis.startDate"
                            />
                          </div>
                          <div class="col-md-6">
                            <label class="date-label" for="floatingInputGrid"
                              >FINISING DATE</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              name="endDate"
                              v-model="thesis.endDate"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
  <base-spinner v-if="isLoading"></base-spinner>
</template>
<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";

export default {
  components: {
    BaseSpinner,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const thesisList = ref([]);

    const loadThesisList = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getSupervisorThesisList/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          isLoading.value = false;
          thesisList.value = resposeData.data;
        }
      }
      isLoading.value = false;
    };

    onMounted(() => {
      loadThesisList();
    });

    return {
      isLoading,
      thesisList,
    };
  },
};
</script>
