<template>
  <div class="container">
     <div class="help-guide">
    <h4 class="main-heading text-start">STUDENT REQUESTS</h4>
     </div>
  </div>
  <section id="notifications" class="notifications">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 my-progress mb-1">
          <div class="notification-boxes">
            <!-- <div class="notification">
              <h5 class="notification--header">STUDENT REQUESTS</h5>
            </div> -->

            <section
              v-for="req in requestList"
              :key="req.id"
              class="notification-boxes--msg mb-2"
            >
              <div class="row no-gutters mb-1">
                <div class="col-xl-5 col-md-6 mb-1 px-2 px-md-3">
                  <div class="row">
                    <div class="col-md-1 text-center">
                      <div class="user-picture">
                        <img
                          :src="req.base64Image"
                          alt="mdo"
                          width="50"
                          height="50"
                          class="rounded-circle shadow-sm"
                        />
                      </div>
                    </div>
                    <div class="col-md-11 text-right text-600">
                      <div class="ml-2 media-body">
                        <div class="notification">
                          <p class="notification--sender">
                            {{ req.studentThesis.student.fullName }}
                          </p>

                          <p class="notification--title mb-2">
                            <a href="#">{{ req.studentThesis.title }} </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xl-7 col-md-6 px-2 px-md-3">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row g-2">
                        <div class="col-md-4">
                          <p class="notification--type">received on</p>
                          <p class="notification--type-descrip">
                            {{ req.receivedDate }}
                          </p>
                        </div>
                        <div class="col-md-8 mb-2 my-2">
                          <div
                            class="
                              student-request
                              d-sm-flex
                              align-items-center
                              justify-content-between
                            "
                          >
                            <button
                              class="btn-task--success me-md-2"
                              type="button"
                              @click="acceptRequests(req.id)"
                            >
                              ACCEPT
                            </button>
                            <button
                              class="btn-task--warning me-md-2"
                              type="button"
                              @click="rejectRequests(req.id)"
                            >
                              REJECT
                            </button>

                            <a
                              href="#"
                              class="social mt-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Tooltip on top"
                              ><i class="fas fa-reply"></i
                            ></a>
                            <a href="#" class="social mt-0"
                              ><i class="fas fa-trash-alt"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
  <base-spinner v-if="isLoading"></base-spinner>
</template>
<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import { backendURL } from "../../../app-constants";
import BaseSpinner from "../../UI/BaseSpinner.vue";
export default {
  components: {
    BaseSpinner,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const requestList = ref([]);

    const loadRequests = async () => {
      //isLoading.value = true;
      const token = store.getters["user/token"];
      const userId = store.getters["user/loggedUserId"];
      const response = await fetch(
        backendURL + "/api/getSupervisorRequestList/" + userId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //     //"Content-Type": "application/json",
          },
        }
      );
      const resposeData = await response.json();
      if (response.ok) {
        if (resposeData.valid) {
          isLoading.value = false;
          requestList.value = resposeData.data;
        }
      }
      isLoading.value = false;
    };

    onMounted(() => {
      loadRequests();
    });

    const acceptRequests = async (requestId) => {
      try {
        //isLoading.value = true;

        const token = store.getters["user/token"];

        const response = await fetch(
          backendURL + "/api/supervisorAcceptRequest/" + requestId,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              //"Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error accept request"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          loadRequests();
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    const rejectRequests = async (requestId) => {
      try {
        //isLoading.value = true;

        const token = store.getters["user/token"];

        const response = await fetch(
          backendURL + "/api/supervisorRejectRequest/" + requestId,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              //"Content-Type": "application/json",
            },
          }
        );
        const resposeData = await response.json();
        if (!response.ok) {
          const error = new Error(
            resposeData.message || "Error reject request"
          );
          throw error;
        }

        if (resposeData.valid) {
          isLoading.value = false;
          loadRequests();
        } else {
          const error = new Error(resposeData.message);
          throw error;
        }
      } catch (error) {
        isLoading.value = false;
        alert(error.message);
      }
    };

    return {
      isLoading,
      requestList,
      acceptRequests,
      rejectRequests,
    };
  },
};
</script>
