<template>
  <!-- <div class="container">
    <h4 class="main-heading text-start">My library</h4>
  </div> -->

  <div class="section">
    <div class="container">
      <div class="tabPanel">
        <label for="tab-1" tabindex="0"></label>
        <input
          id="tab-1"
          type="radio"
          name="tabs"
          checked="true"
          aria-hidden="true"
        />
        <h2>ONLINE RESULTS</h2>
        <div>
          <my-web-resource></my-web-resource>
        </div>
        <label for="tab-2" tabindex="0"></label>
        <input id="tab-2" type="radio" name="tabs" aria-hidden="true" />
        <h2>MY RESOURCES</h2>
        <div>
          <my-resource></my-resource>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyResource from "./MyResource.vue";
import MyWebResource from "./MyWebResource.vue";

export default {
  components: {
    MyResource,
    MyWebResource,
  },
};
</script>
